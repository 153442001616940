import styled from '@emotion/styled';
import {
	Box,
	Button,
	Container,
	Grid,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import EmblaCarousel from '../EmblaCarousel';
import {useCountry} from '../../helpers/contexts';

// TODO: load from strapi
const unrestricted = [
	'Czechia',
	'Dominican-republic',
	'Japan',
	'Serbia',
	'South-korea',
	'Switzerland',
	'uae'
];

const tereaKeys: { [key: string]: string } = {
	"AMBER CARTON": "/images/Terea_Amber.png",
	"BLUE CARTON": "/images/Terea_Blue.png",
	"BRONZE CARTON": "/images/Terea_Bronze.png",
	"SWIFT CARTON": "/images/Terea_Launa_Swift.png",
	"MAUVE CARTON": "/images/Terea_Mauve_Wave.png",
	"MAUVE WAVE MENTHOL CARTON": "/images/Terea_Mauve_Wave.png",
	"RUSSET CARTON": "/images/Terea_Russet.png",
	"SIENNA CARTON": "/images/Terea_Sienna.png",
	"SILVER CARTON": "/images/Terea_Silver.png",
	"TEAK CARTON": "/images/Terea_Teak.png",
	"TURQUOISE CARTON": "/images/Terea_Turqoise.png",
	"WILLOW MENTHOL CARTON": "/images/Terea_Willow.png",
	"TURQUOISE MENTHOL CARTON": "/images/Terea_Turqoise.png",
	"YELLOW CARTON": "/images/Terea_Yellow.png",
};

const keys: { [key: string]: string } = {
	'AMBER SELECTION': "/images/Heets_Amber.png",
	'BRONZE SELECTION': "/images/Heets_Bronze.png",
	'TURQUOISE SELECTION MENTHOL': "/images/Heets_Turqoise.png",
	'YELLOW SELECTION': "/images/duo-yellow.png",
	'PURPLE WAVE (0.5) MENTHOL': "/images/Heets_Purple_Wave.png",
	TEAK: "/images/Heets_Teak.png",
	'TEAK SELECTION': "/images/Heets_Teak.png",
	'SILVER SELECTION': "/images/Heets_Silver.png",
	'GREEN SELECTION MENTHOL': "/images/duo-green.png",
	'RUSSET SELECTION': "/images/Heets_Russet.png",
	'3 DUO WHITE': "/images/DUO_White.png",
	'3 DUO GREY': "/images/DUO_Grey.png",
	'3 DUO BLUE': "/images/DUO_Blue.png",
	'3.0 MULTI WHITE': "/images/DUO_White.png",
	'3 DUO THE WE EDITION': "/images/DUO_We_edition.png",
	AMBER: "/images/Heets_Amber.png",
	'PRIME BRONZE TAUPE': "/images/Prime_Bronze_Taupe.png",
	'PRIME GOLDEN KHAKI': "/images/Prime_Golden_Khaki.png",
	'PRIME JADE GREEN': "/images/Prime_Jade_Green.png",
	'PRIME OBSIDIAN BLACK': "/images/Prime_Black_Obsidian.png",
};

interface TabPanelProps<T> {
	children?: React.ReactNode;
	index: T;
	value: T;

	[k: string]: any;
}

function TabPanel<T>(props: TabPanelProps<T>) {
	const {children, value, index, ...other} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

const ProductShowcase: React.FC<{
	cmsData: any;
	airports: any;
	chosenAirport: string | null;
}> = ({cmsData: buyingAbroad, airports, chosenAirport}) => {
	const [chosenProductType, setChosenProductType] = useState<string | null>('DUO');
	const [chosenSection, setChosenSection] = useState<string>('TOBACCO STICKS');
	const [isRestricted, setIsRestricted] = useState<boolean>(true);
	const {country} = useCountry();

	useEffect(() => {
		fetch('https://ipapi.co/json')
			.then(async res => {
				const response = await res.json();
				const sourceCountry = response.country_name;
				console.log({unrestricted, sourceCountry}, !unrestricted.includes(sourceCountry))
				const restricted = !!sourceCountry ? !unrestricted.includes(sourceCountry) : true;
				setIsRestricted(restricted);
				console.log({restricted})
			})
			.catch(data => {
				console.log('Request failed:', data);
			});
	}, []);

	const [productItems, setProductItems] = useState<string[]>([]);

	const productsCategorised = airports
		?.find(({iata}: any) => iata === chosenAirport)
		?.availableProducts?.reduce(
			(acc: any, curr: any) => {
				let realDeviceType = ['TEREA', 'IQOS ILUMA'].includes(curr.type)
					? 'ILUMA'
					: ['HEETS', 'IQOS'].includes(curr.type)
						? 'DUO'
						: null;
				let realSectionType = ['IQOS', 'IQOS ILUMA'].includes(curr.type)
					? 'DEVICE'
					: ['HEETS', 'TEREA'].includes(curr.type)
						? 'TOBACCO STICKS'
						: null;
				if (realDeviceType === null || realSectionType === null) {
					return acc;
				}

				let currentProduct = acc.find(({ type }: any) => realDeviceType === type);
				if (!currentProduct) {
					currentProduct = {type: realDeviceType, sections: []};
					acc.push(currentProduct);
				}

				// TODO: Proper type finders
				let currentSection = currentProduct.sections.find(
					({ type }: any) => type === realSectionType
				);
				if (!currentSection) {
					currentSection = {type: realSectionType, variants: []};
					currentProduct.sections.push(currentSection);
				}

				const images = currentSection.type === 'DUO' ? keys : tereaKeys
				currentSection.variants.push({
					title: curr.name.split(' - ')[0],
					image: images.hasOwnProperty(curr.name)
						? images[curr.name as keyof typeof images]
						: ''
				});

				return acc;
			},
			[] as {
				type: 'ILUMA' | 'DUO' | string;
				sections: {
					type: 'DEVICES' | 'TOBACCO STICKS' | string;
					variants: {
						title: string;
						image: string;
					}[];
				}[];
			}[]
		);

	const noProductsAvailable = !productsCategorised || productsCategorised?.length === 0;

	useEffect(() => {
		const newItems = productsCategorised
			?.filter((product: any) => product.type === chosenProductType || chosenProductType === 'all')
			?.map((product: any) =>
				product.sections
					.filter((section: any) => section.type === chosenSection)
					.reduce((acc: any, curr: any) => {
						return [...acc, ...curr.variants];
					}, [] as typeof productsCategorised[number]['sections'][number]['variants'])
			)
			?.reduce((acc: any, curr: any) => {
				return [...acc, ...curr];
			}, [] as typeof productsCategorised[number]['sections'][number]['variants'])
			?.sort((a: any, b: any) => a.title.localeCompare(b.title))
			?.map((variant: any) => variant?.title);

		if (!!newItems?.length) setProductItems(newItems);
	}, [productsCategorised]);


	let airportName = airports?.find((a: any) => a?.iata === chosenAirport)?.name ?? '';
	if (airportName === "Cote D\\\\'Azur") airportName = "Cote D'Azur";

	if (!chosenAirport) return <></>;
	return (
		<Styled>
			<Grid container justifyContent="center">
				<Container>
					<Stack
						direction="column"
						textAlign={'center'}
						pt={5}
						spacing={3}
						className="ddf-selection"
					>
						{	
							// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
							country?.Name && chosenAirport && !airports.map((airport: any) => airport.market.trim()).includes(country?.Name?.trim()!) ? (
									<Container>
										<Stack
											direction="column"
											textAlign={'center'}
											spacing={3}
										>
											<Typography variant="h2">
												{buyingAbroad?.noProductsCountryTitle}
											</Typography>
											<Box pb={5}>
												<Typography variant="body1" gutterBottom>
													{buyingAbroad?.noProductsCountryText}
												</Typography>
											</Box>
										</Stack>
									</Container>
								)
								: noProductsAvailable ? (
									<Container>
										<Stack
											direction="column"
											textAlign={'center'}
											spacing={3}
										>
											<Typography variant="h2">
												{buyingAbroad?.noProductsTitle}
											</Typography>
											<Box pb={5}>
												<Typography variant="body1" gutterBottom>
													{buyingAbroad?.noProductsText}
												</Typography>
											</Box>
										</Stack>
									</Container>
								) : (
									<>
										<Container>
											<Stack
												direction="column"
												textAlign={'center'}
												spacing={3}
											>
												<Typography variant="h2">
													{buyingAbroad?.DiscoverDutyFree}
												</Typography>
												<Typography variant="body1" gutterBottom>
													{buyingAbroad?.IQOSProductsAvailable.replace(
														/\{airport\}/g,
														airportName
													)?.replace(/\\\//g, "/")}
												</Typography>
											</Stack>
										</Container>
										<Stack
											direction={{xs: 'column'}}
											justifyContent="center"
											spacing={2}
										>
											{productsCategorised?.length > 1 && <Button
												onClick={() => setChosenProductType('all')}
												variant="outlined"
												color="secondary"
												className={'product-filter-button'}
												sx={{
													color:
														chosenProductType === 'all'
															? 'black !important'
															: 'white',
													background:
														chosenProductType === 'all'
															? 'white !important'
															: 'transparent',

													margin: "auto"
												}}
											>
												ALL
											</Button>}
											<Stack direction="row" justifyContent="center" spacing={3}>
												{productsCategorised?.map((product: any, index: number) => (
													<Button
														key={index}
														onClick={() => setChosenProductType(product.type)}
														variant="outlined"
														color="secondary"
														size="large"
														sx={{
															color:
																chosenProductType === product.type
																	? 'black !important'
																	: 'white',
															background:
																chosenProductType === product.type
																	? 'white !important'
																	: 'transparent'
														}}
													>
														{product.type}
													</Button>
												))}
											</Stack>
										</Stack>
										<Box>
											<Tabs
												value={chosenSection || 'TOBACCO STICKS'}
												onChange={(e, v) => setChosenSection(v)}
												centered
											>
												{productsCategorised
													?.find(
														(product: any) =>
															chosenProductType === product.type ||
															chosenProductType === 'all'
													)
													?.sections?.map((section: any) => (
														<Tab
															label={section.type}
															value={section.type}
															className="product-tab-selector"
														/>
													))}
											</Tabs>
										</Box>
									</>
								)
						}
					</Stack>
				</Container>
			</Grid>
			{productsCategorised
				?.find((product: any) => chosenProductType === product.type || chosenProductType === 'all')
				?.sections?.map((section: any, i: number) => (
					<TabPanel
						value={section.type}
						index={chosenSection}
						className="variant-section"
						key={i}
					>
						<EmblaCarousel slides={productItems} isRestricted={isRestricted}
						               chosenProductType={chosenProductType} chosenSection={chosenSection}/>
					</TabPanel>
				))}
		</Styled>
	);
};

const Styled = styled.div`
	.embla {
		position: relative;
		background-color: #f7f7f7;
		padding: 20px;
		max-width: 670px;
		margin-left: auto;
		margin-right: auto;

		.embla-viewport {
			overflow: hidden;
			width: 100%;

			.is-draggable {
				cursor: move;
				cursor: grab;
			}

			.is-dragging {
				cursor: grabbing;
			}

			.embla-container {
				display: flex;
				user-select: none;
				-webkit-touch-callout: none;
				-khtml-user-select: none;
				-webkit-tap-highlight-color: transparent;
				margin-left: -10px;

				.embla-slide {
					position: relative;
					min-width: 80%;
					padding-left: 10px;

					.embla-slide-inner {
						position: relative;
						overflow: hidden;
						height: 190px;
					}
				}
			}
		}
	}

	.health-warning {
		z-index: 1000;
		position: fixed;
		width: 100vw;
		max-width: unset;
		bottom: 0;
		left: 0;
		height: min-content;
		background-color: white;
		text-align: center;
		padding: 16px;
	}

	.variant-section {
		margin-top: 3rem;
	}
`;

export default ProductShowcase;
