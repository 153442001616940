import styled from '@emotion/styled';
import { Button, lighten, Paper, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDataLayer } from "../../utils/dataLayer";

interface Props {
  open?: boolean;
  onClose?: () => void;
  translations: any;
}

const Feedback: React.FC<Props> = ({ open, onClose, translations }) => {
  const dataLayer = useDataLayer()
  const [hasAnswered, setHasAnswered] = useState(false);

  useEffect(() => {
    if (open && localStorage.getItem('feedbackFinished') === 'true') {
      dataLayer({
        'event': 'giveFeedbackClose'
      });
    }
  }, [open]);

  useEffect(() => {
    dataLayer({
      'event': 'giveFeedbackDisplay'
    });
  }, []);

  return (
    <Styles>
      <Paper
        className={`dialog ${open ? 'dialog--open' : 'dialog--closed'}`}
      >
        <img src="/images/cross-icon.svg" className="card-exit gtm-feedback-closeX" onClick={() => onClose?.()} />
        <Stack alignItems="center" spacing={4} justifyContent="center" className="stack">
          <img src="/images/feedback-man.svg" className="profile" />
          {
            hasAnswered ? (
              <>
                <Typography variant="h3" className="title">
                  {translations?.completeTitle}
                </Typography>

                <Typography variant="body1" fontWeight="thin" className="question">
                  {translations?.completeDescription}
                </Typography>

                <Stack spacing={2}>
                  <Button className="button gtm-feedback-closeButton" variant="contained" onClick={() => onClose?.()} color="info">{translations?.close}</Button>
                </Stack>
              </>
            ) : (
              <>
                <Typography variant="h3" className="title">
                  {translations?.leadTitle}
                </Typography>

                <Typography variant="body1" fontWeight="thin" className="question">
                  {translations?.leadQuestion}
                </Typography>

                <Stack spacing={2}>
                  <Button className="button gtm-feedback-yes" variant="contained" onClick={() => {
                    setHasAnswered(true);
                    dataLayer({
                      'event': 'giveFeedbackAnswer',
                      'feedbackAnswer': 'Yes'
                    });
                  }} color="info">
                    {translations?.yes}
                  </Button>
                  <Button className="button gtm-feedback-no" variant="contained" onClick={() => {
                    setHasAnswered(true);
                    dataLayer({
                      'event': 'giveFeedbackAnswer',
                      'feedbackAnswer': 'No'
                    });
                  }} color="info">
                    {translations?.no}
                  </Button>
                </Stack>
              </>
            )
          }
        </Stack>
      </Paper>
    </Styles>
  );
}

const Styles = styled.div`
    .dialog {
        position: fixed;
        left: 0;
        top: 10vh;

        ${({ theme }) => theme.breakpoints.down('sm')} {
            top: 0;
            max-height: unset;
            max-width: unset;
        }

        /* Under feedback button itself */
        z-index: 9999999;
        transform: translateX(-100vw);
        transition: transform 0.7s ease-in-out;

        &--open {
            transform: translateX(0);
        }

        width: 100%;
        max-width: 350px;
        height: 100%;
        max-height: 550px;
        
        background-color: ${({ theme }) => theme.palette.common.white};


        .card-exit {
            position: absolute;
            top: 12px;
            right: 12px;
            width: 24px;
            cursor: pointer;
            z-index: 200000;
            ${({ theme }) => theme.breakpoints.up('md')} {
                opacity: 1;
                width: 24px;
            }
            /* color: ${({ theme }) => theme.palette.common.black}; */
            /* Use filter as it's an img tag */
            /* https://codepen.io/sosuke/pen/Pjoqqp thanks */
            filter: brightness(0) saturate(100%) invert(19%) sepia(13%) saturate(762%)
                hue-rotate(220deg) brightness(88%) contrast(92%);
        }

        .stack {
            text-align: center;
            width: 100%;
            height: 100%;
            
            .profile {
                width: 50%;
                max-width: 5rem;
            }

            .title {
               color: ${({ theme }: any) => theme.palette.alternate.main};
            }

            .question {
                color: ${({ theme }: any) => theme.palette.common.black};
            }

            .button {
                /* Hide the animation */
                &:hover {
                    background-color: ${({ theme }: any) => lighten(theme.palette.alternate.main, 0.3)};
                    &::after {
                        content: '';
                        left: -10%;
                        transform: skewX(-20deg);
                        width: 0%;
                    }
                }
            }
        }

    }
`;

export default Feedback;