import styled from '@emotion/styled';

const Styles = styled.div`
	&.sidebar-paper {
		width: 100vw;
		border-radius: 0;
	}
`;

export default Styles;
