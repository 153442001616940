/* eslint-disable react-hooks/exhaustive-deps */
import ChoiceGrid from './ChoiceGrid';
import TextBubble from './TextBubble';
import { accurateTimer } from '../../helpers/common';
import { Avatar, Button, Dialog, IconButton, Typography, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import Simplebar from './Simplebar';
import decisionTree from './IQOS_DE-html.json';
import styled from '@emotion/styled';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import theme from "../../theme";
import { useDataLayer } from "../../utils/dataLayer";

const DigitalExpert: React.FC<{ mobile?: boolean; onClose?: () => void; onStart?: () => void; onEnd?: () => void, digitalExpertActive?: boolean }> = ({
	mobile,
	onStart,
	onEnd,
	onClose,
	digitalExpertActive
}) => {

	const [newMessages, setNewMessages] = useState<any | null>(null);
	const [chat, setChat] = useState<any | null>([]);
	const messagesEnd = useRef<any>();

	const [activeChoices, setActiveChoices] = useState<any | null>(null);
	const [restart, setRestart] = useState<boolean>(false);
	const [uniqueID, setUniqueID] = useState<any | null>(null);
	const [initialLoad, setInitalLoad] = useState<boolean>(true);
	const [firstResponse, setFirstResponse] = useState<boolean>(true);
	const $digitalExpert = useRef<HTMLDivElement>(null);
	const $scrollable = useRef<HTMLDivElement>(null);

	const desktop = useMediaQuery(theme.breakpoints.up('md'));
	const dataLayer = useDataLayer();

	let index = 0;
	let messages: any[] = [];

	const handleNewMessages = () => {
		messages = [...messages, newMessages[index]]
		setChat([...messages])
		scrollToBottom()
		if (++index < newMessages.length) {
			accurateTimer(2000, 1, handleNewMessages)
		}
	};

	useEffect(() => {
		if (chat?.[chat?.length - 1]?.action === 'exit') {
			onEnd?.();
		}
	}, [chat]);

	useEffect(() => {
		if (digitalExpertActive) {
			onStart?.();
			updateScroll();
			setNewMessages(decisionTree?.DEWelcoming);
			setActiveChoices(decisionTree?.LAUJourneyChoices);
			setFirstResponse(true);
		}

		if (digitalExpertActive && !!!initialLoad) {
			setChat([...chat, decisionTree.DEWelcomeback]);
			scrollToBottom()
		}
	}, [digitalExpertActive]);

	useEffect(() => {
		if (newMessages !== null) {
			handleNewMessages();
		}
	}, [newMessages]);

	useEffect(() => {
		if (restart === true) {
			accurateTimer(2000, 1, () => handleRestart());
		} else {
			setActiveChoices(decisionTree?.LAUJourneyChoices);
		}
	}, [restart]);

	useEffect(() => {
		setUniqueID(() => Math.floor(Math.random() * 1000).toString());
	}, [activeChoices]);

	const handleRestart = () => {
		setChat([...chat, decisionTree?.restart?.DEResponse]);
		scrollToBottom()
		setActiveChoices(decisionTree?.restart?.LAUChoices);
	};

	const handleLAUAnswer = (choice: any) => {
		if (firstResponse) {
			dataLayer({
				'event': 'customerCareDialogStart'
			});
			setFirstResponse(false);
		}
		setChat([...chat, choice?.LAUAnswer, choice?.DEResponse]);
		setActiveChoices(choice?.LAUChoices);
		if (choice?.end === true) {
			setRestart(true);
		}
		if (choice?.reset === true) {
			setRestart(false);
		}
		if (choice?.IQOSAvailable === true) {
			setChat([...chat, choice?.LAUAnswer, decisionTree?.available?.DEResponse]);
			setActiveChoices(decisionTree?.available?.LAUChoices);
		}
		scrollToBottom()
	};
	const scrollToBottom = () => {
		if (!$scrollable.current) return;
		$scrollable.current.scrollTop = $scrollable.current.scrollHeight;
	}

	const updateScroll = () => {
		if (!$scrollable.current) return;
		$scrollable.current.scrollTop = $scrollable?.current?.scrollHeight;
	};

	const handleOpenClose = () => onClose?.();

	const scrollIfAtBottom = () => {
		if (!$scrollable?.current) return;
		const isScrolledToBottom =
			$scrollable.current.scrollHeight - $scrollable.current.clientHeight <=
			$scrollable.current.scrollTop + 1;
		if (isScrolledToBottom) updateScroll();
	};
	useEffect(() => {
		const interval = setInterval(() => {
			scrollIfAtBottom();
		}, 500);

		return () => clearInterval(interval);
	}, []);


	if (!digitalExpertActive && mobile) {
		return null;
	}

	scrollIfAtBottom();

	return (
		<div>
			<Dialog
				open={digitalExpertActive ?? false}
				onClose={() => {
					dataLayer({
						'event': 'customerCareDialogClose'
					});
					onClose?.()
				}}
				PaperComponent={({ children }) => (
					<Styles>
						<div
							ref={$digitalExpert}
							className={`expert-wrapper ${!desktop && 'expert-wrapper-mobile'} ${desktop && digitalExpertActive && 'expert-wrapper-active'
								}`}
						>
							{children}
						</div>
					</Styles>
				)}
			>

				<div className="closeButton">
					<IconButton onClick={handleOpenClose} style={{ fontSize: "2rem" }}>
						<FontAwesomeIcon icon={faClose} fontSize="3x" color="black" />
					</IconButton>
				</div>
				<div className="kiosk-avatar">
					<Avatar
						alt="Assistant"
						className="kiosk-avatar-image"
						src="/images/jamieSquare.png"
					/>
					<div className="kiosk-user-info">
						<Typography className="kiosk-user-info-title" variant="h5">
							Digital Expert.
						</Typography>
						<div className="kiosk-user-info-name">
							Connected to Jamie
						</div>
					</div>
				</div>
				<div className="kiosk-chat-container">
					<Simplebar
						dark
						className="kiosk-chat-scroll-container"
						scrollableNodeProps={{ ref: $scrollable }}
					>
						{!!chat.length &&
							chat
								?.reduce(
									(acc: any, curr: any) =>
										acc[acc.length - 1]?.[0]?.name === curr.name
											? [...acc.slice(0, -1), [...acc[acc.length - 1], curr]]
											: [...acc, [curr]],
									[]
								)
								?.map((messageGroup: any, index1: number, arr: []) => {
									return (
										<>
											<div
												className={`message-group message-group-${messageGroup[0].name}`}
												key={index1}
											>
												{messageGroup?.map((message: any, index2: number) => {
													return (
														<TextBubble
															key={`${index1}-${index2}`}
															message={message}
															updateScroll={updateScroll}
															handleOpenClose={handleOpenClose}
															isLastMessage={
																index1 === arr?.length - 1 &&
																index2 === messageGroup?.length - 1
															}
														/>
													);
												})}
											</div>
										</>
									);
								})}
						<div className="kiosk-bottom-scroll" ref={messagesEnd} key="scroll-end" />
					</Simplebar>
				</div>

				{chat?.[chat?.length - 1]?.action === 'exit' ? (
					<div className="close-mini-app">
						<Button
							onClick={() => handleOpenClose()}
							//variant="inversed"
							className="close-mini-app-button"
						>
							Close Digital Expert
						</Button>
					</div>
				) : (
					<div className="LAUChoicesWrapper">
						{digitalExpertActive && (
							<ChoiceGrid
								key={uniqueID}
								initialLoad={initialLoad}
								setInitalLoad={setInitalLoad}
								choices={activeChoices}
								handleLAUAnswer={handleLAUAnswer}
								kiosk
							/>
						)}
					</div>
				)}
			</Dialog>
		</div>
	);
};


const Styles = styled.div(({ theme }) => `
    .expert-wrapper {

		transform: translate(0%, 0px);
		&-active {
			transform: translate(100%, 0px);
		}

		border-top-right-radius: 1.6rem;
		border-bottom-right-radius: 1.6rem;

		width: 30%;
		height: 75%;
		display: flex;
		flex-direction: column;
		align-items: center;
		/* justify-content: space-evenly; */
		background: white;
		position: absolute;
		top: 14.6%;
		right: 100%;
		z-index: 999999999;
		border-left: none;
        min-width: 600px;
	
		${theme.breakpoints.down('lg')} {
			height: 90%;
			top: 2.5%;
		}
		
		@media (max-height: ${theme.breakpoints.values.md}px) {
			height: 90%;
			top: 2.5%;
		}
	
		&-mobile {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			padding-top: ${theme.spacing(2)};
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			max-height: 100vh;
			overflow: hidden;
			padding-left: 0;			
            min-width: auto;

			.choice-button {
			}
		}

		.closeButton {
			position: relative;
			left: 40%;
			top: 4%;
			z-index: 9999;
		}

		.kiosk-avatar {
			display: flex;
            width: 90%;
			flex-direction: row;
			/* align-items: center; */
			position: relative;
			top: -0%;

			.kiosk-avatar-image {
                margin-right: ${theme.spacing(2)};
				border: 3px solid #34303D;
                height: 100px;
                width: 100px;
				border-radius: 50%;
				
				${theme.breakpoints.down('lg')} {
					display: none;
				}
				
				@media (max-height: ${theme.breakpoints.values.md}px) {
					display: none;
				}
			}
            
            .kiosk-user-info {
                margin-top: ${theme.spacing(1)};
                flex: 1;
            }

			.kiosk-user-info-name {
				color: #00d1d2;
				margin-top: 5px;
			}

			.kiosk-user-info-title {
				color: black;
			}
		}

		.kiosk-chat-container {
			width: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
			padding: 0 5% 5% 5%;
			margin: 2% 0;
			position: relative;
			top: 0;
			overflow-y: auto;
			/* border:1px solid red; */
			/* height: 40%; */
			/* max-height: 45%; */

			*:not(.playlist-content-icon) {
				text-align: left;
				/* color: black; */
				margin-left: 0;			
			}
			
			.action-button-container {
				flex-direction: column;
				img {
					display: block;
					margin-left: 10px;
					margin-top: 10px;
				}
				.action-button {			
                    text-align: center;
                }
			}
			img {		
                display: grid;
                border-radius: 10px;
			}
			

			.kiosk-chat-scroll-container {
				display: flex;
				flex-direction: column-reverse;
			}

			.video-container {
				margin-right: 0;
			}

			.message-group {
				display: inline-block;
				width: 100%;
			}

			.message-group-DE {
				position: relative;
				&::before {
					top: 10px;
					left: 15px;
					/* 100% - padding message top - padding message bottom */
					height: calc(100% - 10px - 10px);
					width: 1px;
					background-color: black;
					border-left: 1px solid black;
					content: '';
					position: absolute;
				}
			}
		}

		.kiosk-scan-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			/* border: 1px solid red; */
			width: 50%;
			height: 40%;

			.scan-square {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background: #332e3a;
				border-radius: 5%;
				position: relative;
				bottom: 5%;

				.QR {
					width: 50%;
					border-radius: 5%;
					/* border:1px solid red; */
				}

				.scan {
					margin: 0;
					padding-top: 20px;
					width: 75%;
					height: 20%;
					text-align: center;
					color: white;
					font-weight: lighter;
				}
			}
		}

		p {
			text-align: center;
			/* color: grey; */
			text-transform: none;
			/* margin: 0 8% 0 8%; */
		}
	}
	.LAUChoicesWrapper {
		padding-bottom: 10px;
		display: flex;
		flex: 0 1 200px;
		flex-direction: column;
		align-items: center;
		max-height: 50%;
		min-width: 100%;
		/* border:1px solid red; */
		position: relative;
		/* justify-content: space-evenly; */
		bottom: -4%;
		height: 30%;
		background-color: ${theme.palette.grey[300]};
		border-bottom-right-radius: 1.6rem;
		padding-top: ${theme.spacing(2)};


		.choice-button {
			p {
				font-size: 0.7rem;
			}
		}

		/* .choices-grid{
		width:85%;
		height:85%;
		display:grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 35% 35%;
		grid-gap: 10px;
		.choice-button{
			padding:5px;
			border:1px solid #4A6FAE;
			border-radius:10px;
			color:#4A6FAE;
			display:flex;
			align-items:center;
			justify-content:center;
			text-transform:none;
		}
		} */
	}

	.close-mini-app {
		border-bottom-right-radius: 1.6rem;
		padding: ${theme.spacing(2, 0)};
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #38daff;
		width: 100%;

		.close-mini-app-button {
			color: black;
			background-color: white;
			font-weight: normal;

			&:hover {
				background-color: ${theme.palette.grey[200]};
			}
		}
	}

	@keyframes glow {
		from {
			box-shadow: 0 0 9px -3px white;
		}
		to {
			box-shadow: 0 0 9px 3px white;
		}
	}
`);

export default DigitalExpert;
