import { Box, Button, Typography } from '@mui/material';
import styled from '@emotion/styled';

const VeevImportantInformation: React.FC<{ setShowImportant: any; code: any }> = ({
	setShowImportant,
	code
}) => {
	// here
	return (
		<Styles>
			<Box className="page-wrapper">
				<Box className="content-wrapper">
					<Box className="button-wrapper">
						<Button
							variant={'contained'}
							className="button"
							onClick={() => {
								setShowImportant(false);
							}}
						>
							{code === 'ar' ? 'جلد' : 'Hide'}
						</Button>
					</Box>
					<Box className="section-wrapper">
						<Typography
							className="text-title"
							textAlign={code === 'ar' ? 'right' : 'left'}
						>
							{code === 'ar'
								? `معلومات هامّة حول VEEV`
								: `Important Information about VEEV`}
						</Typography>
						<Typography
							className="text-body"
							style={{}}
							textAlign={code === 'ar' ? 'right' : 'left'}
						>
							{code === 'ar'
								? `
							.جهاز VEEV معدّ لاستخدام الرجال والنساء الذين سيستمرون في استخدام منتجات التبغ أو النيكوتين وهو غير معدّ لاستخدام المدخنين البالغين الذين لم يدخنوا على الإطلاق أو الذين أقلعوا عن استخدام منتجات التبغ والنيكوتين. ولا يعتبر جهاز VEEV بديلًا عن الإقلاع عن استخدام منتجات التبغ والنيكوتين. لاختبار منافع VEEV، يجب على المدخنين البالغين التبديل لاستخدام VEEV بالكامل وترك السجائر نهائيًا
							`
								: `
							VEEV is for men and women who would otherwise continue to use tobacco or
							nicotine products. VEEV is not for adult smokers who have never smoked
							or who have quit tobacco and nicotine use. VEEV is not an alternative to
							quitting tobacco and nicotine use. To experience the benefit of VEEV
							adult smokers should switch to VEEV completely and abandon cigarettes
							permanently.
							`}
						</Typography>
					</Box>
					<Box className="section-wrapper">
						<Typography
							className="text-title"
							textAlign={code === 'ar' ? 'right' : 'left'}
						>
							{code === 'ar' ? `التبغ والآثار الصحية` : `Tobacco and health effects`}
						</Typography>
						<Typography
							className="text-body"
							textAlign={code === 'ar' ? 'right' : 'left'}
						>
							{code === 'ar'
								? `
								.لا يخلو جهاز VEEV من المخاطر. تحتوي أعواد التبغ على النيكوتين وهو مادة تسبب الإدمان. وتتمثل الطريقة المثلى للحدّ من المخاطر الصحية المتعلقة بالتبغ في الإقلاع عن استخدام التبغ والنيكوتين نهائيًا
							`
								: `
							VEEV is not risk-free. Tobacco sticks contain nicotine, which is
							addictive. The best way to reduce tobacco related health risks is to
							quit tobacco and nicotine use altogether.
							`}
						</Typography>
					</Box>
					<Box className="section-wrapper">
						<Typography
							className="text-title"
							textAlign={code === 'ar' ? 'right' : 'left'}
						>
							{code === 'ar' ? `النيكوتين` : `Nicotine`}
						</Typography>
						<Typography
							className="text-body"
							textAlign={code === 'ar' ? 'right' : 'left'}
						>
							{code === 'ar'
								? `
								.النيكوتين مادة تسبب الإدمان وقد تخلّف آثارًا جانبية مشابهة لتلك المرتبطة بالمنتجات الأخرى المحتوية على النيكوتين، مثل الصداع أو الغثيان أو تهيج الحلق. إذا عانيت أيًا من هذه الأعراض، فتوقف عن استخدام VEEV واستشر أخصائي الرعاية الصحية. وليس جهازVEEV معدًا لاستخدام الأشخاص الذين يعانون من مشاكل في القلب أو مصابون بداء السكري. ولا ينبغي استخدام VEEV أثناء الحمل أو الرضاعة الطبيعية. وفي حالة الحمل أو الرضاعة أو الاشتباه بالحمل، يجب التوقف فورًا عن التدخين واستخدام منتجات النيكوتين واستشارة أخصائي الرعاية الصحية							`
								: `
								Nicotine is addictive and may cause side-effects similar to those
								associated with other nicotine-containing products, like headache,
								nausea, or throat irritation. If you experience these symptoms stop
								using VEEV and consult a health care professional. VEEV is not for
								people who have heart problems or are diabetic. VEEV should not be used
								during pregnancy or while breast-feeding. If you are pregnant,
								breast-feeding or think you may be pregnant, you should quit tobacco and
								nicotine use altogether and consult a health care professional.
							`}
						</Typography>
					</Box>
					<Box className="section-wrapper">
						<Typography
							className="text-title"
							textAlign={code === 'ar' ? 'right' : 'left'}
						>
							{code === 'ar' ? `الأطفال والمراهقون` : `Children and adolescents`}
						</Typography>
						<Typography
							className="text-body"
							textAlign={code === 'ar' ? 'right' : 'left'}
						>
							{code === 'ar'
								? `
								.يُعد جهاز VEEV مخصص لاستخدام البالغين فقط (الذين تزيد أعمارهم عن 20 سنة). ولا ينبغي للأطفال والمراهقين استخدام VEEV بأي حال من الأحوال
								`
								: `
								VEEV is intended for adult use only (over 20 years old). Children and
							adolescents should not use VEEV under any circumstances.
							`}
						</Typography>
					</Box>
					<Box className="section-wrapper">
						<Typography
							className="text-title"
							textAlign={code === 'ar' ? 'right' : 'left'}
						>
							{code === 'ar'
								? `
							الممارسات السليمة المُتّبعة خلال الانتقال إلى استخدام منتجات شركة فيليب موريس إنترناشيونال الخالية من الدخان

							`
								: `
							Good Conversion Practices for PMI's Smoke-free Products
							`}
						</Typography>
						<Typography
							className="text-body"
							textAlign={code === 'ar' ? 'right' : 'left'}
						>
							{code === 'ar'
								? `
							توفر فيليب موريس إنترناشيونال مجموعة متنوعة من المنتجات الخالية من الدخان عالية الجودةتوفر فيليب موريس إنترناشيونال مجموعة متنوعة من المنتجات الخالية من الدخان عالية الجودة وهي مُثبتة علميّاً أنها تُمثل خياراً أفضل من الاستمرار بالتدخين تتمثل رؤيتنا في أن تحل منتجاتنا الخالية من الدخان يوماً ما محل السجائر
							`
								: `
								PMI offers a variety of quality, scientifically substantiated smoke-free
							products that are much better choices than continued smoking. Our vision
							is that our smoke-free products will one day replace cigarettes.
							`}
							<br />
							<br />
							{code === 'ar'
								? `
							1.يؤدي تدخين السجائر إلى أمراض خطيرة ويسبب الإدمان. وبدون شك، فإن أفضل قرار يتخذه أي مدخن هو أن يقلع عن استهلاك التبغ والنيكوتين نهائياً
							`
								: `
							1. Cigarette smoking causes serious diseases and is addictive. Without
							question, the best decision any smoker can make is to quit tobacco and
							nicotine use altogether.
							`}
							<br />
							{code === 'ar'
								? `
							2.لا يجوز للقُصَر استخدام التبغ أو النيكوتين بأي شكل من الأشكال
							`
								: `
								2. Minors should not use tobacco or nicotine in any form.
							`}
							<br />
							{code === 'ar'
								? `
								3.إن منتجات فيليب موريس إنترناشيونال الخالية من الدخان مخصصة للبالغين الذين سيستمرون في التدخين أو إستخدام منتجات النيكوتين الاخرى
							`
								: `
							3. PMI's smoke-free products are for adults who would otherwise continue
							to smoke or use other nicotine products.
							`}
							<br />
							{code === 'ar'
								? `
							4.لا تقدم فيليب موريس إنترناشونال منتجاتها الخالية من الدخان للأشخاص الذين لم يسبق لهم استخدام منتجات التبغ أو النيكوتين أو الذين توقفوا عن استخدام منتجات التبغ والنيكوتين. لا تعتبر منتجاتنا الخالية من الدخان بدائل للإقلاع عن التدخين وهي غير مصممة كأدوات مساعدة للإقلاع عن التدخين
							`
								: `
							4. We do not offer PMI's smoke-free products to people who have never
							used tobacco or nicotine products or who have quit using tobacco and
							nicotine products. Our smoke-free products are not alternatives to
							quitting and are not designed as cessation aids. 
							`}
							<br />
							{code === 'ar'
								? `
							5. منتجات فيليب موريس إنترناشيونال الخالية من الدخان ليست خالية من المخاطر وتحتوي على النيكوتين الذي يسبب الإدمان. ومع ذلك، يعتبر الانتقال إلى منتج خالٍ من الدخان هو خيار أفضل من الاستمرار في التدخين
							`
								: `
								5. PMI's smoke-free products are not risk free and contain nicotine,
							which is addictive. Switching to a smoke-free product is, however, a
							better choice than continuing to smoke. 
							`}
							<br />
							{code === 'ar'
								? `
							6. نحن ندعم مستخدمي المنتجات الخالية من الدخان البالغين في رحلة انتقالهم التام من خلال التوعية والإرشاد
							`
								: `
								6. We support our adult smoke-free product users in their journey to
							full conversion through education and guidance. 
							`}
							<br />
							{code === 'ar'
								? `
							7. لكي يختبر المستهلكين مزايا المنتجات الخالية من الدخان، يجب عليهم الإنتقال تماماً والتخلي عن التدخين بشكل كامل
							`
								: `
								7. For consumers to experience the benefits of smoke-free products, they
							must switch completely and abandon smoking permanently. `}
							<br />
							{code === 'ar'
								? `
							8. نحن  نقوم بإبلاغ المستهلكين بجميع معلومات السلامة ذات الصلة بمنتجاتنا الخالية من الدخان
							`
								: `
							8. We inform consumers of all relevant safety information about our
							smoke-free products.
							`}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Styles>
	);
};

export default VeevImportantInformation;

const Styles = styled.div`
	.page-wrapper {
		height: 100%;
		width: 100%;
		background-color: #35303e;
	}
	.button-wrapper {
		/* display: flex;
		justify-content: center;
		align-items: center; */
	}
	.content-wrapper {
		padding: 2rem;
		max-width: 800px;
		width: 100%;
		margin-left: auto;
		box-sizing: border-box;
		margin-right: auto;
		display: block;
	}
	.section-wrapper {
		margin-bottom: 20px;
	}
	.button {
		background: rgba(0, 0, 0, 0);
		height: 40px;
		font-weight: normal;
		align-self: center;
		font-size: 0.8rem;
		cursor: pointer;
		border: white solid 1px;
		box-shadow: none;
		color: white !important;
	}
	.button:hover {
		color: black !important;
	}
	.text-title {
		font-size: 2rem;
		font-weight: bold;
		color: #ffffff;
	}
	.text-body {
		font-size: 1rem;
		color: #ffffff;
		font-weight: bold;
	}
`;
