import React, { useState } from 'react';
import { Box } from '@mui/material';
import Navigation from './Navigation';
import Footer from './Footer';
import styled from '@emotion/styled';
import Sidebar from './Sidebar';
import BottomNavigation from './BottomNavigation';
import { useLocation } from 'react-router';

import AgeVerification from '../components/AgeVerification';

const Layout: React.FC<{
	children: React.ReactNode;
	translations: any;
	reducedNav?: boolean;
	footerData: any;
}> = ({ translations, footerData, children, reducedNav }) => {
	const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
	const location = useLocation();
	return (
		<Styles>
			<Box component="main">
				<Sidebar
					open={sidebarOpen}
					onClose={() => setSidebarOpen(false)}
					translations={translations?.navigation?.data?.attributes}
				/>
				<Navigation
					translations={translations?.navigation?.data?.attributes}
					onSidebarOpen={() => setSidebarOpen(true)}
					reducedNav={!!reducedNav}
					prefixLinks={!!reducedNav}
					useVeev={location?.pathname.includes('veev')}
				/>
				{children}
			</Box>
			{!reducedNav && (
				<BottomNavigation
					useVeev={location?.pathname.includes('veev')}
					translations={translations?.navigation}
				/>
			)}
			{!location?.pathname.includes('veev') && (
				<Footer data={footerData} reducedNav={!!reducedNav} />
			)}
			<AgeVerification useVeev={location?.pathname.includes('veev')} />
		</Styles>
	);
};

const Styles = styled.div`
	header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
	}
`;

export default Layout;
