import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { Box, Container, Tab, Tabs as MuiTabs } from '@mui/material';
import styled from '@emotion/styled';
import theme from '../../theme';
import { useParams } from 'react-router';
import { formatLocaleUrl } from '../../utils/common';

const CustomTabs = styled(MuiTabs)({
	'&.MuiTab-iconWrapper': {
		height: theme.spacing(5)
	}
});

const CustomTab = styled((props: any) => <Tab disableRipple iconPosition="start" {...props} />)(
	({ theme }) => ({
		textTransform: 'none',
		minWidth: 0,
		width: '18%',
		background: `${theme.palette.background.primary}`,

		[theme.breakpoints.up('sm')]: {
			minWidth: 0,
			fontSize: '13px'
		},
		':not(:last-child)': {
			marginRight: theme.spacing(2)
		},
		fontWeight: theme.typography.fontWeightRegular,
		'&.Mui-selected': {
			border: `2px solid ${theme.palette.primary.main}`,
			background: `${theme.palette.primary.main}`,
			color: '#000'
		}
	})
);

const Tabs: React.FC<{ 
	data: any;
	tab?: string;
}> = ({ data, tab }) => {

	return (
		<Container>
			<Box>
				<CustomTabs
					className="tabs"
					value={tab}
					TabIndicatorProps={{ style: { transition: 'none' } }}
				>
					{data?.map((item: any) => (
						<CustomTab
							key={item?.slug}
							className={`tab ${item?.buttonTag}`}
							icon={
								<img
									src={item?.icon?.data?.attributes?.url}
									alt={`${item?.title ?? 'Tab'} icon`}
								/>
							}
							label={item?.title ?? ''}
							component={RouterLink}
							to={formatLocaleUrl(`/how-to-travel/${item?.slug}`)}
							value={item?.slug ?? ''}
						/>
					))}
				</CustomTabs>
			</Box>
		</Container>
	);
};

export default Tabs;
