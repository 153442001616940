import React, { useEffect, createContext, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";

import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";

import {
  CountryContext,
  LanguageContext,
  AgeVerificationContext,
  StardriftPopupContext,
} from "./helpers/contexts";

import FeedbackProvider from "./components/FeedbackWithIcon/FeedbackProvider";

import theme from "./theme";
import axios from "axios";

import PageNotFound from "./components/PageNotFound";

import "simplebar-react/dist/simplebar.min.css";

import Home from "./components/Routes/Home";
import Shop from "./components/Routes/Shop";
import ShopCountry from "./components/Routes/ShopCountry";
import PreOrder from "./components/Routes/PreOrder";
import StoreLocator from "./components/Routes/StoreLocator";
import AirportMaps from "./components/Routes/AirportMaps";
import BuyingAbroad from "./components/Routes/BuyingAbroad";
import BuyingAbroadCountry from "./components/Routes/BuyingAbroadCountry";

import FeedbackWithIcon from "./components/FeedbackWithIcon";

import Travel from "./components/Routes/Travel";
import HowToTravel from "./components/Routes/HowToTravel";
import CustomerCare from "./components/Routes/CustomerCare";

import ImportantInformation from "./components/Routes/ImportantInformation";
import Veev from "./components/Routes/Veev";
import VeevOne from "./components/Routes/VeevOne";
import VeevNow from "./components/Routes/VeevNow";

import config from "./config.json";
import CountrySelectDialog from "./components/CountrySelectDialog/";
import StardriftPopup from "./components/StardriftPopup";

export const IqosTravelContext = createContext<any>(null!);

const App: React.FC = () => {
  const url = new URL(window.location.href);
  const skipDialogs = url.searchParams.get("skipDialogs") === "true";
  const [ageVerified, setAgeVerified] = useState<boolean>(skipDialogs);
  const [country, setCountry] = useState<any>(null);
  const [countrySelectOpen, setCountrySelectOpen] = useState<boolean>(false);
  const [stardriftPopupOpen, setStardriftPopupOpen] = useState<boolean>(false);
  const [translations, setTranslations] = useState<any>({});
  const [currentLanguage, setCurrentLanguage] = useState<string>("");

  const onChangeLanguage = (language: any) => {
    const url = new URL(window.location.href);
    if (currentLanguage === "") {
      window.open(url.origin + "/" + language.code, "_self");
    } else {
      window.open(
        url.origin +
          window.location.pathname.replace(currentLanguage, language.code) +
          window.location.search,
        "_self"
      );
    }
  };

  const onChangeCountry = (country: any) => {
    setCountry(country);
    localStorage.setItem("selectedCountry", JSON.stringify(country));
    if (window.location.pathname.includes(currentLanguage + "/shop")) {
      window.open(
        window.location.origin +
          "/" +
          currentLanguage +
          "/shop/" +
          country.CountryCode,
        "_self"
      );
    }
  };

  const isStardrift = url.search.includes("stardrift=true");

  useEffect(() => {
    document.title = useVeev
      ? "IQOS Travel • IQOS Travel"
      : "Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel";
    (async () => {
      // get language from url
      const lang = window.location.pathname.split("/")[1] || "en";
      try {
        const translationsStorage = localStorage.getItem(
          "translations-" + lang
        );
        let newTranslations;
        if (!translationsStorage) {
          const res = await axios.post(config.cmsUrl, {
            locale: lang,
          });
          const data = res.data;
          newTranslations = data;
          setTranslations(data);
          setCurrentLanguage(lang);
          localStorage.setItem("translations-" + lang, JSON.stringify(data));
        } else {
          const data = JSON.parse(translationsStorage);
          newTranslations = data;
          setTranslations(data);
          setCurrentLanguage(lang);
        }

        const selectedCountry = JSON.parse(
          localStorage.getItem("selectedCountry") as string
        );
        const ageVerification = localStorage.getItem("ageVerification");
        if (selectedCountry) {
          const country = newTranslations?.countries?.data?.find(
            (country: any) =>
              country?.attributes?.CountryCode === selectedCountry?.CountryCode
          );
          selectedCountry.Name = country?.attributes?.Name || "";
          setCountry(selectedCountry);
        }

        if (isStardrift) {
          setStardriftPopupOpen(true);
        }

        if (!selectedCountry?.CountryCode && ageVerification && !isStardrift) {
          setCountrySelectOpen(true);
        }

        document.documentElement.lang = lang;
        if (lang === "ar") {
          document.body.dir = "rtl";
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [window.location.pathname]);

  useEffect(() => {
    const ageVerification = localStorage.getItem("ageVerification");

    if (ageVerification === "true") {
      setAgeVerified(true);
    }
  }, [ageVerified]);

  useEffect(() => {
    if (skipDialogs) {
      localStorage.setItem("ageVerification", "true");
    }
  }, [skipDialogs]);

  const countries = translations?.countries?.data?.map(
    (data: any) => data.attributes
  );
  const useVeev = window.location?.pathname.includes("veev");
  return (
    <IqosTravelContext.Provider value={{ translations, country }}>
      <ThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
          <LanguageContext.Provider
            value={{
              languages: translations?.languages,
              language: currentLanguage,
              navigation: translations?.navigation,
              footer: translations?.footer,
              onChange: onChangeLanguage,
            }}
          >
            <CountryContext.Provider
              value={{
                countries,
                country,
                onOpen: () => setCountrySelectOpen(true),
                onChange: onChangeCountry,
                countrySelectOpen,
              }}
            >
              <StardriftPopupContext.Provider
                value={{
                  onOpen: () => setStardriftPopupOpen(true),
                  onChange: () => {},
                }}
              >
                <CssBaseline />
                <AgeVerificationContext.Provider
                  value={{ verified: ageVerified }}
                >
                  <FeedbackProvider>
                    <Router>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/:lang" element={<Home />} />
                        <Route path="/:lang/shop" element={<Shop />} />
                        <Route
                          path="/:lang/shop/:countryCode"
                          element={<ShopCountry />}
                        />
                        <Route path="/:lang/pre-order" element={<PreOrder />} />
                        <Route
                          path="/:lang/store-locator"
                          element={<StoreLocator />}
                        />
                        <Route
                          path="/:lang/airport-maps/:airport"
                          element={<AirportMaps />}
                        />
                        <Route
                          path="/:lang/buying-abroad"
                          element={<BuyingAbroad />}
                        />
                        <Route
                          path="/:lang/buying-abroad/:countryCode"
                          element={<BuyingAbroadCountry />}
                        />
                        <Route path="/:lang/travel" element={<Travel />} />
                        <Route
                          path="/:lang/how-to-travel/"
                          element={<HowToTravel />}
                        />
                        <Route
                          path="/:lang/how-to-travel/:tab"
                          element={<HowToTravel />}
                        />
                        <Route
                          path="/:lang/customer-care"
                          element={<CustomerCare />}
                        />
                        <Route
                          path="/:lang/important-information"
                          element={<ImportantInformation />}
                        />
                        <Route path="/:lang/veev" element={<Veev />} />
                        <Route path="/:lang/veev-one" element={<VeevOne />} />
                        <Route path="/:lang/veev-now" element={<VeevNow />} />
                        <Route path="/404" element={<PageNotFound />} />
                        <Route path="*" element={<Navigate to="/404" />} />
                      </Routes>
                    </Router>
                    <FeedbackWithIcon
                      translations={translations?.feedback?.data?.attributes}
                    />
                  </FeedbackProvider>
                  <StardriftPopup
                    translations={translations?.countrySelect?.data?.attributes}
                    open={stardriftPopupOpen}
                    onClose={() => setStardriftPopupOpen(false)}
                  />
                </AgeVerificationContext.Provider>
                {!useVeev && (
                  <CountrySelectDialog
                    countries={countries}
                    translations={translations?.countrySelect?.data?.attributes}
                    open={countrySelectOpen}
                    onClose={() => setCountrySelectOpen(false)}
                  />
                )}
              </StardriftPopupContext.Provider>
            </CountryContext.Provider>
          </LanguageContext.Provider>
        </EmotionThemeProvider>
      </ThemeProvider>
    </IqosTravelContext.Provider>
  );
};

export default App;
