import { Box, Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import VeevHealthWarning from '../VeevHealthWarning';

const VeevFooter: React.FC<{ setShowImportant: any; code: any }> = ({ setShowImportant, code }) => {
	// here
	const isArabic = code === 'ar';
	return (
		<Styles>
			<Box className="content-wrapper">
				<Box className="section-wrapper">
					<Typography className="footer-title">
						{isArabic ? 'توفر المنتج دوليًا' : 'Internaltional Product Availability'}
					</Typography>
					<Typography className="footer-body">
						{isArabic
							? 'تجدر الإشارة إلى أن VEEV NOW وVEEV ONE متوفران حاليًا في سوق دبي الحرّة فقط.'
							: 'Please note that VEEV ONE and VEEV NOW are currently only available in Dubai Duty Free.'}
						<br />
						{isArabic
							? 'ويتوفر جهازا VEEV ONE وNOW حاليًا على الصعيد المحلي في فرنسا، وبولندا، واليونان،وبلغاريا فقط.'
							: 'Additionally, VEEV ONE and NOW are currently available domestically only in France, Poliand, Greece and Bulgaria.'}
					</Typography>
				</Box>
				<Box className="section-wrapper">
					<Typography className="footer-title">
						{isArabic ? 'الاتصال بخدمة العملاء' : 'Customer Care Contact'}
					</Typography>
					<Typography className="footer-body">
						{isArabic
							? 'في حالة وقوع أي حوادث أو آثار سلبية، يُرجى الاتصال على الرقم 2559 2559 800+ (قد تُطبق بعض الرسوم على الاتصال، يُرجى استشارة مشغّل الشبكة لمزيدٍ من التفاصيل)'
							: 'In case of any adverse events or incidents please contact +800 2559 2559 (Some fees may apply, please consult your operator for more details)'}
					</Typography>
				</Box>
				<Box className="section-wrapper">
					<Typography className="footer-title">
						{isArabic ? 'الضمان الاختياري' : 'Voluntary Warranty'}
					</Typography>
					<Typography className="footer-body">
						{isArabic
							? 'مدة الضمان الاختياري في الإمارات العربية المتحدة هي 12 شهرًا من تاريخ الشراء.'
							: 'The duration of the voluntary warranty in United Arab Emirates in 12 month from the date of purchase.'}
					</Typography>
				</Box>
				<Box className="section-wrapper">
					<Typography className="footer-title">
						{isArabic ? 'تحذيرات وتعليمات السلامة' : 'Safety Warnings & Instructions'}
					</Typography>
					<a href="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/VEEV-TC.pdf">
						<Button variant={'contained'} className="button">
							{isArabic
								? 'تنزيل بطاقة تحذيرات وتعليمات السلامة'
								: 'Download Safety Warnings & Informations Card'}
						</Button>
					</a>
				</Box>
				<Box className="section-wrapper">
					<Typography className="footer-title">
						{isArabic ? 'هام' : 'Important'}
					</Typography>
					<Button
						variant={'contained'}
						className="button"
						onClick={() => {
							window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
							setTimeout(() => {
								setShowImportant(true);
							}, 500);
						}}
					>
						{isArabic ? 'شاهد' : 'View'}
					</Button>
				</Box>
			</Box>
			<VeevHealthWarning />
			<Box className="links-wrapper">
				<Box>
					{isArabic
						? '© 2022 .Philip Morris Products S.A جميع الحقوق محفوظة. '
						: '© 2022 Phillip Morris Products S.A. All rights reserved.'}
				</Box>
				<Box></Box>
				<Box>
					<a
						href="https://www.pmiprivacy.com/global/en/consumer/"
						style={{ textDecoration: 'none' }}
					>
						{isArabic ? 'سياسة الخصوصية' : 'PRIVACY POLICY'}
					</a>
				</Box>
				<Box>
					<a href="https://iqostravel.com/en" style={{ textDecoration: 'none' }}>
						{isArabic ? 'تفضيلات ملفات تعريف الارتباط' : 'COOKIE PREFERENCES'}
					</a>
				</Box>
			</Box>
		</Styles>
	);
};

export default VeevFooter;

const Styles = styled.div`
	.links-wrapper {
		background-color: #26242e;
		display: flex;
		flex-direction: row;
		min-height: 75px;
		align-items: center;
		justify-content: space-around;
		color: #ffffff;
	}
	.content-wrapper {
		background-color: #ebaf34;
		padding: 50px;
	}
	.section-wrapper {
		margin-bottom: 20px;
	}
	.footer-title {
		font-size: 2rem;
		font-weight: bold;
		color: #ffffff;
	}
	.footer-body {
		font-size: 1rem;
		color: #ffffff;
		font-weight: bold;
	}
	.button {
		background: rgba(0, 0, 0, 0);
		height: 40px;
		font-weight: normal;
		align-self: center;
		font-size: 0.8rem;
		cursor: pointer;
		border: white solid 1px;
		box-shadow: none;
		color: white !important;
	}
	.button:hover {
		color: black !important;
	}
`;
