import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import theme from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

export const QuestionIcon: React.FC<{
	children?: JSX.Element | JSX.Element[] | number;
	availability?: boolean;
	inverted?: boolean;
}> = ({ availability, children, inverted }) => {
	const desktop = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '50%',
				minHeight: { xs: 40, md: 50 },
				minWidth: { xs: 40, md: 50 },
				maxHeight: { xs: 40, md: 50 },
				maxWidth: { xs: 40, md: 50 },
				backgroundColor: inverted ? theme.palette.common.black : theme.palette.common.white,
				color: inverted ? theme.palette.common.white : theme.palette.common.black
			}}
		>
			{children ? (
				children
			) : (
				<FontAwesomeIcon
					icon={availability ? faCheck : faXmark}
					size={desktop ? '2x' : 'lg'}
					color={inverted ? theme.palette.common.white : theme.palette.common.black}
				/>
			)}
		</Box>
	);
};
