import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import Styles from './styles';

const VeevHealthWarning: React.FC<{}> = ({}) => {
	return (
		<Styles>
			<Stack justifyContent="center" alignItems="center" className="footer">
				<div className="footer-white">
					<Container maxWidth="xl">
						<Typography
							py={0}
							style={{ fontWeight: 'normal', fontSize: '1rem', opacity: 0.7 }}
						>
							This product is not risk free and provides nicotine, which is addictive.
							Only for use by adults.
						</Typography>
					</Container>
					<Container maxWidth="xl">
						<Typography py={0} style={{ fontWeight: 'bold', fontSize: '1rem' }}>
							.هذا المنتج غير خالٍ من الضرر ويحتوي على النيكوتين، الذي يسبب الادمان.
							لاستعمال البالغين فقط
						</Typography>
					</Container>
				</div>
			</Stack>
		</Styles>
	);
};

export default VeevHealthWarning;
