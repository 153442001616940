import LanguageIcon from '../LanguageIcon';
import LanguageDropdown from '../LanguageDropdown';
import { useRef, useState } from 'react';
import { IconButton } from '@mui/material';

const LanguageSelect: React.FC = () => {
	const [open, setOpen] = useState<boolean>(false);
	const $icon = useRef<HTMLButtonElement>(null);

	return (
		<>
			<IconButton ref={$icon} onClick={() => setOpen(!open)} className="gtm-nav-language">
				<LanguageIcon />
			</IconButton>

			<LanguageDropdown anchorEl={$icon} open={open} onClose={() => setOpen(false)} />
		</>
	);
};

export default LanguageSelect;
