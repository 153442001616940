import React, { useEffect } from 'react'
import gsap from 'gsap'

import { css } from '@emotion/css';

export const createStyles = () => css`
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items:center;
    width: 100%;
    position: relative;
    bottom:37%;
    .dot{
      width:5px;
      height:5px;
      border-radius:100%;
      background: white;
      opacity:0;
      margin:1px;
    }
`;

const Typing: React.FC = () => {
  const styles = createStyles()
  useEffect(()=>{
    const tl = gsap.timeline({repeat: -1})
    tl.fromTo('.one', {opacity:0}, {opacity:1, duration:0.1})
    tl.fromTo('.two', {opacity:0}, {opacity:1, duration:0.1})
    tl.fromTo('.three', {opacity:0}, {opacity:1, duration:0.1})
    tl.to('.one', {opacity:0, duration:0.1})
    tl.to('.two', {opacity:0, duration:0.1})
    tl.to('.three', {opacity:0, duration:0.1})
  },[])

  return(
    <div>
      {['one', 'two', 'three'].map(dot => <div key={dot} className={`dot ${dot}`} />)}
    </div>
  )
}

export default Typing