import {
	Box,
	Container,
	Grid,
	Stack,
	Typography,
	useMediaQuery
} from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router'
import styled from '@emotion/styled'

import theme from '../../theme'
import Layout from '../Layout'

import DestinationSelectorBar from '../DestinationSelectorBar';
import { IqosTravelContext } from '../../App'

import { formatCountryCode, formatLocaleUrl } from '../../utils/common'
import config from '../../config.json'
import axios from 'axios'
import queryString from 'query-string'

import { useCountry } from '../../helpers/contexts';
import LoadingBackdrop from '../LoadingBackdrop'

const BuyingAbroad = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);

	const navigate = useNavigate()
	const desktop = useMediaQuery(theme.breakpoints.up('md'));
	const [localTranslations, setLocalTranslations] = useState<any>({})
	const { translations } = useContext(IqosTravelContext);
	const { lang: langParam } = useParams();

	const { country, countries } = useCountry();

	const shopUrl = formatLocaleUrl(`/buying-abroad/${country?.CountryCode}`);

	useEffect(() => {
		if (country?.CountryCode) navigate(shopUrl);
	}, [country?.CountryCode]);

	useEffect(() => {
		document.title = 'Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel';

		(async () => {
			try {
				const res = await axios.post(config.cmsUrl + '/buying-abroad', {
					locale: langParam,
				})
				const data = res.data
				setLocalTranslations(data);
			} catch (e) {
				console.error(e);
			}
			setLoading(false);
		})()
	}, [langParam]);

	const airports = localTranslations?.airports || []

	const { airport } = queryString.parse(window.location.search.slice(1)) as {
		airport: string;
	};
	const [chosenAirport, setChosenAirport] = useState<string | null>(airport);

	const airportCountries = [
		...new Set(
			airports
				?.map((airport: any) => airport.market)
				?.map((airportMarket: any) =>
					countries?.find(
						(c: any) => formatCountryCode(c?.CountryCode) === formatCountryCode(airportMarket)
					)
				)
				?.filter(Boolean)
		)
	] as any;

	const page = localTranslations?.buyingAbroad?.data?.attributes || {}

	return (
		<Layout translations={translations} footerData={translations.footer?.data?.attributes}>
			<Styles>
				<Stack>
					{desktop ? null : (
						<img
							className="image-shop"
							src="/images/abroad-hero.png"
							width="100%"
							alt="Buy IQOS Abroad"
						/>
					)}
					<Grid container justifyContent="center" className="hero">
						<Container>
							<Grid container sx={{ height: '100%' }} alignItems="center">
								<Grid
									item
									sx={{ textAlign: desktop ? 'left' : 'center' }}
									sm={desktop ? 8 : 12}
								>
									<Stack direction="column" spacing={6}>
										<Typography
											component="div"
											variant="h1"
											dangerouslySetInnerHTML={{ __html: page?.hero?.title }}
											className="buying-abroad-hero-title"
										/>

										<Typography
											component="div"
											variant="h4"
											dangerouslySetInnerHTML={{
												__html: page?.hero?.description
											}}
											className="buying-abroad-hero-description"
										/>
									</Stack>

									<Box mt={8} />
								</Grid>
								<Grid item sx={{ display: desktop ? 'block' : 'none' }}>
									<img
										src={page?.hero?.image?.data?.attributes?.url}
										width="100%"
										alt={page?.hero?.image?.data?.attributes?.alternativeText}
										className="plane"
									/>
								</Grid>
							</Grid>
						</Container>
					</Grid>
					<DestinationSelectorBar
						hideCountrySelectorIfValid={!!country?.CountryCode}
						countries={airportCountries}
						airports={airports.filter(
							(airport: any) =>
								formatCountryCode(airport.market) ===
								formatCountryCode(country?.CountryCode)
						)}
						translations={{
							title: page?.autocomplete?.title,
							button: page?.autocomplete?.buttonText,
							country: page?.autocomplete?.countryPlaceholder,
							airport: page?.autocomplete?.airportPlaceholder
						}}
						selectedAirport={chosenAirport}
						onAirportChange={value => setChosenAirport(value ?? null)}
						link={formatLocaleUrl(
							`/buying-abroad/${formatCountryCode(
								country?.CountryCode
							)}?airport=${chosenAirport}`
						)}
					/>
				</Stack>
			</Styles>
			<LoadingBackdrop open={loading} />
		</Layout>
	);
};

const Styles = styled.div`
	.buying-abroad-hero-title {
		p {
			margin: 0;
		}
	}

	.buying-abroad-hero-description {
		font-weight: normal !important;
		font-size: 1.25rem;

		p {
			max-width: 500px;
		}

		p:not(:last-of-type) {
			margin-bottom: ${theme.spacing(2)};
		}
	}

	.plane {
		object-fit: contain;
		overflow: visible;
		position: absolute;
		right: 0;
		top: 165px;
		max-width: 45vw;
	}

	.hero {
		padding: 4vw;
	}

	.image-shop {
		position: absolute;
		right: 0;
		top: 40%;
		max-width: 50vw;
		width: 100%;
		transition: 0.2s ease;

		${theme.breakpoints.down('md')} {
			position: relative;
			max-width: 100%;
			padding-top: 2rem;
		}

		${theme.breakpoints.up(1800)} {
			max-width: 40vw;
		}
	}

	.left {
		* {
			max-width: 550px;
		}

		background-color: #ebaf34;
		padding-right: ${theme.spacing(3)};
		padding-top: ${theme.spacing(3)};
		padding-bottom: ${theme.spacing(3)};
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.right {
		* {
			max-width: 550px;
		}

		color: ${theme.palette.common.black};
		background-color: white;
		padding-left: ${theme.spacing(3)};
		padding-top: ${theme.spacing(3)};
		padding-bottom: ${theme.spacing(3)};
		display: flex;
		flex-direction: column;
	}
`;

export default BuyingAbroad;
