import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  useCountry,
  useLanguages,
  useStardriftPopup,
} from "../../helpers/contexts";
import theme from "../../theme";
import { formatLocaleUrl } from "../../utils/common";
import { useDataLayer } from "../../utils/dataLayer";

import { Link as RouterLink } from "react-router-dom";

const StardriftPopup: React.FC<{
  translations: any;
  open: boolean;
  onClose: () => void;
}> = ({ translations, open, onClose }) => {
  const theme = useTheme();
  const dataLayer = useDataLayer();
  const { onChange } = useStardriftPopup();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));

  const howToTravelUrl = formatLocaleUrl(`/travel`);
  const storeLocatorUrl = formatLocaleUrl(`/store-locator`);

  return (
    <Dialog
      disableScrollLock
      maxWidth="md"
      fullWidth
      className="modal"
      open={open}
      onClose={onClose}
      sx={{ height: "100%" }}
      PaperProps={{
        sx: {
          height: "100%",
          maxHeight: { xs: "90vh", md: 700 },
        },
      }}
      transitionDuration={{
        enter: 0,
        exit: theme.transitions.duration.leavingScreen,
      }}
    >
      <Styles>
        <img
          src="/images/cross-icon.svg"
          className="card-exit gtm-welcome-close"
          onClick={() => onClose()}
        />
        <DialogContent className="card-content">
          <Grid
            container
            sx={{
              height: "100%",
              display: { xs: "flex", md: "flex" },
              backgroundColor: "#002036",
            }}
          >
            {desktop ? (
              <>
                <Grid
                  className="card-left"
                  item
                  sm={6}
                  sx={{
                    backgroundImage:
                      "url('https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/stardrift-desktop-popup-image.jpg')",
                  }}
                />
                <Grid className="card-right" item sm={6}>
                  <Stack
                    sx={{ height: "100%", px: 6, py: 10 }}
                    justifyContent="space-between"
                  >
                    <Stack direction="column" spacing={3}>
                      <Typography
                        variant="h2"
                        align="center"
                        className={"card-body card-body-title"}
                        sx={{ color: "white !important", fontWeight: "500" }}
                      >
                        아이코스 일루마
                      </Typography>
                      <Typography
                        variant="h2"
                        align="center"
                        className={"card-body card-body-title"}
                        sx={{ color: "#ebdaa0 !important", fontWeight: "500" }}
                      >
                        한정판 스타드리프트
                      </Typography>
                      <Typography
                        variant="h6"
                        align="center"
                        className="card-body card-body-about-airport"
                        sx={{
                          color: "white !important",
                          fontWeight: "500",
                          fontSize: 18,
                        }}
                      >
                        지금 온라인으로 사전 구매하고 <br />
                        가까운 면세점에서 픽업하세요.
                      </Typography>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                      <Button
                        variant="outlined"
                        className="card-button gtm-welcome-shopIqos"
                        fullWidth
                        onClick={() => {
                          window.open(
                            "https://www.shilladfs.com/estore/kr/ko/search?text=iqos",
                            "_blank"
                          );
                        }}
                      >
                        <div className="label-strong">신라 면세점 </div>
                        <div className="label-weak">사전 구매 하기</div>
                      </Button>
                      <Button
                        variant="outlined"
                        className="card-button gtm-welcome-shopIqos"
                        fullWidth
                        onClick={() => {
                          window.open(
                            "https://www.ssgdfs.com/kr/main/initMain?rcntYn=N&topRecommandKwd=%5B%ED%94%84%EB%A0%88%EC%89%AC%5D%EB%A9%B4%EC%84%B8%EB%8B%A8%EB%8F%85+%EC%8A%88%EA%B0%80%EB%A1%9C%EC%A6%88%EB%A7%88%EC%8A%A4%ED%81%AC%EB%93%80%EC%98%A4+40%25+%ED%95%A0%EC%9D%B8&topRecommandLinkUrl=%2Fshop%2Fproduct%2FproductDetail%3FprdtCode%3D08119000190&topRecommandLinkTaget=false&searchTerm=iqos",
                            "_blank"
                          );
                        }}
                      >
                        <div className="label-strong">신세계 면세점 </div>
                        <div className="label-weak">사전 구매 하기</div>
                      </Button>
                      <Button
                        variant="outlined"
                        className="card-button gtm-welcome-shopIqos"
                        fullWidth
                        onClick={() => {
                          window.open(
                            "https://kor.lottedfs.com/kr/search?comSearchWord=iqos&comCollection=GOODS&comTcatCD=&comMcatCD=&comScatCD=&comPriceMin=&comPriceMax=&comErpPrdGenVal_YN=&comHsaleIcon_YN=&comSaleIcon_YN=&comCpnIcon_YN=&comSvmnIcon_YN=&comGiftIcon_YN=&comMblSpprcIcon_YN=&comSort=RANK%2FDESC&comListCount=20&txtSearchClickCheck=Y&comSpell_YN=",
                            "_blank"
                          );
                        }}
                      >
                        <div className="label-strong">롯데 면세점</div>
                        <div className="label-weak">사전 구매 하기</div>
                      </Button>
                      <Button
                        variant="outlined"
                        className="card-button gtm-welcome-shopIqos"
                        fullWidth
                        onClick={() => {
                          window.open(storeLocatorUrl, "_self");
                        }}
                      >
                        <div className="label-strong">가까운 면세</div>
                        <div className="label-weak">점 알아보기</div>
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </>
            ) : (
              <Grid container direction={"column"}>
                <Grid
                  item
                  xs={3}
                  sx={{
                    backgroundImage:
                      "url('https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/stardrift-mobile-popup-image.jpg')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                <Grid item xs={9} style={{ padding: 20 }}>
                  <Stack direction="column" spacing={3}>
                    <Typography
                      variant="h2"
                      align="center"
                      className={"card-body card-body-title"}
                      sx={{ color: "white !important", fontWeight: "500" }}
                    >
                      아이코스 일루마
                    </Typography>
                    <Typography
                      variant="h2"
                      align="center"
                      className={"card-body card-body-title"}
                      sx={{ color: "#ebdaa0 !important", fontWeight: "500" }}
                    >
                      한정판 스타드리프트
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      className="card-body card-body-about-airport"
                      sx={{
                        color: "white !important",
                        fontWeight: "500",
                        fontSize: 18,
                      }}
                    >
                      지금 온라인으로 사전 구매하고 <br />
                      가까운 면세점에서 픽업하세요.
                    </Typography>
                  </Stack>
                  <Stack direction="column" spacing={1}>
                    <Button
                      variant="outlined"
                      className="card-button gtm-welcome-shopIqos"
                      fullWidth
                      onClick={() => {
                        window.open(
                          "https://www.shilladfs.com/estore/kr/ko/search?text=iqos",
                          "_blank"
                        );
                      }}
                    >
                      <div className="label-strong">신라 면세점 </div>
                      <div className="label-weak">사전 구매 하기</div>
                    </Button>
                    <Button
                      variant="outlined"
                      className="card-button gtm-welcome-shopIqos"
                      fullWidth
                      onClick={() => {
                        window.open(
                          "https://www.ssgdfs.com/kr/main/initMain?rcntYn=N&topRecommandKwd=%5B%ED%94%84%EB%A0%88%EC%89%AC%5D%EB%A9%B4%EC%84%B8%EB%8B%A8%EB%8F%85+%EC%8A%88%EA%B0%80%EB%A1%9C%EC%A6%88%EB%A7%88%EC%8A%A4%ED%81%AC%EB%93%80%EC%98%A4+40%25+%ED%95%A0%EC%9D%B8&topRecommandLinkUrl=%2Fshop%2Fproduct%2FproductDetail%3FprdtCode%3D08119000190&topRecommandLinkTaget=false&searchTerm=iqos",
                          "_blank"
                        );
                      }}
                    >
                      <div className="label-strong">신세계 면세점 </div>
                      <div className="label-weak">사전 구매 하기</div>
                    </Button>
                    <Button
                      variant="outlined"
                      className="card-button gtm-welcome-shopIqos"
                      fullWidth
                      onClick={() => {
                        window.open(
                          "https://kor.lottedfs.com/kr/search?comSearchWord=iqos&comCollection=GOODS&comTcatCD=&comMcatCD=&comScatCD=&comPriceMin=&comPriceMax=&comErpPrdGenVal_YN=&comHsaleIcon_YN=&comSaleIcon_YN=&comCpnIcon_YN=&comSvmnIcon_YN=&comGiftIcon_YN=&comMblSpprcIcon_YN=&comSort=RANK%2FDESC&comListCount=20&txtSearchClickCheck=Y&comSpell_YN=",
                          "_blank"
                        );
                      }}
                    >
                      <div className="label-strong">롯데 면세점</div>
                      <div className="label-weak">사전 구매 하기</div>
                    </Button>
                    <Button
                      variant="outlined"
                      className="card-button gtm-welcome-shopIqos"
                      fullWidth
                      onClick={() => {
                        window.open(storeLocatorUrl, "_self");
                      }}
                    >
                      <div className="label-strong">가까운 면세</div>
                      <div className="label-weak">점 알아보기</div>
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Styles>
    </Dialog>
  );
};

const Styles = styled.div`
  z-index: 200 !important;
  height: 100%;
  border-radius: ${theme.shape.borderRadius}px;

  .card-button {
    height: 60px;
    background-color: #ebdaa0;
    border-width: 0px !important;
  }

  .label-strong {
    font-weight: 900;
  }

  .label-weak {
    font-weight: 400;
  }

  .card-exit {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    cursor: pointer;
    z-index: 200000;
    ${theme.breakpoints.up("md")} {
      opacity: 1;
      width: 24px;
    }
    /* color: ${theme.palette.common.black}; */
    /* Use filter as it's an img tag */
    /* https://codepen.io/sosuke/pen/Pjoqqp thanks */
    filter: brightness(0) saturate(100%) invert(19%) sepia(13%) saturate(762%)
      hue-rotate(220deg) brightness(88%) contrast(92%);
  }

  .card-content {
    height: 100%;
    @media screen and (orientation: landscape) and (max-height: 681px) {
      min-height: 100%;
      height: auto;
    }
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    background-color: ${theme.palette.primary.main};
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;

    ${theme.breakpoints.up("md")} {
      background-color: ${theme.palette.common.white};
      overflow: unset;
    }

    .card-mobile-image {
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 230%;
      opacity: 0.3;
      z-index: 200;
    }

    .card-content-mobile-container {
      flex-grow: 1;
      flex-direction: column;
      padding: ${theme.spacing(5, 4)};
      z-index: 300;
      overflow-y: hidden;

      svg {
        max-width: 70%;
      }

      @media screen and (max-height: 830px) and (orientation: portrait) {
        overflow-y: scroll;
      }

      .card-heading {
        color: ${theme.palette.background.default};
      }

      .card-subheading {
        color: ${theme.palette.background.default};
      }

      .card-body {
        color: ${theme.palette.common.white};

        &-mobile {
          ${theme.breakpoints.down("md")} {
            flex: 1;
            justify-content: space-around;
          }
        }

        &-about-airport {
          margin: unset;
        }

        &-title {
          margin: unset;
        }

        .gtm-welcome-pickCountry {
          margin-top: 1rem;
        }

        .select-input-mobile {
          .MuiOutlinedInput-notchedOutline {
            border: 2px solid ${theme.palette.common.white};
          }
        }
      }
    }

    .mobile-menu {
      background-color: ${theme.palette.common.white};
      color: ${theme.palette.common.black};
    }

    .card-body {
      max-width: 600px;
      max-width: 65ch;
      color: ${({ theme }) => theme.palette.common.black};
      //font-size: 1rem;
      //margin: auto;
    }

    .card-left {
      background-color: ${theme.palette.primary.main};
      position: relative;
      background-size: cover;
      background-position: center;

      .card-left-welcome-image {
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        opacity: 0.4;
      }

      .card-left-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: transparent;
        display: flex;
        flex-direction: column;
        padding: ${theme.spacing(10, 6)};
        color: ${theme.palette.common.black};

        &-image {
        }
      }

      * {
        z-index: 2;
      }
    }

    .card-right {
      display: flex;
      align-items: center;
      background-color: #002036;
    }
  }

  .hero {
    height: 65vh;
    min-height: 500px;
    position: relative;

    img {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      object-fit: cover;
    }
  }

  .magic-select {
    background: black;
  }
`;

export default StardriftPopup;
