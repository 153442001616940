import React from 'react';
import { ListItemText, Menu, MenuItem } from '@mui/material';
import { useLanguages } from '../../helpers/contexts';
import { useLocation } from 'react-router-dom';

const HIDDEN_LANGUAGES: string[] = [
	/* 'zh-Hant-TW' */
];
const LanguageDropdown: React.FC<{
	anchorEl: any;
	open: boolean;
	onClose: () => void;
}> = ({ anchorEl, open, onClose }) => {
	const { languages, language: currentLanguage, onChange } = useLanguages();
	const location = useLocation();
	
	const handleChangeName = (code: any) => {
		switch (code) {
			case 'en':
				return 'English'
			case 'fr':
				return 'Français'
			case 'de':
				return 'Deutsch'
			case 'ar':
				return 'رَبِيّ'
			case 'ja': 
				return '日本語'
			case 'zh':
				return '简体中文'
			case 'ko':
				return '한국인'
			case 'zh-Hant-TW':
				return '繁體中文'
		}
	}
	return (
		<Menu
			anchorEl={anchorEl?.current}
			open={open}
			onClose={onClose}
			style={{ minWidth: 300, maxWidth: '100%' }}
			classes={{
				paper: 'language-dropdown'
			}}
			onClick={onClose}
		>
			{location?.pathname.includes('veev') ? (
				<>
					{languages
						?.filter((language: any) => language?.code === 'en' || language?.code === 'ar')
						?.map((language: any) => {
							return (
								<MenuItem
									selected={currentLanguage?.code === (language?.code ?? 'en')}
									key={language?.code}
									value={language?.code}
									onClick={() => onChange?.(language)}
									className="gtm-nav-language-countrySelected"
								>
									<ListItemText primary={handleChangeName(language?.code)} />
								</MenuItem>
							);
						})}
				</>
			) : (
				<>
					{languages
						?.filter((language: any) => !HIDDEN_LANGUAGES.includes(language?.code))
						?.map((language: any) => {
							return (
								<MenuItem
									selected={currentLanguage?.code === (language?.code ?? 'en')}
									key={language?.code}
									value={language?.code}
									onClick={() => onChange?.(language)}
									className="gtm-nav-language-countrySelected"
								>
									<ListItemText primary={handleChangeName(language?.code)} />
								</MenuItem>
							);
						})}
				</>
			)}
		</Menu>
	);
};

export default LanguageDropdown;
