import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const HeaderBodyText: React.FC<{
	headerText?: String;
	bodyText?: String;
	component?: any;
	code?: any;
}> = ({ headerText, bodyText, component = null, code }) => {
	const isArabic = code === 'ar';
	return (
		<Styles>
			<div className="body-section">
				<Typography
					variant="h6"
					component="h6"
					className="body-section-subtitle"
					textAlign={isArabic ? 'right' : 'left'}
					style={{direction: isArabic ? 'rtl' : 'ltr'}}
				>
					{headerText}
				</Typography>
				<Typography
					variant="body1"
					component="p"
					className="body-section-body"
					textAlign={isArabic ? 'right' : 'left'}
					style={{direction: isArabic ? 'rtl' : 'ltr'}}
				>
					{bodyText}
				</Typography>
				{component}
			</div>
		</Styles>
	);
};

const Styles = styled.div`
	.body-section {
		color: #27242e;
		margin-bottom: 20px;
		width: 100%;
	}
	.body-section-title {
		margin-bottom: 20px;
	}

	.body-section-subtitle {
		font-weight: bold;
		font-size: 1.2rem;
	}
`;

export default HeaderBodyText;
