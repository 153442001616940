import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Styles from './styles';
import useEmblaCarousel from 'embla-carousel-react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import { findMiddle } from '../../../helpers/common';
import theme from '../../../theme';
import Autoplay from 'embla-carousel-autoplay';

const EmblaViewport = styled.div`
	overflow: hidden;
	width: 100%;
`;
const EmblaContainer = styled.div`
	display: flex;
	align-items: center;
`;

const EmblaSlide = styled.div<{ desktop: boolean }>`
	position: relative;
	flex: 0 0 ${props => (props.desktop ? '25.5%' : '51%')};
`;

const EmblaSlideInner = styled.div<{ selected: boolean, position: string | boolean }>`
	display: flex;
	align-items: center;
	justify-content: ${props => {
		if (!props.position) return 'center';
		if (props.position === 'prev') return 'flex-end';
		return 'flex-start';
	}};
	width: 100%;
	position: relative;
	transform: ${props => {
		if (props.selected) return 'scale(1)';
		return 'scale(0.9)';
	}};
	transition: transform 300ms;
	flex-direction: column;
	gap: ${theme.spacing(3)};

	h4 {
		display: ${props => {
			return !props?.selected ? 'none' : 'block';
		}};
		font-size: 1.5rem;
		margin-bottom: 20px;
	}
`;

const EmblaSlideImageWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	img {
		width: 50%;
		height: 50%;
		object-fit: contain;
	}
`;

const oneImages: { [key: string]: string } = {
	'Deep Yellow': "/images/veev-one/deep-yellow.png",
	'Blue Mint': "/images/veev-one/blue-mint.png",
	'Classic Tobacco': "/images/veev-one/classic-tobacco.png",
	'Coral Pink': "/images/veev-one/coral-pink.png",
	Marigold: "/images/veev-one/marigold.png",
	Red: "/images/veev-one/red.png"
};

const nowImages: { [key: string]: string } = {
	'Blue Mint': "/images/veev-now/blue-mint.png",
	'Classic Tobacco': "/images/veev-now/classic-tobacco.png",
	'Coral Pink': "/images/veev-now/coral-pink.png",
	Marigold: "/images/veev-now/marigold.png",
	Red: "/images/veev-now/red.png"
};

const keys: { [key: string]: string } = {
	'AMBER SELECTION': "/images/Heets_Amber.png",
	'BRONZE SELECTION': "/images/Heets_Bronze.png",
	'TURQUOISE SELECTION MENTHOL': "/images/Heets_Turqoise.png",
	'YELLOW SELECTION': "/images/duo-yellow.png",
	'PURPLE WAVE (0.5) MENTHOL': "/images/Heets_Purple_Wave.png",
	'PURPLE WAVE (1.2) MENTHOL': "/images/Heets_Purple_Wave.png",
	TEAK: "/images/Heets_Teak.png",
	'TEAK SELECTION': "/images/Heets_Teak.png",
	'BLUE SELECTION MENTHOL': "/images/Heets_Blue.png",
	'SILVER SELECTION': "/images/Heets_Silver.png",
	'SILVER LABEL': "/images/Heets_Silver.png",
	'SIENNA SELECTION': "/images/Heets_Sienna.png",
	'GREEN SELECTION MENTHOL': "/images/duo-green.png",
	'GREEN ZING MENTHOL': "/images/Heets_Green Zing.png",
	'RUSSET SELECTION': "/images/Heets_Russet.png",
	'3 DUO WHITE': "/images/DUO_White.png",
	'3 DUO GREY': "/images/DUO_Grey.png",
	'3 DUO BLUE': "/images/DUO_Blue.png",
	'3.0 MULTI WHITE': "/images/DUO_White.png",
	'3 DUO THE WE EDITION': "/images/DUO_We_edition.png",
	AMBER: "/images/Heets_Amber.png",
	'PRIME BRONZE TAUPE': "/images/Prime_Bronze_Taupe.png",
	'PRIME GOLDEN KHAKI': "/images/Prime_Golden_Khaki.png",
	'PRIME JADE GREEN': "/images/Prime_Jade_Green.png",
	'PRIME OBSIDIAN BLACK': "/images/Prime_Black_Obsidian.png",
	'YUGEN MENTHOL': "/images/Heets_Yugen_Menthol.png",
};

const VeevCarousel: React.FC<{
	slides: string[];
	isRestricted?: boolean;
	chosenProductType?: string | null;
	chosenSection?: string;
	device: String;
	headerText: String;
	code: String;
}> = ({ slides, isRestricted, chosenProductType, chosenSection, device, headerText, code }) => {
	const startIndex = findMiddle(slides);
	const autoplay = Autoplay();

	const [viewportRef, embla] = useEmblaCarousel(
		{
			slidesToScroll: 1,
			startIndex: startIndex
		},
		[autoplay]
	);

	const [slideInfo, setSlideInfo] = useState({
		current: 0,
		prev: slides.length,
		next: slides.length + 1 - slides.length
	});

	// get image from the title of the product
	const getShapeFromTitle = (title: string, isDevice: boolean): string => {
		const dataSet = device === 'one' ? oneImages : nowImages;
		const lowerTitle = isDevice ? title : title?.toLowerCase();
		const result = dataSet?.[lowerTitle] || dataSet?.[title];
		if (result !== null) return result;

		// fallback to amber if no title matches an image
		return device === 'one' ? oneImages?.['amber'] : nowImages?.['amber'];
	};

	const onSelect = useCallback(() => {
		if (!embla) return;
		const slideNumber = slides.length - 1;
		if (embla?.selectedScrollSnap() === 0) {
			setSlideInfo({
				current: 0,
				prev: slideNumber,
				next: slideNumber + 1 - slideNumber
			});
			return;
		}
		if (embla?.selectedScrollSnap() === slideNumber) {
			console.log('reached end of slide');
			setSlideInfo({
				current: embla?.selectedScrollSnap(),
				prev: embla?.selectedScrollSnap() - 1,
				next: 0
			});
			return;
		}
		setSlideInfo({
			current: embla?.selectedScrollSnap(),
			prev: embla?.selectedScrollSnap() - 1,
			next: embla?.selectedScrollSnap() + 1
		});
	}, [embla, setSlideInfo]);

	useEffect(() => {
		if (!embla) return;

		embla.on('init', () => {
			console.log('Initilized');
		});

		onSelect();
		embla.on('select', () => {
			onSelect();
			console.log('Selected');
		});

		embla.on('reInit', () => {
			console.log('reinit');
		});

		embla.on('resize', () => {
			console.log('Resized');
		});
	}, [embla, onSelect]);

	const getBgColorFromTitle = (title: string): string => {
		if (title.includes('Deep Yellow')) return '#EC864D';
		if (title.includes('Indiblue')) return '#A082B5';
		if (title.includes('Marigold')) return '#F3B55E';
		if (title.includes('Mauve')) return '#D088B7';
		if (title.includes('Coral Pink')) return '#F3B7AD';
		if (title.includes('Red')) return '#DB5141';
		if (title.includes('Yellow Green')) return '#CFD95F';
		if (title.includes('Gold')) return '#F3CCA1';
		if (title.includes('Summer')) return '#F7DB5E';
		if (title.includes('Velvety Mint')) return '#489E99';
		if (title.includes('Blue Mint')) return '#A7CEC9';
		if (title.includes('Sea Mint')) return '#14A4C2';
		if (title.includes('Green Mint')) return '#9BD1BC';
		if (title.includes('Classic Tobacco')) return '#BE9B85';
		if (title.includes('Blended Tobacco')) return '#7D6245';
		if (title.includes('Balanced Tobacco')) return '#A76F24';
		if (title.includes('Mellow Tobacco')) return '#C79F61';
		if (title.includes('Fine Tobacco')) return '#EEDCB8';

		return '#18161c';
	};

	const getVeevNowDevice = (title: string) => {
		if (title.includes('Blue Mint')) return "/images/veev-now/veev-now-blue-mint-device.png";
		if (title.includes('Classic Tobacco')) return "/images/veev-now/veev-now-classic-tobacco-device.png";
		if (title.includes('Coral Pink')) return "/images/veev-now/veev-now-coral-pink-device.png";
		if (title.includes('Marigold')) return "/images/veev-now/veev-now-marigold-device.png";
		if (title.includes('Red')) return "/images/veev-now/veev-now-red-device.png";
	};

	const getVeevOneDevice = (title: string) => {
		if (title.includes('Deep Yellow')) return "/images/veev-one/veev-one-deep-yellow-device.png";
		if (title.includes('Blue Mint')) return "/images/veev-one/veev-one-blue-mint-device.png";
		if (title.includes('Classic Tobacco')) return "/images/veev-one/veev-one-classic-tobacco-device.png";
		if (title.includes('Coral Pink')) return "/images/veev-one/veev-one-coral-pink-device.png";
		if (title.includes('Red')) return "/images/veev-one/veev-one-red-device.png";
	};

	const _slides = useMemo(() => [...slides, ...slides], [slides]);
	const desktop = useMediaQuery(theme.breakpoints.up('md'));

	const getArabicTitle = (title: string) => {
		if (title.includes('Deep Yellow')) return 'أصفر غامق';
		if (title.includes('Coral Pink')) return 'وردي مرجاني';
		if (title.includes('Red')) return 'أحمر';
		if (title.includes('Blue Mint')) return 'النعناع الأزرق';
		if (title.includes('Classic Tobacco')) return 'التبغ الكلاسيكي';
		if (title.includes('Marigold')) return 'أصفر وردي';
	};

	return (
		<Styles>
			<Typography
				component="div"
				variant="h1"
				dangerouslySetInnerHTML={{
					__html: headerText as string
				}}
				style={{ textAlign: 'center', color: '#27242e', fontSize: '2rem' }}
			/>
			<Box
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: '5vw' }}
			>
				<EmblaViewport ref={viewportRef}>
					<EmblaContainer style={{ alignItems: 'flex-start' }}>
						{_slides?.map((slide, index) => {
							// @ts-ignore
							const backgroundSrc = index === slideInfo?.current &&
									getShapeFromTitle(slide, true) as string
							return (
								<EmblaSlide desktop={desktop} key={index}>
									<EmblaSlideInner
										selected={index === slideInfo?.current}
										position={
											index === slideInfo?.prev
												? 'prev'
												: index === slideInfo?.next
												? 'next'
												: false
										}
									>
										<EmblaSlideImageWrapper>
											<div className="variant-avatar">
												{ backgroundSrc ?
												<img
													src={backgroundSrc as string}
													style={{
														backgroundColor: getBgColorFromTitle(slide),
														minHeight: desktop ? 150 : 50,
														aspectRatio: 1,
														padding:
															index === slideInfo?.current ? 0 : 10
													}}
												/> : 
												<div
													style={{
														backgroundColor: getBgColorFromTitle(slide),
														minHeight: desktop ? 150 : 50,
														aspectRatio: 1,
														padding:
															index === slideInfo?.current ? 0 : 10,
													}}
													className="product-background"
												></div>
												}
												<img
													src={
														device === 'one'
															? getVeevOneDevice(slide)
															: getVeevNowDevice(slide)
													}
													style={{
														minHeight: desktop ? 150 : 50,
														aspectRatio: 1,
														position: 'absolute',
														top: 0,
														left: 0,
														height: '98%',
														width: 'inherit',
														padding: 5
													}}
												/>
											</div>
										</EmblaSlideImageWrapper>
										<Typography
											variant="h4"
											align="center"
											style={{ color: '#27242e' }}
										>
											{code === 'ar' ? (
												<>{getArabicTitle(slide)}</>
											) : (
												<>
													VEEV {device === 'one' ? 'ONE' : 'NOW'} {slide}
												</>
											)}
										</Typography>
									</EmblaSlideInner>
								</EmblaSlide>
							);
						})}
					</EmblaContainer>
				</EmblaViewport>
			</Box>
		</Styles>
	);
};

export default VeevCarousel;
