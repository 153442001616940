import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import {
	Autocomplete,
	Button,
	Container,
	Grid,
	Paper,
	Typography,
	useMediaQuery
} from '@mui/material';
import theme from '../../theme';
import { Link as RouterLink } from 'react-router-dom';
import { useCountry } from '../../helpers/contexts';
import { useDataLayer } from '../../utils/dataLayer';
import { formatCountryCode } from '../../utils/common';

const DestinationSelectorBar: React.FC<{
	translations: {
		title: string;
		button: string;
		country: string;
		airport: string;
	};
	countries: any;
	hideCountrySelectorIfValid?: boolean;
	selectedCountry?: string | null;
	onCountryChange?: (countryCode: string | null) => void;
	airports: any;
	selectedAirport: string | null;
	onAirportChange?: (value?: string | null) => void;
	link?: string;
	onSubmit?: () => void;
	hideButton?: boolean;
	extraFields?: {
		placeholder: string;
		options: string[] | undefined;
		selectedValue?: string | null;
		onChange?: (value: string | null) => void;
		tag?: string;
	}[];
	onOperatorChange?: (newOperator: string | null) => void;
}> = ({
	translations,
	countries,
	hideCountrySelectorIfValid,
	selectedCountry,
	onCountryChange,
	airports: _airports,
	selectedAirport,
	onAirportChange,
	link,
	onSubmit,
	hideButton,
	extraFields,
	onOperatorChange
}) => {
	const { country, onChange: onChangeCountry } = useCountry(); 
	const dataLayer = useDataLayer();
	const desktop = useMediaQuery(theme.breakpoints.up('md'));
	const isExternalUrl = !!link && /(http(s?)):\/\//i.test(link);
	const airports = useMemo(
		() =>
			_airports?.map((airport: any) => ({
				...airport,
				name: airport?.name === "Cote D\\\\'Azur" ? "Cote D'Azur" : airport?.name
			})),
		[_airports]
	);

	const countryExists = countries?.some(
		(x: any) =>
			formatCountryCode(x?.CountryCode?.toLowerCase()) ===
			formatCountryCode(country?.CountryCode?.toLowerCase())
	);

	useEffect(() => {
		if (!country || !selectedCountry) return
		onOperatorChange?.(null);
		onAirportChange?.(null);

		if (selectedCountry) {
			const countryData = countries?.find(
				(c: any) =>
					formatCountryCode(c?.CountryCode?.toLowerCase()) ===
					formatCountryCode(selectedCountry?.toLowerCase())
			);
			// Listen to country but allow the encapsulating element to
			// decide how to handle the change.
			onChangeCountry?.(countryData);
		}
	}, [selectedCountry]);

	return (
		<Styled>
			<Grid
				container
				justifyContent="center"
				className="destination"
				sx={{ display: 'flex' }}
			>
				<Container>
					<Grid
						className="destination-wrapper"
						container
						direction="column"
						wrap="nowrap"
					>
						<Grid container item justifyContent="center" sx={{ position: 'relative' }}>
							<Typography
								variant="body1"
								color="#35303E"
								sx={{
									fontWeight: 600,
									margin: desktop ? '1.5rem 0' : '2rem 0',
									textAlign: desktop ? 'left' : 'center'
								}}
							>
								{translations?.title}
							</Typography>

							<Grid
								className="inputs"
								container
								item
								direction={desktop ? 'row' : 'column'}
								spacing={2}
								wrap="nowrap"
								justifyContent="center"
								alignItems="center"
							>
								{(!hideCountrySelectorIfValid || !countryExists) && (
									<Grid item>
										<Autocomplete
											disablePortal
											PaperComponent={({ children }) => (
												<Paper
													sx={{
														background: theme.palette.common.white,
														color: theme.palette.common.black
													}}
													elevation={0}
												>
													{children}
												</Paper>
											)}
											renderOption={(props, option) => (
												<li {...props}>{option?.Name}</li>
											)}
											options={countries.sort((a: any, b: any) =>
												a?.Name.localeCompare(b?.Name)
											)}
											placeholder={translations.country}
											value={
												countries?.some(
													(x: any) => x?.CountryCode === country?.CountryCode
												)
													? country
													: null
											}
											onChange={(x, newCountry) =>
												!!newCountry && onChangeCountry(newCountry)
											}
											getOptionLabel={(option: any) => option?.Name}
											renderInput={params => (
												<div
													ref={params.InputProps.ref}
													style={{
														backgroundColor: 'transparent !important'
													}}
													className={'gtm-destinationBar-country'}
												>
													<input
														className="location-input gtm-destinationBar-country"
														placeholder={translations.country}
														type="text"
														{...params.inputProps}
													/>
												</div>
											)}
										/>
									</Grid>
								)}
								<Grid item className={'gridItem'}>
									<Autocomplete
										disablePortal
										className={'autocomplete'}
										disabled={
											!country?.CountryCode ||
											!airports?.map((a: any) => a?.iata).filter(Boolean).length
										}
										PaperComponent={({ children }) => (
											<Paper
												sx={{
													background: theme.palette.common.white,
													color: theme.palette.common.black
												}}
												elevation={0}
											>
												{children}
											</Paper>
										)}
										renderOption={(props, option) => {
											if (option?.length === 3) {
												const name =
													airports?.find((a: any) => a?.iata === option)?.name ??
													'';

												return (
													<li {...props}>
														{name} ({option})
													</li>
												);
											}

											return <li {...props}>{option}</li>;
										}}
										// @ts-ignore
										options={airports?.map(a => a?.iata).filter(Boolean)}
										placeholder={translations?.airport}
										value={selectedAirport}
										onChange={(_, value) => {
											const matchedAirport = airports?.find(
												(airport: any) => airport?.iata === value
											);
											onOperatorChange?.(
												matchedAirport?.links?.length === 1
													? matchedAirport?.links?.[0]?.operator
													: null
											);
											onAirportChange?.(value);
											dataLayer({
												event: 'selectAirport',
												airportName: `${
													matchedAirport?.name ?? ''
												} (${value})`,
												airportCountry: selectedCountry
											});
										}}
										getOptionLabel={option => {
											if (option?.length === 3) {
												const name =
													airports?.find((a: any) => a?.iata === option)?.name ??
													'';

												return `${name} (${option})`;
											}
											return option;
										}}
										renderInput={params => (
											<div
												ref={params.InputProps.ref}
												style={{
													backgroundColor: 'transparent !important'
													// display: 'none'
												}}
												className={
													'location-input-container gtm-destinationBar-airport'
												}
											>
												<input
													className="location-input gtm-destinationBar-airport"
													placeholder={translations.airport}
													type="text"
													{...params.inputProps}
												/>
											</div>
										)}
									/>
								</Grid>
								{extraFields?.map(extraField => (
									<Grid item>
										<Autocomplete
											disablePortal
											disabled={!selectedAirport}
											PaperComponent={({ children }) => (
												<Paper
													sx={{
														background: theme.palette.common.white,
														color: theme.palette.common.black
													}}
													elevation={0}
												>
													{children}
												</Paper>
											)}
											renderOption={(props, option) => {
												return <li {...props}>{option}</li>;
											}}
											// @ts-ignore
											options={extraField.options}
											placeholder={extraField.placeholder}
											value={extraField.selectedValue}
											onChange={(_, value) => extraField?.onChange?.(value)}
											getOptionLabel={option => {
												return option;
											}}
											renderInput={params => (
												<div
													ref={params.InputProps.ref}
													style={{
														backgroundColor: 'transparent !important'
													}}
												>
													<input
														className={`location-input ${extraField.tag}`}
														placeholder={extraField.placeholder}
														type="text"
														{...params.inputProps}
													/>
												</div>
											)}
										/>
									</Grid>
								))}
								<Grid item>
									{!hideButton && (
										<Button
											variant="outlined"
											disabled={!selectedAirport}
											sx={{ height: 50 }}
											component={isExternalUrl ? 'a' : RouterLink}
											href={isExternalUrl ? link : undefined}
											to={!isExternalUrl ? link : undefined}
											target={isExternalUrl ? '_blank' : undefined}
											className="gtm-destinationBar-go"
											onClick={(e: any) => {
												if (!!onSubmit) {
													onSubmit();
												}
											}}
										>
											{translations?.button}
										</Button>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</Styled>
	);
};

const Styled = styled.div`
	.destination {
		background: ${theme.palette.primary.main};
		padding: 2vw;

		${theme.breakpoints.down('md')} {
			padding-bottom: 10vh;
		}

		.destination-wrapper {
			position: relative;

			.inputs {
				margin-bottom: 1.5em;
			}

			.location-input {
				display: none !important;
				outline: none;
				border: solid red;
				border-radius: 50px;
				padding: 1rem 2rem;
				min-width: 300px;
				font-size: 1rem;
				font-weight: 600;
				color: #35303e;
				background: transparent;
			}

			.location-select {
				outline: none;
				border: none;
				border-radius: 50px;
				padding: 1rem;
				margin: 1rem;
				min-width: 300px;
				font-size: 1rem;
				font-weight: 600;
				color: #35303e;
			}

			.location-option {
				outline: none;
				border: none;
				border-radius: 50px;
				padding: 1rem;
				min-width: 300px;
				font-size: 1rem;
				font-weight: 600;
				color: #35303e;
			}

			.Mui-disabled {
				color: #35303e;
				border-color: #35303e;
				//background: transparent;
				opacity: 0.8;
			}

			${({ theme }) => theme.breakpoints.down('md')} {
				.gtm-destinationBar-go {
					width: 300px;
				}
			}

			${({ theme }) => theme.breakpoints.down('md')} {
				.gtm-destinationBar-go {
					width: 300px;
				}
			}

			.button {
				border: 3px solid ${theme.palette.background.default};
				border-radius: 50px;
				min-width: 150px;
				height: 51px;
				color: ${theme.palette.background.default};
				font-size: 1rem;
				text-transform: capitalize;
				padding: 0.5rem 0;
			}

			.button:hover {
				border: 3px solid #fff;
			}
		}
	}

	.MuiAutocomplete-input {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		min-width: 300px;
		width: 100%;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.MuiAutocomplete-popper {
		background: #fff;
		color: #35303e;
		font-size: 1rem;
		font-weight: 800;
		border-radius: 50px;
	}
`;

export default DestinationSelectorBar;
