import {
	Breadcrumbs,
	Button,
	Container,
	Grid,
	Link,
	Stack,
	Typography,
	useMediaQuery
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'

import theme from '../../theme'
import Layout from '../Layout'
import ShopPopup from '../ShopPopup/'
import { IqosTravelContext } from '../../App'
import ImageTile from '../ImageTile'

import { formatLocaleUrl } from '../../utils/common'
import config from '../../config.json'
import axios from 'axios'

import { useCountry } from '../../helpers/contexts'
import LoadingBackdrop from '../LoadingBackdrop'

const ShopCountry = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);
	const { country } = useCountry()
	const desktop = useMediaQuery(theme.breakpoints.up('md'))

	const [openStorePopup, setOpenStorePopup] = useState(false)

	const [localTranslations, setLocalTranslations] = useState<any>({})
	const [airports, setAirports] = useState<any>([])
	const { translations } = useContext(IqosTravelContext);
	const { lang: langParam, countryCode } = useParams();

	useEffect(() => {
		document.title = 'Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel';

		(async () => {
			try {
				const res = await axios.post(config.cmsUrl + '/shop', {
					locale: langParam,
				})
				const data = res.data
				setLocalTranslations(data);

				const res2 = await axios.post(config.cmsUrl + '/getAirportMarkets', {
					countryCode,
				})

				const data2 = res2.data
				setAirports(data2);
			} catch (e) {
				console.error(e);
			}
			setLoading(false);
		})()
	}, [langParam, countryCode]);

	const page = localTranslations?.shop?.data?.attributes || {}
	const navigation = translations?.navigation?.data?.attributes || {}

	return (
		<Layout translations={translations} footerData={translations.footer?.data?.attributes}>
			<Styles>
				<Stack>
					{desktop ? null : (
						<img
							className="image-shop"
							src="/images/shop-hero.png"
							width="100%"
							alt={`Shop IQOS in ${country?.Name}`}
						/>
					)}
					<Grid container justifyContent="center" className="hero">
						<Container>
							<Breadcrumbs separator=">">
								<Link
									component={RouterLink}
									to={formatLocaleUrl(`/${navigation?.shop?.toLowerCase()}`)}
								>
									{navigation?.shop}
								</Link>
								<Link component={RouterLink} to="#" aria-current="page">
									{country?.Name}
								</Link>
							</Breadcrumbs>
							<Grid container direction="row" wrap="nowrap" sx={{ mt: 5 }}>
								<Grid
									item
									container
									direction="column"
									sx={{ textAlign: desktop ? 'left' : 'center' }}
									sm={desktop ? 8 : 12}
								>
									<Stack direction="column" spacing={6} sx={{ my: 4 }}>
										<Typography gutterBottom variant="h1">
											{page?.titleWithLanguage}
											<br />
											<b style={{ color: theme.palette.primary.main }}>
												{country?.Name}
											</b>
										</Typography>
										<Typography
											gutterBottom
											variant="h6"
											fontWeight={theme.typography.fontWeightRegular}
										>
											{page?.description}
										</Typography>
										<Button
											variant="outlined"
											color="secondary"
											sx={{ width: 'max-content', my: 3 }}
											size="large"
											className="gtm-travel-quickTips"
											onClick={() => setOpenStorePopup(true)}
										>
											{page?.buttonText}
										</Button>
									</Stack>
								</Grid>
							</Grid>
						</Container>
						{desktop && (
							<img
								className="image-shop"
								src="/images/shop-hero.png"
								width="100%"
								alt={`Shop IQOS in ${country?.Name}`}
							/>
						)}
					</Grid>
					<div className="shop-items">
						<Container>
							<Grid container justifyContent="center" spacing={2}>
								{page?.card?.map(
									({ title, subtitle, image, buttonText, buttonLink, buttonTag }: any) => (
										<Grid item md={4} xs={12} key={title}>
											<ImageTile
												title={title}
												subTitle={subtitle}
												linkText={buttonText}
												link={
													buttonLink?.startsWith('/')
														? formatLocaleUrl(
															`${buttonLink}${country?.CountryCode}`
														)
														: `${buttonLink}${country?.CountryCode}`
												}
												image={image?.data?.attributes?.url}
												className={buttonTag}
											/>
										</Grid>
									)
								)}
							</Grid>
						</Container>
					</div>
				</Stack>
				<ShopPopup
					open={openStorePopup}
					onClose={() => setOpenStorePopup(false)}
					data={page?.popup}
					airports={airports.airports}
				/>
			</Styles>
			<LoadingBackdrop open={loading} />
		</Layout>
	);
};

const Styles = styled.div`
	.hero {
		padding: 4vw;
		min-height: 500px;

		${theme.breakpoints.up('lg')} {
			height: auto;
			//min-height: 800px;
		}
	}

	.text-area {
		padding: 8vw;
	}

	${theme.breakpoints.down('md')} {
		.gtm-travel-quickTips {
			margin-left: auto;
			margin-right: auto;
		}
	}

	.image-shop {
		position: absolute;
		right: 0;
		width: 35vw;
		min-width: 200px;
		transition: 0.2s ease;

		${theme.breakpoints.down('md')} {
			position: relative;
			max-width: 70%;
			//right: -30vw;
			padding-top: 2rem;
			padding-bottom: 2rem;
			width: 100%;
			margin-left: auto;
		}

		${theme.breakpoints.up(1800)} {
			max-width: 35vw;
		}
	}

	.MuiAutocomplete-input {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		min-width: 300px;
		width: 100%;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.MuiAutocomplete-popper {
		background: #fff;
		color: #35303e;
		font-size: 1rem;
		font-weight: 800;
		border-radius: 50px;
	}

	.destination {
		background: ${theme.palette.primary.main};
		padding: 2vw;
	}

	.destination-wrapper {
		position: relative;
	}

	.close-icon {
		position: absolute;
		top: -25px;
		right: 0;
		cursor: pointer;
	}

	.inputs {
		flex-grow: 1;
	}

	.location-input {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem 2rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
		background: transparent;
	}

	.location-select {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		margin: 1rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.location-option {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.shop-items {
		background: #fff;
		padding: 4vw 0;

		${theme.breakpoints.down('md')} {
			padding-bottom: 8vh;
		}

		.tile {
			min-height: unset !important;
		}
	}

	.image-cta-square {
		position: relative;

		img {
			position: absolute;
		}
	}
`;

export default ShopCountry;
