import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Styles from './styles';
import useEmblaCarousel from 'embla-carousel-react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import { findMiddle } from '../../helpers/common';
import theme from '../../theme';
import Autoplay from 'embla-carousel-autoplay';

type EmblaSlideInnerProps = {
	selected: boolean;
	position: string | boolean;
};

const EmblaViewport = styled.div`
	overflow: hidden;
	width: 100%;
`;
const EmblaContainer = styled.div`
	display: flex;
	align-items: center;
`;

const EmblaSlide = styled.div<{ desktop: boolean }>`
	position: relative;
	flex: 0 0 ${props => (props.desktop ? '25.5%' : '51%')};
`;

const EmblaSlideInner = styled.div`
	display: flex;
	align-items: center;
	justify-content: ${(props: EmblaSlideInnerProps) => {
		if (!props.position) return 'center';
		if (props.position === 'prev') return 'flex-end';
		return 'flex-start';
	}};
	width: 100%;
	position: relative;
	transform: ${(props: EmblaSlideInnerProps) => {
		if (props.selected) return 'scale(1)';
		return 'scale(0.9)';
	}};
	transition: transform 300ms;
	flex-direction: column;
	gap: ${theme.spacing(3)};

	h4 {
		display: ${(props: EmblaSlideInnerProps) => {
		return !props?.selected ? 'none' : 'block';
	}};
		font-size: 1.5rem;
		margin-bottom: 20px;
	}
`;

const EmblaSlideImageWrapper = styled.div<{ desktop: boolean }>`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	.product-img, .product-img-shape {
		width: 50%;
		height: 50%;
		object-fit: contain;
		border-radius: 50%;
	}

	.product-img-shape {
		transform: translateX(50%);
	}
`;

const logoImages: { [key: string]: string } = {
	amber: "/images/Heets_Amber.png",
	blue: "/images/Heets_Blue.png",
	bronze: "/images/Heets_Bronze.png",
	swift: "/images/Terea_Launa_Swift.png",
	mauve: "/images/Terea_Mauve_Wave.png",
	'mauve wave menthol': "/images/Terea_Mauve_Wave.png",
	russet: "/images/Heets_Russet.png",
	'sienna selection': "/images/Heets_Sienna.png",
	sienna: "/images/Heets_Sienna.png",
	silver: "/images/Heets_Silver.png",
	teak: "/images/Heets_Teak.png",
	turquoise: "/images/Heets_Turqoise.png",
	'willow menthol': "/images/Terea_Willow.png",
	'turquoise menthol': "/images/Terea_Turqoise.png",
	yellow: "/images/duo-yellow.png",
	'AMBER CARTON': "/images/Heets_Amber.png",
	'BLUE CARTON': "/images/Heets_Blue.png",
	'BRONZE CARTON': "/images/Heets_Bronze.png",
	'SWIFT CARTON': "/images/Terea_Launa_Swift.png",
	'MAUVE CARTON': "/images/Terea_Mauve_Wave.png",
	'MAUVE WAVE MENTHOL CARTON': "/images/Terea_Mauve_Wave.png",
	'RUSSET CARTON': "/images/Heets_Russet.png",
	'SIENNA CARTON': "/images/Heets_Sienna.png",
	'SILVER CARTON': "/images/Heets_Silver.png",
	'TEAK CARTON': "/images/Heets_Teak.png",
	'TURQUOISE CARTON': "/images/Heets_Turqoise.png",
	'WILLOW MENTHOL CARTON': "/images/Terea_Willow.png",
	'TURQUOISE MENTHOL CARTON': "/images/Terea_Turqoise.png",
	'YELLOW CARTON': "/images/duo-yellow.png",
};

const keys: { [key: string]: string } = {
	'AMBER SELECTION': "/images/Heets_Amber.png",
	'BRONZE SELECTION': "/images/Heets_Bronze.png",
	'TURQUOISE SELECTION MENTHOL': "/images/Heets_Turqoise.png",
	'YELLOW SELECTION': "/images/duo-yellow.png",
	'PURPLE WAVE (0.5) MENTHOL': "/images/Heets_Purple_Wave.png",
	'PURPLE WAVE (1.2) MENTHOL': "/images/Heets_Purple_Wave.png",
	TEAK: "/images/Heets_Teak.png",
	'TEAK SELECTION': "/images/Heets_Teak.png",
	'BLUE SELECTION MENTHOL': "/images/Heets_Blue.png",
	'SILVER SELECTION': "/images/Heets_Silver.png",
	'SILVER LABEL': "/images/Heets_Silver.png",
	'SIENNA SELECTION': "/images/Heets_Sienna.png",
	'GREEN SELECTION MENTHOL': "/images/duo-green.png",
	'GREEN ZING MENTHOL': "/images/Heets_Green Zing.png",
	'RUSSET SELECTION': "/images/Heets_Russet.png",
	'3 DUO WHITE': "/images/DUO_White.png",
	'3 DUO GREY': "/images/DUO_Grey.png",
	'3 DUO BLUE': "/images/DUO_Blue.png",
	'3.0 MULTI WHITE': "/images/DUO_White.png",
	'3 DUO THE WE EDITION': "/images/DUO_We_edition.png",
	AMBER: "/images/Heets_Amber.png",
	'PRIME BRONZE TAUPE': "/images/Prime_Bronze_Taupe.png",
	'PRIME GOLDEN KHAKI': "/images/Prime_Golden_Khaki.png",
	'PRIME JADE GREEN': "/images/Prime_Jade_Green.png",
	'PRIME OBSIDIAN BLACK': "/images/Prime_Black_Obsidian.png",
	'YUGEN CARTON': "/images/Heets_Yugen_Menthol.png",
};

const EmblaCarousel: React.FC<{
	slides: any[];
	isRestricted: boolean;
	chosenProductType: string | null;
	chosenSection: string;
}> = ({ slides, isRestricted, chosenProductType, chosenSection }) => {
	const startIndex = findMiddle(slides);
	const autoplay = Autoplay();

	const [viewportRef, embla] = useEmblaCarousel(
		{
			slidesToScroll: 1,
			startIndex: startIndex
		},
		[autoplay]
	);

	const [slideInfo, setSlideInfo] = useState({
		current: 0,
		prev: slides.length,
		next: slides.length + 1 - slides.length
	});

	// get image from the title of the product
	const getShapeFromTitle = (
		title: string,
		isDevice: boolean,
		chosenProductType: string | null
	): string => {
		const dataSet = isDevice ? keys : logoImages;
		const result = dataSet?.[title] || dataSet?.[title?.toLowerCase()];
		if (result !== null) return result;

		// fallback to amber if no title matches an image
		return logoImages?.['amber'];
	};

	const onSelect = useCallback(() => {
		if (!embla) return;
		const slideNumber = slides.length - 1;
		if (embla?.selectedScrollSnap() === 0) {
			setSlideInfo({
				current: 0,
				prev: slideNumber,
				next: slideNumber + 1 - slideNumber
			});
			return;
		}
		if (embla?.selectedScrollSnap() === slideNumber) {
			console.log('reached end of slide');
			setSlideInfo({
				current: embla?.selectedScrollSnap(),
				prev: embla?.selectedScrollSnap() - 1,
				next: 0
			});
			return;
		}
		setSlideInfo({
			current: embla?.selectedScrollSnap(),
			prev: embla?.selectedScrollSnap() - 1,
			next: embla?.selectedScrollSnap() + 1
		});
	}, [embla, setSlideInfo]);

	useEffect(() => {
		if (!embla) return;

		embla.on('init', () => {
			console.log('Initilized');
		});

		onSelect();
		embla.on('select', () => {
			onSelect();
			console.log('Selected');
		});

		embla.on('reInit', () => {
			console.log('reinit');
		});

		embla.on('resize', () => {
			console.log('Resized');
		});
	}, [embla, onSelect]);

	const getBgColorFromTitle = (title: string): string => {
		if (title.includes('amber')) return 'orange';
		if (title.includes('blue')) return 'lightblue';
		if (title.includes('sienna')) return 'peru';
		if (title.includes('yellow')) return 'goldenrod';
		if (title.includes('teak')) return 'indigo';
		if (title.includes('russet')) return 'tan';
		if (title.includes('bronze')) return 'burlywood';
		if (title.includes('white')) return 'lightgrey';

		// no changes
		if (title.includes('grey')) return 'grey';
		if (title.includes('turquoise')) return 'turquoise';
		if (title.includes('red')) return 'red';
		if (title.includes('green')) return 'green';
		if (title.includes('purple')) return 'purple';
		if (title.includes('orange')) return 'orange';
		if (title.includes('silver')) return 'silver';
		if (title.includes('black')) return 'black';
		if (title.includes('gold')) return '#B59410';
		if (title.includes('mint')) return 'lightgreen';
		if (title.includes('menthol')) return 'lightgreen';

		return '#18161c';
	};

	const _slides = useMemo(() => [...slides, ...slides], [slides]);

	const desktop = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Styles>
			<Box
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: '5vw' }}
			>
				<EmblaViewport ref={viewportRef}>
					<EmblaContainer style={{ alignItems: 'flex-start' }}>
						{_slides?.map((slide, index) => {
							const backgroundImage = chosenSection === 'TOBACCO STICKS' &&
								chosenProductType === 'ILUMA'
								? getShapeFromTitle(
									slide,
									false,
									chosenProductType
								)
								: getShapeFromTitle(
									slide,
									true,
									chosenProductType
								)
							return (
								<EmblaSlide desktop={desktop} key={index}>
									<EmblaSlideInner
										selected={index === slideInfo?.current}
										position={
											index === slideInfo?.prev
												? 'prev'
												: index === slideInfo?.next
													? 'next'
													: false
										}
									>
										<EmblaSlideImageWrapper desktop={desktop}>
											{backgroundImage ?
												<img
													src={backgroundImage}
													alt={slide}
													className="product-img"
												/> :
												<div className="variant-avatar">
													<div className="product-img-shape"
														style={{
															backgroundColor: getBgColorFromTitle(
																slide.toLowerCase()
															),
															backgroundSize: 'contain',
															minHeight: desktop ? 150 : 50,
															aspectRatio: 1,
														}}
													/>
												</div>
											}
										</EmblaSlideImageWrapper>
										<Typography variant="h4" align="center">
											{slide}
										</Typography>
									</EmblaSlideInner>
								</EmblaSlide>
							);
						})}
					</EmblaContainer>
				</EmblaViewport>
			</Box>
		</Styles>
	);
};

export default EmblaCarousel;
