import React from 'react';
import styled from '@emotion/styled';

interface LanguageIconProps {
	language?: string;
	size?: number;
	style?: { [x: string]: any };
	color?: 'light' | 'dark';
}

const LanguageIcon: React.FC<LanguageIconProps & { innerRef: any }> = ({
	innerRef,
	size = 25,
	style,
	color = 'light'
}) => {
	return (
		<Styles
			ref={innerRef}
			style={{
				width: size,
				height: size,
				...(style ?? {})
			}}
		>
			<img
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover'
				}}
				alt="language icon globe"
			src={color === 'light' ? '/images/icon-globe.svg' : '/images/icon-globe-dark.svg'}
			/>
		</Styles>
	);
};

const Styles = styled.div`
	font-size: 1rem;

	svg,
	img {
		vertical-align: top;
		max-width: none;
	}
`;

export default React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & LanguageIconProps>(
	(props, ref) => <LanguageIcon {...props} innerRef={ref} />
);
