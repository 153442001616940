import React, { useEffect } from "react";
import Styles from "./styles";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import LanguageSelect from "../LanguageSelect";
import { useCountry, useStardriftPopup } from "../../helpers/contexts";
import theme from "../../theme";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { formatLocaleUrl } from "../../utils/common";

const Sidebar: React.FC<{
  open: boolean;
  onClose: () => void;
  translations: any;
  reducedNav?: boolean;
}> = ({ open, onClose, translations, reducedNav }) => {
  const { country, onOpen: openCountrySelect } = useCountry();
  const location = useLocation();

  const countryPath = !!country?.CountryCode ? `/${country?.CountryCode}` : "";
  const shopUrl = formatLocaleUrl(`/shop${countryPath}`);
  const preOrderUrl = formatLocaleUrl(`/pre-order`);
  const storeLocatorUrl = formatLocaleUrl(`/store-locator`);
  const buyingAbroadUrl = formatLocaleUrl(`/buying-abroad${countryPath}`);
  const travelUrl = formatLocaleUrl(`/travel`);
  const customerCareUrl = formatLocaleUrl("/customer-care");
  const howToTravelUrl = formatLocaleUrl("/how-to-travel");
  useEffect(() => {
    onClose?.();
  }, [location, country]);

  const navigation = (
    <Box role="presentation" sx={{ pr: 5, flexGrow: 1, overflowY: "auto" }}>
      <List>
        <>
          <ListItem disablePadding disableGutters>
            <ListItemButton component={RouterLink} to={shopUrl} sx={{ pl: 5 }}>
              <ListItemText
                secondary={translations?.shop}
                secondaryTypographyProps={{
                  fontWeight: theme.typography.fontWeightBold,
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding disableGutters>
            <ListItemButton
              component={RouterLink}
              to={preOrderUrl}
              sx={{ pl: 10 }}
            >
              <ListItemText
                secondary={translations?.preOrder}
                secondaryTypographyProps={{
                  fontWeight: theme.typography.fontWeightBold,
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding disableGutters>
            <ListItemButton
              component={RouterLink}
              to={storeLocatorUrl}
              sx={{ pl: 10 }}
            >
              <ListItemText
                secondary={translations?.storeLocator}
                secondaryTypographyProps={{
                  fontWeight: theme.typography.fontWeightBold,
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding disableGutters>
            <ListItemButton
              component={RouterLink}
              to={buyingAbroadUrl}
              sx={{ pl: 10 }}
            >
              <ListItemText
                secondary={translations?.buyingAbroad}
                secondaryTypographyProps={{
                  fontWeight: theme.typography.fontWeightBold,
                }}
              />
            </ListItemButton>
          </ListItem>
          <Box sx={{ mt: 4 }} />
          <ListItem disablePadding disableGutters>
            <ListItemButton
              component={RouterLink}
              to={travelUrl}
              sx={{ pl: 5 }}
            >
              <ListItemText
                secondary={translations?.travel}
                secondaryTypographyProps={{
                  fontWeight: theme.typography.fontWeightBold,
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding disableGutters>
            <ListItemButton
              component={RouterLink}
              to={howToTravelUrl}
              sx={{ pl: 10 }}
            >
              <ListItemText
                secondary={translations?.howToTravel}
                secondaryTypographyProps={{
                  fontWeight: theme.typography.fontWeightBold,
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding disableGutters>
            <ListItemButton
              component={RouterLink}
              to={customerCareUrl}
              sx={{ pl: 10 }}
            >
              <ListItemText
                secondary={translations?.customerCare}
                secondaryTypographyProps={{
                  fontWeight: theme.typography.fontWeightBold,
                }}
              />
            </ListItemButton>
          </ListItem>
        </>
      </List>
    </Box>
  );
  return (
    <Styles>
      <Drawer
        anchor={"left"}
        open={open}
        onClose={onClose}
        variant="temporary"
        hideBackdrop
        PaperProps={{
          sx: {
            width: "100vw",
            borderRadius: 0,
            backgroundColor: "#27242e",
          },
        }}
      >
        <Box sx={{ px: 2 }}>
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={{ flex: 1, marginRight: "auto" }}>
              <IconButton size="medium" onClick={onClose}>
                <img
                  src="/images/cross-icon.svg"
                  style={{ height: 24, width: 24 }}
                />
              </IconButton>
            </Box>

            <Box component="span" sx={{ flex: 1 }}>
              <Link
                component={RouterLink}
                to={formatLocaleUrl("/")}
                sx={{ textDecoration: "none", display: "flex" }}
              >
                <img src="/images/iqostravel-logo.svg" />
              </Link>
            </Box>

            {!reducedNav && (
              <Box component="nav" sx={{ marginLeft: "auto", flex: 1 }}>
                <Stack
                  direction="row"
                  component={"ul"}
                  sx={{ listStyle: "none" }}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <li>
                    <LanguageSelect />
                  </li>
                </Stack>
              </Box>
            )}
          </Stack>
        </Box>

        <Box sx={{ mt: 4 }} />
        {!reducedNav && (
          <Box sx={{ px: 5 }}>
            <Stack direction={"column"} alignItems={"start"} spacing={2}>
              {country?.Name && (
                <Typography variant="body1">
                  <span>
                    <strong>Location</strong>
                  </span>{" "}
                  - {country?.Name}
                </Typography>
              )}
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => openCountrySelect()}
              >
                {country
                  ? translations?.changeLocation
                  : translations?.selectLocation}
              </Button>
            </Stack>
          </Box>
        )}

        <Box sx={{ mt: 4 }} />

        {!reducedNav && navigation}
      </Drawer>
    </Styles>
  );
};

export default Sidebar;
