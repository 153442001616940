import {
	Autocomplete,
	Box,
	Breadcrumbs,
	Button,
	Card,
	CardContent,
	Container,
	Grid,
	IconButton,
	Link,
	Stack,
	Typography,
	useMediaQuery
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'
import { MyLocation } from '@mui/icons-material';
import { default as MapComponent } from '../Map';
import { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useParams } from 'react-router'
import styled from '@emotion/styled'
import mapboxgl from 'mapbox-gl'

import theme from '../../theme'
import Layout from '../Layout'

import { IqosTravelContext } from '../../App'

import { formatLocaleUrl } from '../../utils/common'
import config from '../../config.json'
import axios from 'axios'

import { useCountry, useLanguages } from '../../helpers/contexts';
import { useDataLayer } from '../../utils/dataLayer'

import LoadingBackdrop from '../LoadingBackdrop'

mapboxgl.accessToken = `pk.eyJ1IjoibWVybGlucGFuZWwiLCJhIjoiY2ttZjQ0a3B1MDZlcTJ3czAyZzFzOTQ5dyJ9.K5x2Kiljxmn8SPwrqrH50A`;

const StoreLocator = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);
	const dataLayer = useDataLayer()
	const mapBarRef = useRef<HTMLDivElement>(null);

	const searchParams = new URL(window.location.href).searchParams;
	const desktop = useMediaQuery(theme.breakpoints.up('md'));

	const { language } = useLanguages();
	const { country } = useCountry();

	const [localTranslations, setLocalTranslations] = useState<any>({})
	const [location, setLocation] = useState<[number, number] | null>([6.1431577, 46.2043907])
	const [userLocation, setUserLocation] = useState<[number, number] | null>(null)
	const [locationName, setLocationName] = useState<string | null>(null)

	const [bounce, setBounce] = useState<any | null>(null);
	const [searchResults, setSearchResults] = useState<any>(null);
	const [chosenResult, setChosenResult] = useState<any>(
		null
	);
	const directedLocations = searchParams.getAll('location');
	const queryZoom = searchParams.get('zoom');
	const referrerParam = searchParams.get('referrer');
	const jumpToMap = referrerParam === 'oasis';

	const { translations } = useContext(IqosTravelContext);
	const { lang: langParam } = useParams();

	// Bounding box logic
	const [mapRef, setMapRef] = useState<google.maps.Map>();
	const [bounds, setBounds] = useState<{
		minLat: number;
		maxLat: number;
		minLon: number;
		maxLon: number;
	}>();

	const [mapBoundsLocations, setMapBoundsLocations] = useState<any>({});

	useEffect(() => {
		document.title = 'Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel';

		(async () => {
			try {
				const res = await axios.post(config.cmsUrl + '/store-locator', {
					locale: langParam,
				})
				const data = res.data
				setLocalTranslations(data);
			} catch (e) {
				console.error(e);
			}
			setLoading(false);
		})()
	}, [langParam]);

	useEffect(() => {
		const source = axios.CancelToken.source();

		(async () => {
			try {
				if (!bounds) return;
				const { minLat, minLon, maxLat, maxLon } = bounds
				const res = await axios.post(config.cmsUrl + '/external-location', {
					minLat, minLon, maxLat, maxLon,
					cancelToken: source.token
				})

				const data = res.data
				setMapBoundsLocations(data)
			} catch (e) {
				setMapBoundsLocations({
					locations: [],
					clusters: []
				})
				console.error(e);
			}
		})()

		return () => {
			source.cancel();
		}
	}, [bounds]);

	useEffect(() => setNewBounds(), [mapRef]);


	const setNewBounds = () => {
		if (!mapRef) return;

		const bounds = mapRef?.getBounds();
		const topRight = bounds?.getNorthEast();
		const bottomLeft = bounds?.getSouthWest();

		if (!bounds || !topRight || !bottomLeft) return;

		// Our system uses min/maxLon and minMaxLat
		const minLat = bottomLeft.lat();
		const minLon = bottomLeft.lng();
		const maxLat = topRight.lat();
		const maxLon = topRight.lng();

		setBounds({ minLat, minLon, maxLat, maxLon });
	};

	// Allow external sites to define a location and a name.
	const prefillLat = searchParams.get('prefill-lat');
	const prefillLon = searchParams.get('prefill-lon');
	const prefillName = searchParams.get('prefill-name');

	const northBound = searchParams.get('north');
	const southBound = searchParams.get('south');
	const eastBound = searchParams.get('east');
	const westBound = searchParams.get('west');

	useEffect(() => {
		(async () => {
			if (prefillLat && prefillLon) {
				const prefillLocation = await getLocationFromCoordinates([
					parseFloat(prefillLon),
					parseFloat(prefillLat)
				]);

				if (prefillName) setLocationName(prefillName);

				// if the location has a bounding box
				if (!!(prefillLocation as any)?.bbox?.length) {
					mapRef?.fitBounds(
						{
							north: (prefillLocation as any).bbox[3],
							south: (prefillLocation as any).bbox[1],
							east: (prefillLocation as any).bbox[2],
							west: (prefillLocation as any).bbox[0]
						},
						1
					);
				} else {
					// if there is no bb fall back to the standard viewport setting
					setViewport({
						...viewport,
						zoom: !!queryZoom ? parseInt(queryZoom) : 10,
						longitude: (prefillLocation as any)?.center[0],
						latitude: (prefillLocation as any)?.center[1]
					});
					mapRef?.setZoom?.(17);
					return setLocation((prefillLocation as any)?.center);
				}

				setLocationName(prefillName);
			} else {
				if (northBound && southBound && eastBound && westBound) {
					mapRef?.fitBounds(
						{
							north: parseFloat(northBound),
							south: parseFloat(southBound),
							east: parseFloat(eastBound),
							west: parseFloat(westBound)
						},
						1
					);
				}
			}
		})();
	}, [mapRef, prefillName, prefillLon, prefillLat, northBound, eastBound, southBound, westBound]);

	useEffect(() => {
		if ((prefillLon && prefillLat) || (northBound && eastBound && southBound && westBound))
			return;
		if (!country?.Name) return;

		(async () => {
			// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
			const location = await getLocation(country?.Name!);
			const coordinates = location?.features?.[0]?.geometry?.coordinates;
			if (!!(location?.features?.[0] as any)?.bbox?.length) {
				setLocation(coordinates as [number, number]);
				mapRef?.fitBounds(
					{
						north: (location?.features?.[0] as any).bbox[3],
						south: (location?.features?.[0] as any).bbox[1],
						east: (location?.features?.[0] as any).bbox[2],
						west: (location?.features?.[0] as any).bbox[0]
					},
					1
				);
			} else {
				setLocation(coordinates as [number, number]);
				setViewport({
					zoom: 15,
					longitude: coordinates[0],
					latitude: coordinates[1]
				});
				// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
				setChosenResult(location?.features?.[0]);
				// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
				setLocationName(country?.Name!);
			}
		})();
	}, [country?.Name, mapRef]);

	useEffect(() => {
		if ((prefillLon && prefillLat) || (northBound && eastBound && southBound && westBound))
			return;

		(async () => {
			if (directedLocations.length) {
				let location = null;
				let _locationName = null;

				for (const directedLocation of directedLocations) {
					_locationName = directedLocation;
					// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
					location = await getLocation((directedLocation! as string) ?? country?.Name!);
					if (location) break;
				}

				// if (!location) location = await getLocation(country?.Name!);
				if (!location) return;
				const coordinates = location?.features?.[0]?.geometry?.coordinates;

				if (!!(location?.features?.[0] as any).bbox?.length) {
					mapRef?.fitBounds(
						{
							north: (location?.features?.[0] as any).bbox[3],
							south: (location?.features?.[0] as any).bbox[1],
							east: (location?.features?.[0] as any).bbox[2],
							west: (location?.features?.[0] as any).bbox[0]
						},
						1
					);
				} else {
					setLocation(coordinates as [number, number]);
					setViewport({
						longitude: coordinates[0],
						latitude: coordinates[1],
						zoom: 15
					});
					// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
					setChosenResult(location?.features?.[0]);
					// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
					setLocationName((_locationName! as string) ?? country?.Name!);
				}
			} else if (!country?.Name) {
				if (
					navigator?.geolocation &&
					!directedLocations.length &&
					!country &&
					!prefillLat &&
					!prefillLon
				) {
					navigator?.geolocation?.getCurrentPosition?.(
						position => {
							setViewport({
								longitude: position.coords.longitude,
								latitude: position.coords.latitude,
								zoom: 15
							});
							setLocation([position.coords.longitude, position.coords.latitude]);
							setUserLocation([position.coords.longitude, position.coords.latitude]);
						},
						err => console.error(err)
					);
				}
			}
		})();
	}, []);

	const closestLocations = useMemo(() => {
		if (!location) return [];

		return mapBoundsLocations?.locations
			?.map((feature: any) => ({
				...feature,
				distanceSqrd:
					Math.pow(location[0] - stringToFloat(feature.longitude), 2) +
					Math.pow(location[1] - stringToFloat(feature.latitude), 2)
			}))
			.sort((a: any, b: any) => a.distanceSqrd - b.distanceSqrd)
			.filter((v: any, i: number) => i < 10);
	}, [location, mapBoundsLocations]);

	const [viewport, setViewport] = useState<{
		latitude: number;
		longitude: number;
		zoom?: number;
	}>({
		latitude: location?.[1] ?? 0.0,
		longitude: location?.[0] ?? 0.0,
		zoom: queryZoom ? parseInt(queryZoom) : 3
	});

	const geoJson = {
		type: 'FeatureCollection',
		features:
			mapBoundsLocations?.locations
				?.map((location: any) => {
					return {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [
								stringToFloat(location?.latitude) || 0,
								stringToFloat(location?.longitude) || 0
							]
						},
						properties: location
					};
				})
				?.filter((l: any) =>
					l?.geometry?.coordinates?.every((coord: any) => typeof coord === 'number')
				) ?? []
	};

	const searchData = async (query: string) => {
		if (bounce) clearTimeout(bounce);

		setBounce(
			setTimeout(async () => {
				try {
					const results: { data: typeof geoJson } = await axios.get(
						`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?autocomplete=true&access_token=${mapboxgl.accessToken}&types=poi,place,region,country&language=${language}`
					);

					setSearchResults(results.data);
				} catch (e) {
					console.error(e);
				}
			}, 1000)
		);
	};

	const getLocation = async (query: string) => {
		try {
			const results: { data: typeof geoJson } = await axios.get(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?autocomplete=true&access_token=${mapboxgl.accessToken}`
			);
			return results?.data;
		} catch (e) {
			console.error(e);
		}
	};

	const getLocationFromCoordinates = async (location: [number, number]) => {
		try {
			const results: { data: typeof geoJson } = await axios.get(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${location[0]},${location[1]}.json?autocomplete=true&access_token=${mapboxgl.accessToken}`
			);

			return results?.data?.features?.[0];
		} catch (e) {
			console.error(e);
		}
	};

	const getLocationName = async (location: [number, number]) => {
		const results: { data: typeof geoJson } = await axios.get(
			`https://api.mapbox.com/geocoding/v5/mapbox.places/${location[0]},${location[1]}.json?autocomplete=true&access_token=${mapboxgl.accessToken}`
		);

		return (
			(
				results?.data?.features?.filter(
					(feature: any) => (feature as any).place_type[0] === 'country'
				)?.[0] as any
			)?.place_name ?? 'Nothing found'
		);
	};

	useEffect(() => {
		(async () => {
			if (prefillName) return;
			setLocationName(await getLocationName(location!));
		})();
	}, [location]);

	const getOTToday = (object: any) => {
		let day = new Date().getDay();
		if (new Date().getHours() >= 23) {
			day = (day + 1) % 7;
		}

		if (!object.hours) return '';
		return object.hours[day - 1];
	};
	const navigation = translations?.navigation?.data?.attributes || {}
	const storeLocator = localTranslations?.storeLocator?.data?.attributes || {}

	return (
		<Layout translations={translations} footerData={translations.footer?.data?.attributes}>
			<Styles>
				<Stack>
					{desktop ? null : (
						<img
							className="image-shop"
							src="/images/locator-hero.png"
							width="100%"
							alt="Buy IQOS Abroad"
						/>
					)}
					<Grid container justifyContent="center" className="hero">
						<Container>
							<Breadcrumbs separator=">" color="secondary">
								<Link
									component={RouterLink}
									to={formatLocaleUrl(`/${navigation?.shop?.toLowerCase()}`)}
								>
									{navigation?.shop}
								</Link>
								<Link component={RouterLink} to="#" aria-current="page">
									{navigation?.storeLocator}
								</Link>
							</Breadcrumbs>
							<Grid container direction="row" wrap="nowrap" sx={{ mt: 5 }}>
								<Grid
									item
									container
									direction="column"
									sx={{
										textAlign: desktop ? 'left' : 'center',
										paddingRight: '2rem'
									}}
									sm={desktop ? 6 : 12}
								>
									<Typography gutterBottom variant="h1">
										{storeLocator?.NearbyShops}
									</Typography>
									<Typography gutterBottom variant="body1">
										{storeLocator?.UseLocator}
									</Typography>
									<Typography gutterBottom variant="body1">
										{storeLocator?.PreOrderProducts}
									</Typography>
									<Box mt={8} />
								</Grid>
								<Grid item sm={desktop ? 6 : 12}>
									{desktop && (
										<img
											className="image-shop"
											src="/images/locator-hero.png"
											width="100%"
											alt="Buy IQOS Abroad"
										/>
									)}
								</Grid>
							</Grid>
						</Container>
					</Grid>
					<Container>
						<Stack
							direction="column"
							textAlign="center"
							className="search-container"
							id="#map"
						>
							<Typography>
								{prefillName}
							</Typography>
							<Typography gutterBottom>{storeLocator?.UseSearch}</Typography>

							<Stack
								className="inputs"
								direction={desktop ? 'row' : 'column'}
								spacing={2}
								justifyContent="center"
								alignItems="center"
							>
								<Autocomplete
									freeSolo={false}
									sx={{
										outline: 'none',
										border: 'none',
										borderRadius: '50px',
										backgroundColor: 'transparent !important'
									}}
									disablePortal
									autoComplete
									autoHighlight
									isOptionEqualToValue={(a, b) => (a as any).id === (b as any).id}
									options={searchResults?.features ?? []}
									placeholder={storeLocator?.EnterLocation}
									loading={!!directedLocations.length && !chosenResult}
									value={chosenResult}
									filterOptions={x => x}
									onInputChange={(event, inputValue) => {
										let result = searchData(inputValue);
										return result;
									}}
									onChange={(_, value) => {
										setChosenResult(value!);
										dataLayer({
											event: 'storeLocatorSearch',
											searchLocation: (value as any)?.place_name
										});
									}}
									getOptionLabel={option => {
										return (option as any)?.place_name;
									}}
									renderInput={params => (
										<div
											ref={params.InputProps.ref}
											style={{ backgroundColor: 'transparent !important' }}
										>
											<input
												className="location-input"
												placeholder={storeLocator?.EnterLocation}
												type="text"
												{...params.inputProps}
											/>
										</div>
									)}
									className="gtm-locator-dropdown"
								/>
								<Button
									variant="outlined"
									color="secondary"
									disabled={!chosenResult}
									sx={{ height: 50 }}
									className="gtm-locator-go"
									onClick={() => {
										if (!!(chosenResult as any)?.bbox?.length) {
											mapRef?.fitBounds(
												{
													north: (chosenResult as any).bbox[3],
													south: (chosenResult as any).bbox[1],
													east: (chosenResult as any).bbox[2],
													west: (chosenResult as any).bbox[0]
												},
												1
											);
										} else {
											setViewport({
												...viewport,

												longitude: (chosenResult as any)?.center[0],
												latitude: (chosenResult as any)?.center[1]
											});
											mapRef?.setZoom?.(15);
											return setLocation((chosenResult as any)?.center);
										}
									}}
								>
									{storeLocator?.Go}
								</Button>
								{userLocation && (
									<IconButton
										sx={{ height: 50, width: 50 }}
										onClick={() => {
											setViewport({
												zoom: 15,
												longitude: userLocation[0],
												latitude: userLocation[1]
											});
											setLocation([userLocation[0], userLocation[1]]);
										}}
									>
										<MyLocation />
									</IconButton>
								)}
							</Stack>
						</Stack>
					</Container>
					<div className="map-wrapper" ref={mapBarRef}>
						<MapComponent
							onMapLoaded={() =>
								jumpToMap &&
								mapBarRef?.current?.scrollIntoView?.({
									behavior: 'smooth',
									block: 'start'
								})
							}
							geoData={mapBoundsLocations?.locations}
							// @ts-ignore
							viewport={viewport}
							user={
								userLocation
									? {
										latitude: userLocation[1],
										longitude: userLocation[0]
									}
									: undefined
							}
							onViewportChange={newViewport => {
								if (
									newViewport.latitude !== viewport.latitude ||
									newViewport.longitude !== viewport.longitude ||
									newViewport.zoom !== viewport.zoom
								) {
									setViewport(newViewport);
									setNewBounds();
								}
							}}
							data={storeLocator}
							clusters={mapBoundsLocations?.clusters}
							GoogleMapsProps={{
								onLoad: map => {
									setMapRef(map);
								}
							}}
						/>
					</div>

					{!!closestLocations?.length && (
						<Grid container>
							<Container id={'nearbyLocations'}>
								<Typography className="nearby-location-title" gutterBottom variant="h2">
									{storeLocator?.NearbyLocations}
								</Typography>
								<Stack direction="row" spacing={2} className="location-cards">
									{closestLocations?.map((closeLocation: any, i: number) => {
										return (
											<Card key={i} className="location-card">
												<CardContent>
													<Stack spacing={2} className="location-card-text">
														<Typography
															gutterBottom
															variant="h6"
															fontSize={'20px'}
															component="div"
														>
															{closeLocation.name?.toLowerCase()}
														</Typography>
														<Typography
															variant="body1"
															fontSize={'18px'}
															component="div"
														>
															{closeLocation.line1?.toLowerCase()}
														</Typography>
														<Typography
															gutterBottom
															variant="body1"
															fontSize={'14px'}
															component="div"
														>
															{closeLocation.city?.toLowerCase()},{' '}
															{closeLocation.postalCode}
														</Typography>
														<Typography
															gutterBottom
															variant="h6"
															fontSize={'18px'}
															component="div"
															pb={2}
														>
															{getOTToday(closeLocation)}
														</Typography>
													</Stack>
													<div className="location-card-button-container">
														<Button
															variant="outlined"
															component={'a'}
															href={`https://www.google.com/maps/dir/${closeLocation?.line1 || ''}+${closeLocation?.city || ''}+${closeLocation?.postalCode || ''}/@${closeLocation?.latitude || ''},${closeLocation?.longitude || ''},15z`}
															target={'_blank'}
														>
															{(storeLocator as any)?.popup?.GoogleMaps}
														</Button>
													</div>
												</CardContent>
											</Card>
										);
									})}
								</Stack>
								<Box mt={8} />
							</Container>
						</Grid>
					)}
				</Stack>
			</Styles>
			<LoadingBackdrop open={loading} />
		</Layout>
	);
};

const Styles = styled.div`
	.hero {
		padding: 4vw;
		background-color: ${theme.palette.primary.main};
		color: #35303e;

		b {
			color: ${theme.palette.primary.main};
		}
	}

	.nearby-location-title {
		margin: ${({ theme }) => theme.spacing(3, 0)};
	}

	.text-area {
		padding: 8vw;
	}

	.map-wrapper {
		// TODO: add wrapper to "Loading..."
		//background: red;
	}

	.image-shop {
		right: 0;
		transition: 0.2s ease;

		${theme.breakpoints.down('md')} {
			position: relative;
			max-width: 100%;
			padding-top: 2rem;
			background-color: ${({ theme }) => theme.palette.primary.main};
		}

		${theme.breakpoints.up(1800)} {
			max-width: 40vw;
		}
	}

	.MuiAutocomplete-input {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		min-width: 300px;
		width: 100%;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.MuiAutocomplete-popper {
		background: #fff;
		color: #35303e;
		font-size: 1rem;
		font-weight: 800;
		border-radius: 50px;

		ul li {
			text-align: left;
		}
	}

	.destination {
		background: ${theme.palette.primary.main};
		padding: 2vw;
	}

	.discover-range {
		background: ${theme.palette.primary.main};
		padding: 2vw;
	}

	.destination-wrapper {
		position: relative;
	}

	.close-icon {
		position: absolute;
		top: -25px;
		right: 0;
		cursor: pointer;
	}

	.left {
		* {
			max-width: 80%;
		}

		background-color: #ebaf34;
		padding-right: ${theme.spacing(3)};
		padding-top: ${theme.spacing(3)};
		padding-bottom: ${theme.spacing(3)};
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.inputs {
		padding-top: ${theme.spacing(3)};
	}

	.right {
		* {
			max-width: 80%;
		}

		color: ${theme.palette.common.black};
		background-color: white;
		padding-left: ${theme.spacing(3)};
		padding-top: ${theme.spacing(3)};
		padding-bottom: ${theme.spacing(3)};
		display: flex;
		flex-direction: column;
	}

	.inputs {
		flex-grow: 1;
	}

	.location-input {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem 2rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
		background: transparent;
	}

	.location-select {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		margin: 1rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.location-option {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.location-cards {
		width: 100%;
		overflow: auto;
	}

	.location-card {
		min-width: min(397px, 100%);
		background-color: #ebaf34;
		padding: ${theme.spacing(4)} 0;
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: capitalize;

		.location-card-text {
			color: black;
			text-align: center;
		}

		.location-card-button-container {
			width: 100%;
			display: flex;
			justify-content: center;

			.location-card-button {
				margin-top: ${theme.spacing(2)};
			}
		}
	}

	.ddf-selection-text {
		max-width: 50%;
	}

	.iata-button {
		align-self: start;
		margin-left: 20%;
		margin-top: auto;
	}

	.right-button {
		align-self: start;
	}

	.button:hover {
		border: 3px solid #fff;
	}

	.shop-items {
		background: #fff;
		padding: 4vw 0;
	}

	.mapboxgl-canvas {
		height: 50vh;
	}

	.mapboxgl-control-container {
		display: none;
	}

	.search-container {
		padding: ${theme.spacing(6)} 0;
	}

	.gm-style-iw-d {
		overflow: visible !important;
	}

	.gm-style .gm-style-iw-t::after {
		background: linear-gradient(
				45deg,
				#35303e 47%,
				rgba(255, 255, 255, 0) 51%,
				rgba(255, 255, 255, 0) 100%
			)
			#35303e;
	}

	.gm-ui-hover-effect {
		display: none !important;
	}

	.close-button {
		font-weight: bold;
		margin-left: auto;
		margin-right: 7%;
		cursor: pointer;
	}

	.infobox-link {
		text-decoration: none !important;
		margin-bottom: 15px;
	}
`;

function stringToFloat(number: string | number): number {
	return typeof number === 'string'
		? (number.startsWith('-') ? -1 : 1) * parseFloat(number) ?? 0
		: number;
}


export default StoreLocator;
