import React from 'react';
import styled from '@emotion/styled';
import { Box, Grid, Stack } from '@mui/material';
import theme from '../../../theme';

const VeevSectionWithChildren: React.FC<{
	imagePlacement: String;
	imagePath: String;
	sectionStyles?: any;
	bodyContent: any;
	imageType: String;
}> = ({ imagePlacement, imagePath, sectionStyles, bodyContent, imageType }) => {
	return (
		<Styled>
			<Box sx={{ py: 10 }} className="one-section" style={sectionStyles}>
				<Grid
					container
					direction={{
						xs: 'column',
						md: imagePlacement === 'left' ? 'row' : 'row-reverse'
					}}
					className="one-section-grid"
				>
					<Grid
						item
						xs={12}
						md={6}
						justifyContent={{ xs: 'center', md: 'flex-start' }}
						className={
							imageType === 'fill'
								? 'one-section-left-fill'
								: 'one-section-left-fixed'
						}
						style={{ backgroundImage: `url(${imagePath})` }}
					></Grid>
					<Grid
						item
						xs={12}
						md={6}
						justifyContent={{ xs: 'center', md: 'flex-start' }}
						className="one-section-right"
					>
						<Grid
							item
							className="pre-order-hero-text"
							sx={{ textAlign: 'left' }}
							md={12}
							xs={12}
						>
							<Stack direction="column" spacing={6}>
								{bodyContent}
							</Stack>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Styled>
	);
};

const Styled = styled.div`
	.one-section {
		.one-section-grid {
			/* height: 850px; */
			.one-section-left-fill {
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				flex: 2;
			}

			.one-section-left-fixed {
				background-repeat: no-repeat;
				background-size: auto;
				background-position: top;
				flex: 2;
			}

			.one-section-right {
				background-color: #f5f4f0;
				padding-top: 50px;
				padding-bottom: 50px;
				padding-left: 100px;
				padding-right: 100px;
				flex: 3;

				${theme.breakpoints.only('xs')} {
					padding-left: 20px;
					padding-right: 20px;
				}
			}
		}
	}

	.no-top-padding {
		padding-top: 0px !important;
	}

	.section-title {
		color: #27242e;
		font-size: 2rem;
	}

	.section-text {
		color: #27242e;
		font-size: 1rem;
		padding-right: 50px;
		padding-left: 50px;
	}

	.section-disclaimer-text {
		color: #27242e;
		font-size: 0.8rem;
		padding-top: 50px;
		padding-bottom: 20px;
	}

	.section-grid-item {
		height: auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-bottom: 50px;
	}
`;

export default VeevSectionWithChildren;
