import styled from '@emotion/styled';
import theme from '../../theme';

const Styles = styled.div`

    .card-exit {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 24px;
        cursor: pointer;
        z-index: 200000;
        ${theme.breakpoints.up('md')} {
            opacity: 1;
            width: 24px;
        }
            /* color: ${theme.palette.common.black}; */
        /* Use filter as it's an img tag */
        /* https://codepen.io/sosuke/pen/Pjoqqp thanks */
        filter: brightness(0) saturate(100%) invert(19%) sepia(13%) saturate(762%)
        hue-rotate(220deg) brightness(88%) contrast(92%);
    }
	
	.shop-popup-dialog-content {
		background-color: ${theme.palette.primary.main};

		padding: ${theme.spacing(3, 0)};

		${theme.breakpoints.up('md')} {
			padding: 0;
		}

		.shop-popup-dialog-content-mobile {
			.shop-popup-image {
				object-fit: cover;
				max-width: 100%;
			}

			.shop-popup-availability-cards-container {
				padding: ${theme.spacing(3, 0, 2, 7)};
				overflow: visible;
				display: flex;

				.flexed-container {
					display: inline-flex;
					flex-grow: 1;
					overflow: auto;

					gap: ${theme.spacing(2)};

					.availability-card {
						flex-basis: 80%;
						min-width: 80%;
						background: transparent;
						border: 2px solid ${theme.palette.background.default};
						display: flex;
						flex-direction: column;

						&:last-of-type {
							margin-right: ${theme.spacing(7)};
						}

						.availability-card-content {
							flex-grow: 1;

							.availability-check-box {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 30px;
								height: 30px;
								border-radius: 50%;
								background-color: ${theme.palette.background.default};
								border: 1px solid ${theme.palette.common.white};
							}
						}
					}
				}
			}
			${theme.breakpoints?.up('md')} {
				display: none;
			}
		}

		.shop-popup-dialog-content-left {
			display: none;
			background-color: ${theme.palette.common.white};

			${theme.breakpoints.up('md')} {
				display: block;
			}

			.content-wrapper-left {
				padding: ${theme.spacing(6, 6, 12)};
				color: ${theme.palette.common.black};
				height: 100%;

				.logo {
					max-height: 50px;
					width: auto;
				}
			}
		}

		.shop-popup-dialog-content-right {
			display: none;
			background-color: ${theme.palette.primary.main};

			${theme.breakpoints.up('md')} {
				display: block;
			}

			.shop-popup-content-wrapper {
				height: 100%;
				display: flex;
				flex-direction: column;
				padding-bottom: ${theme.spacing(12)};

				.shop-popup-image-right {
					object-fit: cover;
					max-width: 100%;
				}

				.shop-popup-content-stack {
					flex-grow: 1;
					padding: ${theme.spacing(5, 5, 0)};

					.availability-card {
						flex: 1;
						background: transparent;
						border: 2px solid ${theme.palette.background.default};
						display: flex;
						flex-direction: column;

						.availability-card-content {
							flex-grow: 1;

							.availability-check-box {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 30px;
								height: 30px;
								border-radius: 50%;
								background-color: ${theme.palette.background.default};
								border: 1px solid ${theme.palette.common.white};
							}
						}
					}
				}
			}
		}
	}
`;

export default Styles;
