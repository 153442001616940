import React from 'react';
import { Box, Button } from '@mui/material';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import { formatLocaleUrl } from '../../utils/common';

const BottomNavigation: React.FC<{
	title?: string;
	action?: React.ReactNode;
	image?: string;
	translations: any;
	useVeev?: boolean;
}> = ({ title, action = null, image, translations, useVeev }) => {
	const navigation = translations?.data?.attributes

	return (
		<Styles>
			<Box className="bottom-menu">
				<Button
					variant="outlined"
					color="secondary"
					component={RouterLink}
					to={formatLocaleUrl(
						useVeev ? '/veev-one' : `/${navigation?.shop?.toLowerCase()}`
					)}
				>
					{useVeev ? 'VEEV One' : navigation?.shop}
				</Button>
				<Button
					variant="outlined"
					color="secondary"
					component={RouterLink}
					to={formatLocaleUrl(
						useVeev ? '/veev-now' : `/${navigation?.travel?.toLowerCase()}`
					)}
				>
					{useVeev ? 'VEEV Now' : navigation?.travel}
				</Button>
			</Box>
		</Styles>
	);
};

const Styles = styled.div(
	({ theme }) => `
  .bottom-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    background: #27242e;
    position: fixed;
    width: 100vw;
    padding: ${theme.spacing(2)};
    bottom: 0;
    left: 0;
    z-index: 100;

    ${theme.breakpoints.up('md')} {
      display: none;
    }

    .MuiButton-root {
      border: 2px solid #fff;
      border-radius: 50px;
      text-transform: none;
      padding: ${theme.spacing(1, 0)};
      margin: ${theme.spacing(0, 1)};
      max-width: 150px;
      width: 100%;
    }
  }
`
);

export default BottomNavigation;
