import TagManager from 'react-gtm-module';

export const useDataLayer = () => {
	return (data: any) => {
		TagManager.dataLayer({
			dataLayer: {
				...data
			},
			dataLayerName: 'proxyDataLayer'
		});
	};
};
