import React from 'react';
import styled from '@emotion/styled';
import {
	AccordionProps,
	Accordion as MuiAccordion,
	AccordionSummaryProps,
	AccordionSummary as MuiAccordionSummary,
	AccordionDetails as MuiAccordionDetails,
	Typography
} from '@mui/material';
import { useNavigate, useParams } from 'react-router'
import { formatLocaleUrl } from '../../utils/common';

type OwnProps = { icon?: string };
type CustomAccordionSummaryProps = OwnProps & AccordionSummaryProps;

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	borderBottom: `2px solid ${theme.palette.background.default}`,
	'&:before': {
		display: 'none'
	}
}));

const AccordionSummary = styled((props: CustomAccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<img src={props.icon} alt={'Common questions icon'} style={{ height: 40 }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	padding: theme.spacing(2, 5),
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper': {
		width: 40
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(0deg)'
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(5)
	}
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(8, 5),
	backgroundColor: theme.palette.background.default
}));

const Accordions: React.FC<{
	data: any;
	children: React.ReactNode;
}> = ({ data, children }) => {
	const { tab } = useParams()
	let tabParam = tab || data[0]?.slug
	
	const navigate = useNavigate();
	return (
		<div>
			{data?.map((item: any) => {
				const url = formatLocaleUrl(`/how-to-travel/${item?.slug}`);

				return (
					<Accordion
						key={item?.slug}
						expanded={tabParam === item?.slug}
						TransitionProps={{ unmountOnExit: true }}
					>
						<AccordionSummary
							onClick={() => {
								navigate(url);
							}}
							icon={item?.icon?.data?.attributes?.url}
						>
							<Typography>{item?.title ?? 'Tab'}</Typography>
						</AccordionSummary>
						<AccordionDetails>{tabParam === item?.slug && children}</AccordionDetails>
					</Accordion>
				);
			})}
		</div>
	);
};

export default Accordions;
