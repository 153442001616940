import React from 'react';
import { Box, Container, Link, Stack, Typography } from '@mui/material';
import Styles from './styles';
import DynamicLink from '../DynamicLink';
import { formatLocaleUrl } from '../../utils/common';
import { Link as RouterLink } from 'react-router-dom';

const Footer: React.FC<{ data: any; reducedNav?: boolean }> = ({ data, reducedNav }) => {
  return (
    <Styles>
      <Stack justifyContent='center' alignItems='center' className='footer'>
        <div className='footer-white'>
          <Container maxWidth='lg'>
            <Typography
              py={0}
              style={{
                direction: 'ltr',
              }}
            >
              20 歳未満の者の喫煙は禁じられています。加熱式たばこの煙（蒸気）は、発がん性物質が含まれる
              など、あなたや周りの人の健康への悪影響が否定でき ません。
              <br />
              <br />
              아이코스는 19세 이상 성인 흡연자를 위한 제품으로 무해하지 않습니다.
              <br />
              <br />
              THESE PRODUCTS ARE NOT RISK-FREE AND ARE ADDICTIVE. FOR ADULT USE ONLY.
              {!!data?.subText && (
                <>
                  <br />
                  <br />
                  {data?.subText}
                </>
              )}
              <br />
            </Typography>
          </Container>
        </div>
        <div className='footer-bottom'>
          <Container maxWidth='lg'>
            <Box component='nav' sx={{ marginLeft: 'auto', flex: 1 }}>
              <Stack
                spacing={10}
                direction='row'
                component='ul'
                alignItems='center'
                className='footer-bottom-copyright'
              >
                <li>© {data?.copyright}</li>
              </Stack>
              <Stack spacing={10} direction='row' component='ul' alignItems='center' className='footer-bottom-links'>
                {data?.link?.map(({ buttonText, buttonLink, buttonTag }: any, i: number) => (
                  <li
                    key={`${buttonText}-${i}`}
                    style={{
                      height: buttonLink === 'openCookiePolicy' ? '24px' : 'auto',
                      width: buttonLink === 'openCookiePolicy' ? '200px' : 'auto',
                    }}
                  >
                    <Link
                      id={buttonLink === 'openCookiePolicy' ? 'ot-sdk-btn' : ''}
                      component={buttonLink === 'openCookiePolicy' ? 'span' : DynamicLink}
                      {...(buttonLink === 'openCookiePolicy'
                        ? {}
                        : {
                            to: formatLocaleUrl(buttonLink ?? ''),
                          })}
                      className={
                        i === 0
                          ? 'gtm-footer-important'
                          : i === 1
                          ? 'gtm-footer-privacyPolicy'
                          : 'gtm-footer-cookiesPolicy ot-sdk-show-settings cookie-text'
                      }
                    >
                      {buttonText ?? ''}
                    </Link>
                  </li>
                ))}
              </Stack>
            </Box>
          </Container>
        </div>
        <div className='footer-bottom'>
          <ul style={{ listStyle: 'none', display: 'flex', gap: '20px' }}>
            <li>
              <Link
                component={RouterLink}
                to={formatLocaleUrl(`/`)}
                style={{ color: '#fff', textDecorationColor: '#fff' }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                component={RouterLink}
                to={formatLocaleUrl(`shop`)}
                style={{ color: '#fff', textDecorationColor: '#fff' }}
              >
                Shop
              </Link>
            </li>
            <li>
              <Link
                component={RouterLink}
                to={formatLocaleUrl(`pre-order`)}
                style={{ color: '#fff', textDecorationColor: '#fff' }}
              >
                Pre-Order
              </Link>
            </li>
            <li>
              <Link
                component={RouterLink}
                to={formatLocaleUrl(`store-locator`)}
                style={{ color: '#fff', textDecorationColor: '#fff' }}
              >
                Store Locator
              </Link>
            </li>
            <li>
              <Link
                component={RouterLink}
                to={formatLocaleUrl(`buying-abroad`)}
                style={{ color: '#fff', textDecorationColor: '#fff' }}
              >
                Buying Abroad
              </Link>
            </li>
            <li>
              <Link
                component={RouterLink}
                to={formatLocaleUrl(`travel`)}
                style={{ color: '#fff', textDecorationColor: '#fff' }}
              >
                Travel
              </Link>
            </li>
            <li>
              <Link
                component={RouterLink}
                to={formatLocaleUrl(`how-to-travel/`)}
                style={{ color: '#fff', textDecorationColor: '#fff' }}
              >
                How to Travel
              </Link>
            </li>
            <li>
              <Link
                component={RouterLink}
                to={formatLocaleUrl(`customer-care`)}
                style={{ color: '#fff', textDecorationColor: '#fff' }}
              >
                Customer Care
              </Link>
            </li>
            <li>
              <Link
                component={RouterLink}
                to={formatLocaleUrl(`important-information`)}
                style={{ color: '#fff', textDecorationColor: '#fff' }}
              >
                Important Information
              </Link>
            </li>
          </ul>
        </div>
      </Stack>
    </Styles>
  );
};

export default Footer;
