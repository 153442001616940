import React from 'react';
import {
	Link,
	Typography,
} from '@mui/material';

import Styles from './styles';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

const ImageTile: React.FC<{
	title?: string;
	subTitle?: string;
	link?: any;
	externalLink?: any;
	linkText?: string;
	image?: string;
	className?: string;
}> = ({ title, subTitle, link, externalLink, linkText, image, className }) => {
	return (
		<Styles>
			{!!externalLink ? (
				<Link href={externalLink} target={'_blank'} className={className}>
					<div className="tile">
						<div className="tile-background">
							<img src={image} alt={`${title} image`} />
						</div>
						<div className="tile-foreground">
							<Typography component="h3">{title}</Typography>
							<Typography className="tile-foreground-description">
								{subTitle}
							</Typography>
							<Typography>
								{linkText}&nbsp;
								<FontAwesomeIcon icon={faArrowRightLong} />
							</Typography>
						</div>
					</div>
				</Link>
			) : (
				<Link component={RouterLink} to={link} className={className}>
					<div className="tile">
						<div className="tile-background">
							<img src={image} alt={`${title} image`} />
						</div>
						<div className="tile-foreground">
							<Typography component="h3">{title}</Typography>
							<Typography className="tile-foreground-description">
								{subTitle}
							</Typography>
							<Typography>
								{linkText}&nbsp;
								<FontAwesomeIcon icon={faArrowRightLong} />
							</Typography>
						</div>
					</div>
				</Link>
			)}
		</Styles>
	);
};

export default ImageTile;
