import {
  Breadcrumbs,
	Container,
	Grid,
	Link,
	Stack,
	Typography,
	useMediaQuery 
} from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useEffect, useContext, useState, useRef } from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'

import theme from '../../theme'
import Layout from '../Layout'

import DestinationSelectorBar from '../DestinationSelectorBar';
import { IqosTravelContext } from '../../App'

import { formatCountryCode, formatLocaleUrl } from '../../utils/common'
import config from '../../config.json'
import axios from 'axios'

import { useCountry, useLanguages } from '../../helpers/contexts'
import { useDataLayer } from "../../utils/dataLayer"
import LoadingBackdrop from '../LoadingBackdrop'

const PreOrder = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true)
  const [airports, setAirports] = useState<any>([])

  const url = new URL(window.location.href)
	const countryCode = url.searchParams.get('country')
	const airportIata = url.searchParams.get('airport')
  const selectedAirport = airports?.find((airport: any) => airport?.iata?.toLowerCase() === airportIata?.toLowerCase());

  const dataLayer = useDataLayer()
  const { language } = useLanguages()
  const { country, countries } = useCountry()
  const { translations } = useContext(IqosTravelContext)
  const { lang: langParam } = useParams()

  const preOrderBar = useRef<HTMLDivElement>(null)

  const [localTranslations, setLocalTranslations] = useState<any>({})

  const [chosenOperator, setChosenOperator] = useState<string | null>(null);
  const [chosenAirport, setChosenAirport] = useState<string | null>(null);

  useEffect(() => {
    document.title = 'Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel';

    (async () => {
      try {
        const res = await axios.post(config.cmsUrl + '/pre-order', {
          locale: langParam,
        })
        const data = res.data
        setLocalTranslations(data);

        const res2 = await axios.post(config.cmsUrl + '/getAirports')
        const data2 = res2.data
        setAirports(data2.airports)
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    })()
  }, [langParam]);

  useEffect(() => {
    if (selectedAirport?.iata) {
      setChosenAirport(selectedAirport?.iata)
    }
  }, [selectedAirport])

  const desktop = useMediaQuery(theme.breakpoints.up('md'))
  const navigation = translations?.navigation?.data?.attributes || {}
  const page = localTranslations?.preOrder?.data?.attributes || {}

  const heroUrl = page?.hero?.image?.data?.attributes?.url


  const airportCountries = [
		...new Set(
			airports
				?.filter((airport: any) => !!airport.links?.length)
				?.map((airport: any) => airport.market)
				?.map((airportMarket: any) =>
					countries?.find(
						(c: any) => formatCountryCode(c?.CountryCode) === formatCountryCode(airportMarket)
					)
				)
				?.filter(Boolean)
		)
	]

  return (
    <Layout translations={translations} footerData={translations.footer?.data?.attributes}>
      <Styles>
        <Stack id="pre-order" ref={preOrderBar}>
          <Grid container justifyContent="center" className="pre-order-hero">
            <Container>
              <Breadcrumbs separator=">">
                <Link
                  component={RouterLink}
                  to={formatLocaleUrl(`/shop`)}
                >
                  {navigation?.shop}
                </Link>
                <Link component={RouterLink} to={'#'} aria-current="page">
                  {navigation?.preOrder}
                </Link>
              </Breadcrumbs>

              <Grid container sx={{ mt: 8 }}>
                <Grid
                  item
                  className="pre-order-hero-text"
                  sx={{ textAlign: desktop ? 'left' : 'center' }}
                  md={6}
                  xs={12}
                >
                  <Stack direction="column" spacing={6}>
                    <Typography
                      component="div"
                      variant="h1"
                      dangerouslySetInnerHTML={{ __html: page?.hero?.title }}
                      className="pre-order-hero-title"
                    />

                    <Typography gutterBottom variant="body1" mb={6}>
                      {page?.hero?.subtitle}
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: page?.hero?.description
                        }}
                      ></span>
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item md={6} xs={12}>
                  { heroUrl &&
                  <img
                    className="image-preorder"
                    src={heroUrl}
                    width="100%"
                    alt={`Pre order in ${country?.Name}`}
                  />
                  }
                </Grid>
              </Grid>
            </Container>
          </Grid>
          {
            !!airportCountries?.length ? (
              <DestinationSelectorBar
                countries={airportCountries}
                airports={airports.filter(
                  (airport: any) =>
                    formatCountryCode(airport.market) ===
                    formatCountryCode(country?.CountryCode) &&
                    !!airport.links?.filter(
                      ({ languageCodes }: any) =>
                        !languageCodes?.length ||
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        languageCodes.includes(language?.code!)
                    ).length
                )}
                translations={{
                  title: page?.autocomplete?.title,
                  button: page?.autocomplete?.buttonText,
                  country: page?.autocomplete?.countryPlaceholder,
                  airport: page?.autocomplete?.airportPlaceholder
                }}
                selectedAirport={chosenAirport}
                selectedCountry={countryCode || country?.CountryCode}
                onAirportChange={value => setChosenAirport(value ?? null)}
                onOperatorChange={newOperator => setChosenOperator(newOperator)}
                link={
                  (!!country?.CountryCode &&
                    !!chosenAirport &&
                    !!chosenOperator &&
                    (airports
                      .find((airport: any) => airport?.iata === chosenAirport)
                      ?.links?.find(
                        ({ operator, languageCodes }: any) =>
                          operator === chosenOperator &&
                          (!languageCodes?.length ||
                            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                            languageCodes.includes(language?.code!))
                      )?.link ||
                      airports
                        .find((airport: any) => airport?.iata === chosenAirport)
                        ?.links?.find(
                          ({ operator }: any) => operator === chosenOperator
                        )?.link)) ||
                  '?=notFound'
                }
                onSubmit={() => {
                  dataLayer({
                    event: 'preOrder',
                    airportName: airports?.find(
                      (airport: any) => airport?.iata === chosenAirport
                    )?.name,
                    airportCountry: airports?.find(
                      (airport: any) => airport?.iata === chosenAirport
                    )?.market,
                    storeName: chosenOperator
                  });
                }}
                extraFields={[
                  {
                    selectedValue: chosenOperator,
                    onChange: value => setChosenOperator(value),
                    placeholder: 'Operator',
                    options:
                      airports
                        .find((airport: any) => airport?.iata === chosenAirport)
                        ?.links?.filter(
                          ({ languageCodes }: any) =>
                            !languageCodes?.length ||
                            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                            languageCodes.includes(language?.code!)
                        )
                        ?.map(({ operator }: any) => operator) ?? []
                  }
                ]}
              />
            ) : (
              <Stack
                textAlign="center"
                alignItems="center"
                className="pre-order-unavailable"
              >
                <Container maxWidth="md">
                  <Typography variant="h3" gutterBottom>
                    {page?.preOrderMissingTitle?.replace(
                      /{country}/g,
                      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                      country?.Name!
                    )}
                  </Typography>
                  <span
                    dangerouslySetInnerHTML={{ __html: page?.preOrderMissingBody }}
                  ></span>
                </Container>
              </Stack>
            )
          }
        </Stack>
      </Styles>
      <LoadingBackdrop open={loading} />
    </Layout>
  );
};
const Styles = styled.div`
	.pre-order-hero {
		padding: 4vw;
		position: relative;

		${theme.breakpoints.up('md')} {
			height: auto;
			//min-height: 800px;
		}
		.pre-order-hero-title {
			p {
				margin: 0;
			}
		}
	}

	.pre-order-unavailable {
		background-color: ${theme.palette.primary.main};
		padding: ${theme.spacing(6, 0)};
	}

	.text-area {
		padding: 8vw;
	}

	.image-preorder {
		margin-top: 2rem;

		${theme.breakpoints.up('md')} {
			margin-left: 20px;
		}
	}

	.close-icon {
		position: absolute;
		top: -25px;
		right: 0;
		cursor: pointer;
	}
`;

export default PreOrder;
