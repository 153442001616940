import styled from '@emotion/styled';

const Styles = styled.div`
	
	.navigation {
		background: #27242e;
		font-weight: 300;
		font-size: 1rem;
		min-height: 71px;
		
		.logo {
			display: flex;

			svg {
				padding: 2px;
			}
		}

		nav {
			margin-left: auto;

			ul {
				list-style: none;
				font-size: 1rem;

				li:not(:last-of-type) {
					a {
						text-decoration: none;
						color: ${({ theme }) => theme.palette.text.primary};
						transition: ${({ theme }) => theme.transitions.create(['color'])};
						font-size: 1rem;

						button {
							font-size: 1rem;
						}

						&:hover {
							color: ${({ theme }) => theme.palette.secondary.main};
						}
					}
				}
			}
		}

		.hamburger-container {
			width: 20px;

			.hamburger {
				height: 3px;
				width: 100%;
				background-color: ${({ theme }) => theme.palette.common.white};

				&:first-of-type {
					margin-bottom: ${({ theme }) => theme.spacing(1)};
				}
			}
		}
	}
`;

export default Styles;
