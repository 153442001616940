/* eslint-disable react-hooks/rules-of-hooks */
import { useLanguages } from '../helpers/contexts';

export const formatLocaleUrl = (path: string, domain?: string) => {
	const { language } = useLanguages();
	// Is it an entire URL?
	try {
		const parsed = new URL(path);
		// If is a valid domain, this won't throw an error
		return parsed.href;
	} catch (e) {
		// Isn't an entire URL
		const formatted = `${!!language ? `/${language}` : ''}${
			path.startsWith('/') ? path : '/' + path
		}`;
		return !!domain ? new URL(formatted, domain).href : formatted;
	}
};

export const formatCountryCode = (countryCode: string | null | undefined) =>
	countryCode?.replace(/\s+/g, '-')?.toLowerCase();
