import styled from '@emotion/styled';

const Styles = styled.div`
	.tile {
		position: relative;
		min-height: 340px;
		overflow: hidden;
		opacity: 1;
		transition: opacity 0.2s ease-in-out;
		aspect-ratio: 1;

		&:hover {
			opacity: 0.9;
		}

		.tile-background {
			width: 100%;
			position: absolute;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: linear-gradient(
					to bottom,
					rgba(0, 0, 0, 0) 0%,
					rgba(0, 0, 0, 0.4) 50%,
					rgba(0, 0, 0, 0.7) 100%
				);
			}
		}

		.tile-foreground {
			position: absolute;
			color: white;
			bottom: 25px;
			padding: 0 20px;

			h3 {
				font-size: 1.3rem;
				font-weight: bold;
			}

			.tile-foreground-description {
				font-size: 1rem;
				padding: 10px 0;
			}
		}
	}
`;

export default Styles;
