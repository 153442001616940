import {
  Container,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'

import Layout from '../Layout'

import { IqosTravelContext } from '../../App'

import config from '../../config.json'
import axios from 'axios'
import LoadingBackdrop from '../LoadingBackdrop'

const ImportantInformation = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const { translations } = useContext(IqosTravelContext)
  const { lang: langParam } = useParams()

  const [localTranslations, setLocalTranslations] = useState<any>({})

  useEffect(() => {
    document.title = 'Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel';

    (async () => {
      try {
        const res = await axios.post(config.cmsUrl + '/important-information', {
          locale: langParam,
        })
        const data = res.data
        setLocalTranslations(data)
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    })()
  }, [langParam]);

  const page = localTranslations?.importantInformation?.data?.attributes || {}

  return (
    <Layout translations={translations} footerData={translations.footer?.data?.attributes}>
      <Styles>
        <Container className="wrapper">
          <Stack direction="column" spacing={4}>
            {page?.section?.map(({ title, description }: any, i: number) => (
              <Stack direction="column" spacing={1} key={i}>
                <Typography component="h1" variant="h2">
                  {title}
                </Typography>
                <Typography
                  component="div"
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Stack>
            ))}
          </Stack>
        </Container>
      </Styles>
      <LoadingBackdrop open={loading} />
    </Layout>
  );
};

const Styles = styled.div`
	.wrapper {
		padding: 2rem;
		max-width: 800px;

		h1,
		h2 {
			margin-bottom: 10px;
			margin-top: 30px;
			font-weight: bold;
		}

		p {
			font-size: 1rem;
			margin: 10px 0;
		}
	}
`;

export default ImportantInformation;
