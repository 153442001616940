import {
  Box,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useLanguages } from '../../helpers/contexts'
import styled from '@emotion/styled'

import theme from '../../theme'
import Layout from '../Layout'
import VeevImportantInformation from '../Veev/VeevImportantInformation'
import VeevSection from '../Veev/VeevSection'
import VeevFooter from '../Veev/VeevFooter'
import VeevCarousel from '../Veev/VeevCarousel'
import VeevSectionWithChildren from '../Veev/VeevSectionWithChildren'

import HeaderBodyText from '../HeaderBodyText'
import OtherResources from '../OtherResources'

const VeevOne = (): JSX.Element => {
  const [showImportant, setShowImportant] = useState(false);

  const { language } = useLanguages();
  console.info(language)
  const isArabic = language === 'ar';


  return (
    <Layout translations={{}} footerData={{}}>
      <Styles>
        {showImportant ? (
          <VeevImportantInformation
            setShowImportant={setShowImportant}
            code={language}
          />
        ) : (
          <>
            <VeevSection
              code={language}
              imagePlacement="left"
              imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-landing-one-left.jpg"
              sectionStyles={{ paddingTop: 0 }}
              imageType="fill"
              titleText={
                isArabic
                  ? 'يُعد VEEV ONE نظام فيب متقدم يعمل باستخدام الكبسولات'
                  : 'VEEV ONE is our <br /> pod-vape system'
              }
              bodyText={
                isArabic
                  ? [
                    'يوفر السائل الإلكتروني ما يصل إلى 2000* سحبة بكل عبوة تحتوي على كبسولتين',
                    'بطارية متينة توفّر شحنًا كاملًا في أقل من 45 دقيقة',
                    'هيكل ألومنيوم مصنوع من قطعة واحدة لضمان منتج متين ومستدام'
                  ]
                  : [
                    'E-liquid delivers up to 2000* puffs per pack of 2 pods.',
                    'Powerful battery makes a full charge in less than 45 minutes**.',
                    'Moulded from a single block of aluminium to make it durable and robust.'
                  ]
              }
              buttonText={isArabic ? 'اشترِ VEEV ONE' : 'Buy VEEV ONE'}
              disclaimer={
                isArabic
                  ? '* على اعتبار أن السحبة مدّتها ثانية واحدة. تختلف حسب طريقة الاستخدام الفردية'
                  : '* Based on a puff duration of 1 second. May vary depending on individual usage patterns.'
              }
              disclaimerLower={
                isArabic
                  ? '**أرجع جهازك المستعمل إلينا لإعادة التدوير، ونحن نهتم بالباقي'
                  : '**Return your used device to us for recycling, we will take care of it.'
              }
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: '5vw',
                backgroundColor: '#F6F4F0'
              }}
            >
              <VeevCarousel
                code={language}
                headerText={
                  isArabic
                    ? 'اكتشف المزيد من نكهات VEEV ONE'
                    : 'More VEEV ONE flavour pods to explore'
                }
                device="one"
                slides={[
                  'Deep Yellow',
                  'Coral Pink',
                  'Red',
                  'Blue Mint',
                  'Classic Tobacco'
                ]}
              />
            </Box>
            <VeevSectionWithChildren
              imagePlacement="right"
              imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-one-middle.jpg"
              imageType="fill"
              bodyContent={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    py: '5vw',
                    backgroundColor: '#F6F4F0'
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    className="body-section-title"
                    textAlign={isArabic ? 'right' : 'left'}
                    style={{ width: '100%' }}
                  >
                    {isArabic
                      ? `
										كيف تستخدم جهاز ™VEEV ONE الخاص بك 
لأول مرة
										`
                      : 'How to use your VEEV ONE™ for the first time.'}
                  </Typography>

                  <div className="body-text-container">
                    <HeaderBodyText
                      code={language}
                      headerText={isArabic ? '1. فتح العبوة' : '1. Unbox'}
                      bodyText={
                        isArabic
                          ? 'استعد لتجربة جديدة مع جهاز ™VEEV ONE وتأكد من تجهيز كلّ ما يلزم قبل أن تبدأ: الجهاز وكابل الشحن المعتمد من الشركة المصنّعة بالإضافة إلى محوّل طاقة معتمد وجهازك المفضل ™VEEV ONE القابل للاستخدام'
                          : 'Get ready for a new experience with VEEV ONE™ and make sure you have everything you need before starting: the device, a manufacturer-approved Charging Cable with a certified AC Power Adaptor and your favorite VEEV ONE™ consumable.'
                      }
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      code={language}
                      headerText={isArabic ? '2. التحضير' : '2. Prepare'}
                      bodyText={
                        isArabic
                          ? 'أدخل كبسولة VEEV ONE في جهازك. عطرية، مع لمسة مبرّدة، مقرمشة ودافئة: بدّل بين النكهات واستعد للاستمتاع بتجربتك المقبلة'
                          : 'Insert the VEEV ONE pod into your device. Aromatic, with cooling, cooling and crisp and warm: swap your flavours and get ready to enjoy your next experience.'
                      }
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      code={language}
                      headerText={isArabic ? '3. التشغيل' : '3. Switch ON'}
                      bodyText={
                        isArabic
                          ? 'تأكد من شحن جهازك ™VEEV ONE بما يكفي لبدء التجربة.  قبل الاستخدام الأول، شغّل الجهاز بالضغط باستمرار على زر التشغيل / الإيقاف لمدة ثلاث ثوانٍ، ثم افلته. ستضيء أربعة مصابيح بيضاء ببطء للدلالة على أن الجهاز جاهز للاستخدامات التالية'
                          : 'Make sure your VEEV ONE™ is charged enough to start the experience. Before first use, turn on your device by pressing and holding the ON/OFF button for three seconds, then release. Four white lights will turn on slowly to indicate that the device is ready for next uses.'
                      }
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      code={language}
                      headerText={isArabic ? '4. الفيب' : '4. Vape'}
                      bodyText={
                        isArabic
                          ? 'سهل الاستخدام: ما عليك سوى البدء بالسحب! اسحب بسلاسة وبمعدل ضغط طبيعي لتستمتع بتجربة فيب مثالية باستخدام جهاز ™VEEV ONE يمكنك دائمًا تغيير كبسولة ™VEEV ONE أثناء تشغيل الجهاز. '
                          : 'It’s simple: just start puffing! Take smooth puffs with normal drawing pressure to ensure the ideal vaping experience with VEEV ONE™. You can always change the VEEV ONE™ pod while your device is turned on.'
                      }
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      code={language}
                      headerText={isArabic ? '5. إيقاف التشغيل' : '5. Turn OFF'}
                      bodyText={
                        isArabic
                          ? 'في حال عدم استخدام ™VEEV ONE لمدة ثلاث ثوانٍ، يدخل الجهاز تلقائيًا في وضع السكون للحفاظ على البطارية	يُمكنك إيقاف تشغيل الجهاز يدويًا بالضغط على زر تشغيل/إيقاف لمدة ثلاث ثوان حتى تتلاشى جميع أضواء الحالة'
                          : 'If VEEV ONE™ is unused for three seconds, it will automatically go in sleep mode to save battery. You can manually turn off the device by pressing the ON/OFF button for three seconds until all the status lights fade out.'
                      }
                    />
                  </div>
                </Box>
              }
            />
            <Box sx={{ py: 10 }} className="hero">
              <Typography
                component="div"
                variant="h1"
                dangerouslySetInnerHTML={{
                  __html: isArabic
                    ? 'رعاية يمكنك الاعتماد عليها'
                    : 'Care you can count on'
                }}
                className="hero-title"
              />
            </Box>
            <Box
              sx={{
                paddingTop: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                component="div"
                variant="h1"
                dangerouslySetInnerHTML={{
                  __html: isArabic ? 'النصائح واللمحات' : 'Tips and Hints'
                }}
                className="hero-title-no-wrap"
              />
            </Box>
            <VeevSectionWithChildren
              imagePlacement="left"
              imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-one-middle-orange.jpg"
              imageType="fill"
              bodyContent={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    py: '5vw',
                    backgroundColor: '#F6F4F0',
                    width: '100%'
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    className="body-section-title"
                  >
                    {isArabic ? 'التحقق من البطارية' : 'Battery Check.'}
                  </Typography>
                  <HeaderBodyText
                    headerText={
                      isArabic
                        ? `
											ستعرف دائمًا متى يحين موعد الشحن مع جهاز ™VEEV ONE.  
تحقّق من معدل الشحن بكل سهولة من خلال  
الضغط قليلًا على زر تشغيل/إيقاف: تضيء أربعة مصابيح باللون الأبيض 
للدلالة على حالة البطارية.

											`
                        : 'With VEEV ONE™, you always know when it is time to charge. You can easily check the charge level of the device by briefly pressing the ON/OFF button: four white lights will turn on to indicate the status of the battery.'
                    }
                    bodyText=""
                    code={language}
                  />
                  <div className="body-text-container">
                    <HeaderBodyText
                      component={
                        <Typography
                          variant="body1"
                          component="p"
                          className="body-section-subtitle"
                          textAlign={isArabic ? 'right' : 'left'}
                        >
                          {isArabic ? (
                            <>
                              1. مصباح واحد LED مضاء باللون الأبيض = شحن
                              بنسبة 25% -1%
                            </>
                          ) : (
                            <>
                              1. one white LED on =
                              <span
                                style={{
                                  fontWeight: 'normal !important'
                                }}
                              >
                                {' '}
                                25% - 1% charged
                              </span>
                            </>
                          )}
                        </Typography>
                      }
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      component={
                        <Typography
                          variant="body1"
                          component="p"
                          className="body-section-subtitle"
                          textAlign={isArabic ? 'right' : 'left'}
                        >
                          {isArabic ? (
                            <>
                              2. مصباحان LED مضاءان باللون الأبيض = شحن
                              بنسبة 25% - 50%
                            </>
                          ) : (
                            <>
                              2. two white LEDs on =
                              <span
                                style={{
                                  fontWeight: 'normal !important'
                                }}
                              >
                                {' '}
                                25% - 50% charged
                              </span>
                            </>
                          )}
                        </Typography>
                      }
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      component={
                        <Typography
                          variant="body1"
                          component="p"
                          className="body-section-subtitle"
                          textAlign={isArabic ? 'right' : 'left'}
                        >
                          {isArabic ? (
                            <>
                              3. ثلاثة مصابيح LED مضاءة باللون الأبيض =
                              شحن بنسبة 50% - 75%
                            </>
                          ) : (
                            <>
                              3. three white LEDs on =
                              <span
                                style={{
                                  fontWeight: 'normal !important'
                                }}
                              >
                                {' '}
                                50% - 75% charged
                              </span>
                            </>
                          )}
                        </Typography>
                      }
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      component={
                        <Typography
                          variant="body1"
                          component="p"
                          className="body-section-subtitle"
                          textAlign={isArabic ? 'right' : 'left'}
                        >
                          {isArabic ? (
                            <>
                              4. أربعة مصابيح LED مضاءة باللون الأبيض =
                              شحن أقل من 75%
                            </>
                          ) : (
                            <>
                              4. four white LEDs on =
                              <span
                                style={{
                                  fontWeight: 'normal !important'
                                }}
                              >
                                {' >'}
                                75% charged
                              </span>
                            </>
                          )}
                        </Typography>
                      }
                    />
                  </div>
                </Box>
              }
            />
            {/* Airflow holes */}
            <VeevSectionWithChildren
              imagePlacement="right"
              imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-one-middle-pink.jpg"
              imageType="fill"
              /* sectionStyles={{ paddingTop: 0 }} */
              bodyContent={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    py: '5vw',
                    backgroundColor: '#F6F4F0',
                    width: '100%'
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    className="body-section-title"
                  >
                    {isArabic
                      ? 'لا تسد فتحات تدفق الهوا'
                      : "Don't cover Airflow holes."}
                  </Typography>
                  <HeaderBodyText
                    headerText=""
                    bodyText={
                      isArabic
                        ? 'لضمان توصيل البخار بالشكل المناسب، يشترط تدفّق الهواء دائمًا عبر الجهاز:  عند السحب، تأكد من عدم سدّ فتحات تدفّق الهواء الموجودة على جانب الجهاز، بأصابعك أو بأي عائق آخر'
                        : 'To ensure a proper aerosol delivery, air needs to be always able to flow through your device: when puffing, make sure that the two airflow holes, placed on the side of your device, are not occluded by your fingers or any other blockage.'
                    }
                    /* spacer */
                    component={<div className="spacer" />}
                    code={language}
                  />
                </Box>
              }
            />
            {/* How to change */}
            <VeevSectionWithChildren
              imagePlacement="left"
              imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-one-middle-blue.jpg"
              imageType="fill"
              /* sectionStyles={{ paddingTop: 0 }} */
              bodyContent={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    py: '5vw',
                    backgroundColor: '#F6F4F0',
                    width: '100%'
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    className="body-section-title"
                  >
                    {isArabic
                      ? 'كيف تبدّل كبسولة ™VEEV ONE '
                      : 'How to change your VEEV ONE pod.'}
                  </Typography>
                  <HeaderBodyText
                    headerText=""
                    bodyText={
                      isArabic
                        ? 'تبديل كبسولة ™VEEV ONE سهل للغاية، فيمكن إجراء عملية التبديل أثناء تشغيل الجهاز، في أي وقت وأثناء التنقل.   عند إدخال كبسولة جديدة في جهاز ™VEEV ONE الجديد، ستضيء مصابيح الحالة ببطء للإشارة إلى أن الجهاز جاهز للاستخدام. لا بل أكثر من ذلك، لا ينتقل الطعم من كبسولة إلى أخرى'
                        : 'You can easily change the VEEV ONE™ pod while your VEEV ONE™ device is turned on, at any time and on the go. When a new VEEV ONE™ pod is inserted, status lights will turn on slowly to indicate that the device is ready for use. What’s more, there is no flavor aftertaste from one pod to another.'
                    }
                    /* spacer */
                    component={<div className="spacer" />}
                    code={language}
                  />
                </Box>
              }
            />
            {/* FAQS */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                component="div"
                variant="h1"
                dangerouslySetInnerHTML={{
                  __html: isArabic ? 'الأسئلة الشائعة' : 'Frequently Asked Questions'
                }}
                className="hero-title-no-wrap"
              />
            </Box>
            {/* Leeking */}
            <VeevSectionWithChildren
              imagePlacement="right"
              imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-one-middle-pink.jpg"
              imageType="fill"
              /* sectionStyles={{ paddingTop: 0 }} */
              bodyContent={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    py: '5vw',
                    backgroundColor: '#F6F4F0',
                    width: '100%'
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    className="body-section-title"
                  >
                    {isArabic
                      ? 'ماذا أفعل في حال إذا كان جهاز VEEV ONE™ الخاص بي يُسرب السائل الإلكتروني؟ '
                      : 'What to do if my VEEV ONE™ is leaking e-liquid?'}
                  </Typography>
                  <HeaderBodyText
                    code={language}
                    headerText=""
                    bodyText={
                      isArabic
                        ? `
									قد تظهر قطرات من السائل على مبسم الجهاز أثناء الاستخدام العادي. امسح هذه القطرات بقطعة قماش جافة ونظيفة قبل استخدام الجهاز. لا تستخدم هذا المنتج في حال تلف الجهاز، أو التلاعب فيه، أو كسره،   
أو تسرب السائل منه. في حالة ملامسة السائل الإلكتروني المتسرب من VEEV ONE، اغسل الموضع المتأثر على الفور بالماء والصابون
									`
                        : 'Droplets of liquid that are visible on the mouthpiece of the device may occur during normal use. These droplets must be wiped off with a clean dry cloth before you use the device. Do not use this product if the device appears damaged, tampered with, broken, or leaking. In case of contact with leaking e-liquid from VEEV ONE, wash the affected area immediately with soap and water.'
                    }
                    /* spacer */
                    component={<div className="spacer" />}
                  />
                </Box>
              }
            />
            {/* LED Light blinks */}
            <VeevSectionWithChildren
              imagePlacement="left"
              imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-one-tall-blue.jpg"
              imageType="fill"
              /* sectionStyles={{ paddingTop: 0 }} */
              bodyContent={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    py: '5vw',
                    backgroundColor: '#F6F4F0'
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    className="body-section-title"
                  >
                    {isArabic
                      ? `
									ماذا أفعل في حال إذا كان جهاز VEEV ONE™ 
									الخاص بي يُسرب السائل الإلكتروني؟
									`
                      : `
										What to do if my VEEV ONE™ 
is leaking e-liquid?
										`}
                  </Typography>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText={
                        isArabic
                          ? `
										ربما يُحذر الجهاز الخاص بك من وجود عطل 
عندما تومض الأضواء
										`
                          : 'When lights are blinking, your device might be warning of an error.'
                      }
                      bodyText=""
                      code={language}
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText={
                        isArabic
                          ? `
										1. وميض الضوء الأحمر
										`
                          : '1. Blinking Red Light'
                      }
                      bodyText={
                        isArabic
                          ? `
										يدل ذلك على عدم إدخال الكبسولة بالشكل الصحيح. يُرجى، إعادة إدخال الكبسولة بالشكل الصحيح أو استخدام أخرى جديدة. في حال استمرار وميض الضوء، أعد ضبط الجهاز
										`
                          : 'This indicates that your pod might not be correctly inserted. Please, re-insert correctly your pod or try using a new one.If thelights is still blinking, perform a reset.'
                      }
                      code={language}
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText={
                        isArabic
                          ? `
										2. وميض الضوء الأبيض
										`
                          : '2. Blinking white lights'
                      }
                      bodyText={
                        isArabic
                          ? `
										من المحتمل أن يكون الجهاز قد تعرّض لدرجة حرارة خارجة عن نطاق التشغيل. في هذه الحالة، اترك الجهاز بضع دقائق ليعود إلى درجة حرارة التشغيل الصحيحة
										`
                          : 'Your device might be exposed to outside of its operating temperature range and you may need to give your device a few minutes to get back to the correct operating temperature.'
                      }
                      code={language}
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText={
                        isArabic
                          ? `
3. وميض الضوء السفلي الأبيض 5 مرات
										`
                          : '3. Bottom light blinking white 5 times'
                      }
                      bodyText={
                        isArabic
                          ? `
										يجب إعادة شحن الجهاز للحصول على أداء الشحن المثالي، استخدم فقط كابل الشحن المعتمد من المصنع الموجود في العبوة ومحوّل طاقة معتمد
										`
                          : 'Your device needs to be charged. For optimal charging performance, only use a manufacturer-approved Charging Cable supplied in the box and a certified AC Power Adaptor.'
                      }
                      code={language}
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText={
                        isArabic
                          ? `
										4. وميض الضوء العلوي الأبيض 5 مرات
										`
                          : '4. Top light blinking white 5 times'
                      }
                      bodyText={
                        isArabic
                          ? `
										مستوى السائل الإلكتروني في الكبسولة منخفض.  يتميز جهاز ™VEEV ONE بوجود 
نافذة شفافة على الكبسولة للتحقق بسهولة من مستوى السائل الإلكتروني:
إذا كان المستوى منخفض جدًا، لن يعمل الجهاز
										`
                          : `There might be low e-liquid level in the pod. VEEV ONE™ features 
										a transparent window on the pod for easy e-liquid level check:
										if the level is too low, your device will not start the experience.`
                      }
                      code={language}
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText={
                        isArabic
                          ? '5. وميض الضوء السفلي الأبيض مرّتين'
                          : '5. Bottom light blinking white two times'
                      }
                      bodyText={
                        isArabic
                          ? `
										قد يعود السبب في ذلك إلى السحب مطوّلًا.  تأكد من عدم السحب لأكثر من 5 ثوانٍ: إذا استمرت السحبة الواحدة أكثر من 5 ثوانٍ، 
سيتوقف الجهاز عن إيصال البخار
										`
                          : `
										This might be caused because of long puffing. Ensure to not puff for more than five seconds: when a puff lasts for more than five seconds, 
								the device will stop the delivery of aerosol.
										`
                      }
                      code={language}
                    />
                  </div>
                </Box>
              }
            />
            {/* No lights */}
            <VeevSectionWithChildren
              imagePlacement="right"
              imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-one-horizontal-orange.jpg"
              imageType="fill"
              /* sectionStyles={{ paddingTop: 0 }} */
              bodyContent={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    py: '5vw',
                    backgroundColor: '#F6F4F0',
                    width: '100%'
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    className="body-section-title"
                  >
                    {isArabic
                      ? 'ماذا تفعل إذا لم تظهر أي أضواء على الجهاز؟ '
                      : 'What to do when there are no lights on the device?'}
                  </Typography>
                  <HeaderBodyText
                    headerText=""
                    bodyText={
                      isArabic
                        ? `
									إذا لم تظهر أي أضواء عند الضغط على الزر، فربما نفدت بطارية جهازك VEEV ONE أو ربما يجب إعادة ضبط الجهاز.  لإعادة ضبط الجهاز، اضغط باستمرار على زر تشغيل/إيقاف لمدة 10 ثوانٍ حتى تزول أضواء الحالة.  تتأكد عملية إعادة ضبط الجهاز، عندما تتلاشى أضواء الحالة مجددًا وتومض أضواء الحالة باللون الأبيض مرتين.  
									`
                        : 'When there are no lights on button press, your VEEV ONE might be discharged or you might need to reset it. To perform a reset, press and hold down the ON/OFF button for 10 seconds until status lights fade out. Reset will be confirmed when status lights fade in again and status lights blink white twice.'
                    }
                    /* spacer */
                    component={<div className="spacer" />}
                    code={language}
                  />
                </Box>
              }
            />
            <OtherResources code={language} />
            <VeevFooter setShowImportant={setShowImportant} code={language} />
          </>
        )}
      </Styles>
    </Layout>
  );
};

const Styles = styled.div`
	.body-section-title {
		color: #27242e;
		margin-bottom: 20px;
		font-size: 2rem;
	}

	.body-section-subtitle {
		color: #27242e;
		font-weight: bold;
		font-size: 1rem !important;
	}

	.body-section-body {
		color: #27242e;
		font-size: 1rem !important;
	}

	.body-text-container {
		width: 100%;
	}

	.hero {
		height: 600px;
		background-image: url('https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/care-you-can-count-on.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}

	.hero {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.hero-title {
		max-width: 400px;
		text-align: center;
		font-size: 2rem;
	}

	.hero-title-no-wrap {
		text-align: center;
		font-size: 2rem;
	}

	.spacer {
		${theme.breakpoints.up('xs')} {
			margin: 150px;
		}
	}
`;

export default VeevOne;
