import React, { useRef, useState } from "react";
import {
  GoogleMap,
  GoogleMapProps as GoogleMapPropsType,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { Button, Stack, Typography, useMediaQuery } from "@mui/material";
import theme from "../../theme";
import { Link as RouterLink } from "react-router-dom";
import { formatLocaleUrl } from "../../utils/common";

const Map: React.FC<{
  geoData?: any[];
  clusters?: {
    centroid: [number, number];
    amount: number;
    topRight: [number, number];
    bottomLeft: [number, number];
  }[];
  user?: { latitude: number; longitude: number };
  viewport: { latitude: number; longitude: number; zoom: number };
  onViewportChange: (newViewport: {
    latitude: number;
    longitude: number;
    zoom: number;
  }) => void;
  GoogleMapsProps: GoogleMapPropsType;
  data: any;
  onMapLoaded?: () => void;
}> = ({
  geoData,
  clusters,
  user,
  viewport,
  onViewportChange,
  data,
  GoogleMapsProps,
  onMapLoaded,
}) => {
  const containerStyle = {
    width: "100vw",
    height: "80vh",
    maxHeight: "700px",
  };

  const mapRef = useRef<google.maps.Map>();
  let desktop = useMediaQuery(theme.breakpoints.up("md"));

  // for the map popup to open in Google Maps directions
  const [popupWindowCenter, setPopupWindowCenter] = useState({
    lat: 46.204,
    lng: 6.146,
  });

  // what popup is currently open
  const [popupLocationData, setPopupLocationData] = useState<any | null>(null);

  // adds button on popup if there is a match, otherwise just display Google map button
  const hasIndoorMap = (city: string | null) => {
    if (!city) return "";
    switch (city.toLowerCase()) {
      case "zurich":
        return "/airport-maps/switzerland-zurich";
      case "chubu":
        return "/airport-maps/japan-chubu";
      case "fukuoka":
        return "/airport-maps/japan-fukuoka";
      case "haneda":
        return "/airport-maps/japan-haneda";
      case "narita":
        return "/airport-maps/japan-narita";
      case "osaka":
      case "osaka-shi":
      case "大阪市":
        return "/airport-maps/japan-osaka";
      case "gimhae":
        return "/airport-maps/korea-gimhae";
      case "gimpo":
        return "/airport-maps/korea-gimpo";
      case "incheon":
        return "/airport-maps/korea-incheon";
      case "beirut":
        return "/airport-maps/lebanon-beirut";
      case "abu dhabi":
        return "/airport-maps/uae-abu-dhabi";
      case "sheremetyevo":
      case "russia":
        return "/airport-maps/russia-sheremetyevo";
      case "geneva":
      case "genève":
        return "/airport-maps/switzerland-geneva";
      case "dubai":
        return "/airport-maps/uae-dubai";
      case "دبي":
        return "/airport-maps/uae-dubai";
      case "cairo":
        return "/airport-maps/egypt-cairo";
      case "jeju":
        return "/airport-maps/korea-jeju";
      default:
        return "";
    }
  };

  const searchInput = [
    popupLocationData?.line1,
    popupLocationData?.city,
    popupLocationData?.postalCode,
  ]
    .filter(Boolean)
    .join("+");

  return (
    <LoadScript googleMapsApiKey={"AIzaSyARt5273gDyPqzMAR1f9CVaPcfB3H0wK4Y"}>
      <GoogleMap
        zoom={viewport?.zoom ?? 10}
        center={{ lng: viewport?.longitude ?? 0, lat: viewport?.latitude ?? 0 }}
        mapContainerStyle={containerStyle}
        {...GoogleMapsProps}
        onLoad={(map) => {
          mapRef.current = map;
          GoogleMapsProps?.onLoad?.(map);
          onMapLoaded?.();
        }}
        onClick={() => setPopupLocationData(null)}
        onDragStart={() => setPopupLocationData(null)}
        onIdle={() => {
          const center = mapRef.current?.getCenter();
          if (!!center) {
            onViewportChange?.({
              longitude: center.lng(),
              latitude: center.lat(),
              zoom: mapRef.current?.getZoom() ?? viewport.zoom,
            });
          }
          GoogleMapsProps?.onIdle?.();
        }}
      >
        {user && (
          <Marker
            icon="/images/user-icon.png"
            position={{
              lat: user?.latitude,
              lng: user?.longitude,
            }}
            onClick={() => {
              onViewportChange?.({
                ...user,
                zoom: 15,
              });
            }}
          />
        )}

        {geoData?.map((item) => {
          return (
            <Marker
              icon={{
                url: "/images/map-pin.svg",
                // @ts-ignore
                scaledSize: { width: 30, height: 45 },
              }}
              position={{
                lat: item.latitude,
                lng: item.longitude,
              }}
              onClick={() => {
                setPopupWindowCenter({
                  lat: item.latitude,
                  lng: item.longitude,
                });
                setPopupLocationData(item);
              }}
            />
          );
        })}
        {clusters?.map((item) => (
          <Marker
            icon="/images/circlesvg.png"
            position={{
              lat: item.centroid[0],
              lng: item.centroid[1],
            }}
            label={item.amount.toString()}
            onClick={() => {
              mapRef.current?.fitBounds(
                {
                  north: item.topRight[0] + 0.01,
                  south: item.bottomLeft[0] - 0.01,
                  east: item.topRight[1] + 0.01,
                  west: item.bottomLeft[1] - 0.01,
                },
                0
              );

              mapRef.current?.setZoom((mapRef.current?.getZoom() ?? 0) + 1);
            }}
          />
        ))}
        {popupLocationData != null && (
          <InfoWindow position={popupWindowCenter}>
            <Stack
              sx={{
                bgcolor: "#35303E",
                m: 0,
                p: 2,
                borderRadius: "20px",
                transform: "scale(1.16) translate(0.5%)",
              }}
              alignItems={"center"}
            >
              <div
                className="close-button gtm-locator-popup-close"
                onClick={() => setPopupLocationData(null)}
              >
                {data?.popup?.Close}
              </div>
              <Typography sx={{ color: "white", pt: 1 }} variant={"body1"}>
                {data?.popup?.BuyAtIqos}
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  py: 2,
                  px: 2,
                  maxWidth: desktop ? "400px" : "280px",
                  textAlign: "center",
                }}
                variant={"body1"}
              >
                {popupLocationData?.name
                  ? popupLocationData?.name
                  : "This location"}
              </Typography>

              <Stack
                direction={desktop ? "row" : "column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Button
                  sx={{ bgcolor: "#00D1D2", color: "black", mx: 3 }}
                  variant="contained"
                  color="info"
                  className="infobox-link gtm-locator-popup-googleMap"
                  component="a"
                  href={`https://www.google.com/maps/dir/${searchInput}/@${popupWindowCenter?.lat},${popupWindowCenter?.lng},15z`}
                  target="_blank"
                >
                  {data?.popup?.GoogleMaps}
                </Button>

                {hasIndoorMap(popupLocationData?.city) && (
                  <Button
                    sx={{
                      bgcolor: "#00D1D2",
                      color: "black",
                      mx: 3,
                      marginLeft: 0,
                      marginRight: desktop ? "40px" : "",
                    }}
                    variant="contained"
                    color="info"
                    className="infobox-link gtm-locator-popup-indoorMap"
                    component={RouterLink}
                    target="_blank"
                    to={formatLocaleUrl(hasIndoorMap(popupLocationData?.city!))}
                  >
                    {data?.popup?.IndoorMaps}
                  </Button>
                )}
              </Stack>
            </Stack>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
