import React, { ForwardedRef } from 'react';
import SimpleBar from 'simplebar-react';
import styled from '@emotion/styled';
import { alpha } from '@mui/material';

// interface Props extends SimpleBar.Props {
// 	innerRef?: ForwardedRef<HTMLElement>;
// 	forceVisible?: boolean | 'y' | 'x';
// 	autoHide?: boolean;
// 	dark?: boolean;
// 	style?: any;
// 	className?: string;
// 	children?: React.ReactNode;
// }

const Simplebar: React.FC<any> = ({
	innerRef,
	forceVisible,
	autoHide,
	children,
	dark,
	style,
	className,
	...props
}) => {
	return (
		<Styles>
			<SimpleBar
				scrollableNodeProps={{ ref: innerRef }}
				forceVisible={forceVisible ?? 'y'}
				autoHide={autoHide ?? false}
				style={{ maxHeight: '100%', ...style }}
				className={`sidebar-scrollbar-container ${className}`}
				{...props}
			>
				{children}
			</SimpleBar>
		</Styles>
	);
};

const Styles = styled.div(
	({ theme }) => `
	/* position: relative; */
	height: 100%;
	padding: ${theme.spacing(0, theme.palette.mode === 'dark' ? 2 : 0, 0, 0)};

	.simplebar-content-wrapper {
		padding-top: ${theme.spacing(3)};
		scroll-behavior: smooth;

		.simplebar-content {
			:first-child {
				position: relative;
			}
		}
	}

	.simplebar-track {
		background-color: ${alpha(
			theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.common.white,
			0.5
		)};
		margin: auto 0;
		border-radius: 7px;
		width: 5px;
		/* margin-bottom: ${theme.spacing(3)};
		margin-top: ${theme.spacing(3)}; */

		.simplebar-scrollbar {
			&::before {
				background-color: ${
					theme.palette.mode === 'dark'
						? theme.palette.grey[600]
						: theme.palette.common.white
				};
				opacity: 1;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
		}
	}

	.sidebar-scrollbar-container {
		width: 100%;
		height: 100%;
		overflow-y: auto;
		min-height: 100%;
	}
`
);

export default React.forwardRef((props, ref: ForwardedRef<HTMLElement>) => (
	<Simplebar innerRef={ref} {...props} />
)) as any;
