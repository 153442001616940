import React from "react";
import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from "../../theme";
import Tabs from "../HowToTravel/Tabs";
import TabContent from "../HowToTravel/TabContent";
import Accordions from "../HowToTravel/Accordions";

export const QuestionIcon: React.FC<{
  children: React.ReactNode;
  availability?: boolean | null;
}> = ({ availability, children }) => {
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        minHeight: { xs: 40, md: 50 },
        minWidth: { xs: 40, md: 50 },
        maxHeight: { xs: 40, md: 50 },
        maxWidth: { xs: 40, md: 50 },
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
      }}
    >
      {children ? (
        children
      ) : (
        <FontAwesomeIcon
          icon={availability ? faCheck : faXmark}
          size={desktop ? "2x" : "lg"}
          color={theme.palette.common.black}
        />
      )}
    </Box>
  );
};

const TravelQuestion: React.FC<{
  translations: any;
  tab?: string;
}> = ({ translations, tab = translations.allTravelInformation[0].slug }) => {
  const allTravelInformation = translations?.allTravelInformation;
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const page = tab
    ? translations.allTravelInformation.find((data: any) => tab === data.slug)
    : translations.allTravelInformation[0];

  const content = page?.content?.map((c: any) =>
    c?.__component === "travel-information.rules" ? (
      <Stack spacing={4}>
        <Typography
          variant="body1"
          sx={{
            color: {
              xs: theme.palette.common.white,
              md: theme.palette.common.black,
            },
            fontWeight: "normal !important",
          }}
        >
          {c?.description}
        </Typography>
        <Stack spacing={4}>
          {c?.items?.map((item: any, i: number) => (
            <Stack direction="row" alignItems="center" spacing={2} key={i}>
              <QuestionIcon availability={item?.allowed}>
                {item?.allowedText}
              </QuestionIcon>
              <Typography
                variant="h6"
                component="p"
                sx={{ color: mobile ? "common.white" : "common.black" }}
              >
                {item?.question ?? ""}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    ) : (
      <Typography
        component="div"
        variant="body1"
        sx={{ color: mobile ? "common.white" : "common.black" }}
        dangerouslySetInnerHTML={{
          __html: c?.body ?? "",
        }}
      />
    )
  );
  if (mobile) {
    return (
      <Accordions data={allTravelInformation}>
        <TabContent title={page?.title ?? ""} qaData={page?.questions ?? []}>
          {content}
        </TabContent>
      </Accordions>
    );
  }

  return (
    <Stack>
      <Container>
        <Box>
          <Tabs data={allTravelInformation} tab={tab} />
        </Box>
      </Container>
      <Box className="tab-panel">
        <Container>
          <div>
            <TabContent
              title={page?.title ?? ""}
              qaData={page?.questions ?? []}
            >
              {content}
            </TabContent>
          </div>
        </Container>
      </Box>
    </Stack>
  );
};

export default TravelQuestion;
