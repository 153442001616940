import { Breadcrumbs, Link, Container, Grid, Stack, Typography, Button } from '@mui/material'
import { Link as RouterLink, Outlet } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'

import theme from '../../theme'
import Layout from '../Layout'

import { IqosTravelContext } from '../../App'

import { formatLocaleUrl } from '../../utils/common'
import config from '../../config.json'
import axios from 'axios'

import { useCountry } from '../../helpers/contexts';

import lookup from 'country-code-lookup'

import TravelQuestion from '../TravelQuestions'
import LoadingBackdrop from '../LoadingBackdrop'

const HowToTravel = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);

	const [localTranslations, setLocalTranslations] = useState<any>(null)
	const { translations } = useContext(IqosTravelContext);
	const { lang: langParam, tab: tabParam } = useParams();

	useEffect(() => {
		document.title = 'Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel';

		(async () => {
			try {
				const res = await axios.post(config.cmsUrl + '/how-to-travel', {
					locale: langParam,
				})
				const data = res.data

				const res2 = await axios.post(config.cmsUrl + '/travel-informations', {
					locale: langParam,
				})
				const data2 = res2.data

				setLocalTranslations({
					...data, ...data2
				});

				if (!tabParam) {
					const firstTab = data2?.allTravelInformation?.[0]
					if (firstTab?.slug) {
						window.history.replaceState({}, '', `${window.location.origin}/${langParam}/how-to-travel/${firstTab.slug}`)
					}
				}

			} catch (e) {
				console.error(e);
			}
			setLoading(false);
		})()
	}, [langParam, tabParam])

	const { country } = useCountry();

	const ISOCode = country?.ISO ?? lookup.byCountry(country?.Name ?? '')?.iso2

	const page = localTranslations?.howToTravel?.data?.attributes || {};
	const navigation = translations?.navigation?.data?.attributes || {};

	return (
		<Layout translations={translations} footerData={translations.footer?.data?.attributes}>
			<Styles>
				<Container sx={{ p: theme.spacing(6, 0, 15), minHeight: { xs: 'auto', md: 600 } }}>
					<Breadcrumbs separator=">">
						<Link
							component={RouterLink}
							to={formatLocaleUrl(`/${navigation?.travel?.toLowerCase()}`)}
						>
							{navigation?.travel}
						</Link>
						<Link component={RouterLink} to={'#'} aria-current="page">
							{navigation?.travelRules}
						</Link>
					</Breadcrumbs>

					<Grid
						container
						sx={{ mt: { lg: 8 } }}
						direction={{ xs: 'column-reverse', lg: 'row' }}
						spacing={{ xs: 2, md: 0 }}
						alignItems={'center'}
					>
						<Grid item xs={12} lg={6}>
							<Stack
								direction="column"
								spacing={6}
								alignItems={{ xs: 'center', lg: 'start' }}
								sx={{
									textAlign: { xs: 'center', lg: 'left' },
									px: { xs: 5, md: 0 }
								}}
							>
								<Typography
									component="div"
									variant="h1"
									dangerouslySetInnerHTML={{ __html: page?.hero?.title }}
									className="how-to-travel-title"
								/>

								<Stack direction="column" spacing={2}>
									<Typography component="h3">{page?.hero?.subtitle}</Typography>
									<Typography variant="body1">{page?.hero?.description}</Typography>
								</Stack>
								<Stack
									direction="column"
									spacing={1}
									sx={{ width: 'max-content' }}
									alignItems="center"
								>
									<Button
										variant="outlined"
										color="secondary"
										sx={{ width: 'max-content' }}
										startIcon={
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="25.5"
												height="25.5"
												viewBox="0 0 25.5 25.5"
											>
												<path
													d="M25.667,25.667H5.833V5.833H15.75V3H5.833A2.833,2.833,0,0,0,3,5.833V25.667A2.833,2.833,0,0,0,5.833,28.5H25.667A2.842,2.842,0,0,0,28.5,25.667V15.75H25.667ZM18.583,3V5.833h5.086L9.743,19.759l2,2L25.667,7.831v5.086H28.5V3Z"
													transform="translate(-3 -3)"
													fill="#fff"
												/>
											</svg>
										}
										className={`hover-button ${page?.hero?.buttonTag} gtm-howToTravel-iataRules`}
										component="a"
										href={
											ISOCode
												? `https://www.iatatravelcentre.com/${ISOCode}-${country?.Name}-customs-currency-airport-tax-regulations-details.htm#Import%20regulations`
												: 'https://www.iatatravelcentre.com/'
										}
										target={'_blank'}
									>
										{page?.hero?.buttonText}
									</Button>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12} lg={6}>
							{page?.hero?.image?.data?.attributes?.url &&
								<img
									className="image-travel"
									src={page?.hero?.image?.data?.attributes?.url}
									width="100%"
									alt="Travel"
								/>
							}
						</Grid>
					</Grid>
				</Container>
				{localTranslations && <TravelQuestion translations={localTranslations} tab={tabParam} />}
				<Outlet />
			</Styles>
			<LoadingBackdrop open={loading} />
		</Layout>
	);
};

const Styles = styled.div`
	.how-to-travel-title {
		p {
			margin: 0;
		}
	}
	.hover-button {
		position: relative;
		overflow: hidden;
		z-index: 0;
		transition: ${theme.transitions.create(['color', 'border-color'], {
	duration: theme.transitions.duration.standard
})};
		&:hover {
			color: ${theme.palette.common.black};
			border-color: ${theme.palette.common.white};
			svg {
				path {
					fill: ${theme.palette.common.black};
				}
			}
		}
		&::after {
			background-color: ${theme.palette.common.white};
			content: '';
			height: 200%;
			left: -121%;
			position: absolute;
			top: -50%;
			transform: skewX(0deg);
			transition: ${theme.transitions.create(['left', 'transform', 'width'], {
	duration: theme.transitions.duration.standard
})};
			width: 0;
			z-index: -1;
		}
		&:hover::after {
			box-shadow: inset 0 0 0 0 ${theme.palette.common.white};
			content: '';
			left: -10%;
			transform: skewX(-20deg);
			width: 120%;
		}
		svg {
			height: 20px;
			width: 20px;
			path {
				transition: ${theme.transitions.create('fill', {
	duration: theme.transitions.duration.standard
})};
				fill: ${theme.palette.common.white};
			}
		}
	}
	.window-title,
	.window-body {
		color: ${({ theme }) => theme.palette.common.black};
	}

	.hero {
		padding: 4vw;
		min-height: 80vh;
		height: 100%;

		${theme.breakpoints.down('lg')} {
			padding: 0;
		}
	}

	.tabs {
		display: flex;
		justify-content: center;
	}

	.tab {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border: 2px solid #fff;
		border-bottom: none;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		padding: 0 ${theme.spacing(4)};
		background: #35303e;

		img {
			height: ${theme.spacing(5)};
		}

		&.Mui-selected {
			img {
				filter: invert(0.75);
			}
		}
	}

	.tab-panel {
		background: ${theme.palette.primary.main};
		padding: ${theme.spacing(12, 0)};
	}

	.panel-left {
		color: ${theme.palette.background.default};
		margin-right: 100px;

		.panel-body {
			font-weight: 400;
			margin-bottom: ${theme.spacing(4)};
		}
	}

	.panel-window {
		background: #fff;
		border-radius: 15px;
		padding: ${theme.spacing(9, 8)};

		.window-title {
			margin-bottom: ${theme.spacing(4)};
		}

		.window-body {
			margin-bottom: ${theme.spacing(2)};
		}
	}

	.panel-question {
		margin-bottom: ${theme.spacing(4)};
	}

	.image-travel {
		position: absolute;
		right: 0;
		top: 205px;
		max-width: 676px;
		width: 100%;
		transition: 0.2s ease;

		${theme.breakpoints.down('lg')} {
			position: relative;
			top: 0;
			max-width: 600px;
		}
	}

	.item-button {
		border: 3px solid ${theme.palette.background.default};
		border-radius: 50px;
		max-width: 200px;
		width: 100%;
		height: 50px;
		text-transform: none;
		color: ${theme.palette.background.default};
	}
`;

export default HowToTravel;
