import { 
	Box,
	Breadcrumbs,
	Button, 
	Card, 
	CardActions, 
	CardContent, 
	CardHeader, 
	CardMedia,
	Container,
	Grid,
	Link,
	Stack,
	Typography,
	useMediaQuery } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useEffect, useContext, useState, useRef } from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'

import theme from '../../theme'
import Layout from '../Layout'

import { IqosTravelContext } from '../../App'

import { formatLocaleUrl } from '../../utils/common'
import config from '../../config.json'
import axios from 'axios'

import DigitalExpert from '../DigitalExpert'

import { useFeedback } from '../../helpers/contexts'
import { useDataLayer } from "../../utils/dataLayer"
import LoadingBackdrop from '../LoadingBackdrop'

const CustomerCare = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);
	const dataLayer = useDataLayer()
  const [localTranslations, setLocalTranslations] = useState<any>({})
  const { translations } = useContext(IqosTravelContext);
  const { lang: langParam } = useParams();
	const {feedbackOpen, setFeedbackOpen, setFeedbackButtonVisible} = useFeedback()

	const [digitalExpertActive, setDigitalExpertActive] = useState(false);

  useEffect(() => {
    document.title = 'Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel';

    (async () => {
      try {
        const res = await axios.post(config.cmsUrl + '/customer-care', {
          locale: langParam,
        })
        const data = res.data
        setLocalTranslations(data);
      } catch (e) {
        console.error(e);
      }
			setLoading(false);
    })()
  }, [langParam]);

	useEffect(() => {
		// if somehow they open feedback while DE is open?
		if (digitalExpertActive && feedbackOpen) {
			setDigitalExpertActive(false);
		}
	}, [feedbackOpen]);


  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const customerCare = localTranslations?.customerCare?.data?.attributes || {};
	const navigation = translations?.navigation?.data?.attributes || {};

  return (
    <Layout translations={translations} footerData={translations.footer?.data?.attributes}>
      <Styles>
        <DigitalExpert
          digitalExpertActive={digitalExpertActive}
          onEnd={() => setDigitalExpertActive(false)}
          onClose={() => {
            setFeedbackButtonVisible?.(true);
            setDigitalExpertActive(false);
          }}
        />
        <Stack>
          {desktop ? null : (
            <img
              className="image-shop"
              src="/images/home-customer.png"
              width="100%"
              alt="Shop IQOS in Switzerland"
            />
          )}
          <Grid container justifyContent="center" className="hero">
            <Container>
              <Grid container direction="row" wrap="nowrap">
                <Grid item container direction="column" sm={desktop ? 6 : 12}>
                  <Breadcrumbs separator=">">
                    <Link
                      component={RouterLink}
                      to={formatLocaleUrl(
                        `/${navigation?.travel?.toLowerCase()}`
                      )}
                    >
                      {navigation?.travel}
                    </Link>
                    <Link component={RouterLink} to="#">
                      {navigation?.customerCare}
                    </Link>
                  </Breadcrumbs>

                  <Typography gutterBottom variant="h2" className="hero-title">
                    {customerCare.SeeUsNextTime}
                  </Typography>
                  <Typography gutterBottom variant="body1" className="hero-subtitle">
                    {customerCare.ByYourSide}
                  </Typography>
                  <Box mt={8} />
                </Grid>
              </Grid>
            </Container>
            {desktop ? (
              <img
                className="image-shop"
                src="/images/home-customer.png"
                width="100%"
                alt="Shop IQOS in Switzerland"
              />
            ) : null}
          </Grid>
          <Box sx={{ backgroundColor: 'common.white', padding: theme.spacing(8, 0) }}>

            <Container>
              <Grid container spacing={4}>
                {customerCare.cards
                  ?.map(
                    (cardData: any, i: number) => (
                      <Grid item xs={12} md={4} key={`${cardData.Title}-${i}`}>
                        <Card classes={{ root: 'travel-card' }} elevation={0}>
                          <CardMedia
                            component="img"
                            image={cardData.Image?.data?.attributes?.url}
                            alt={`${cardData.Title}'s image`}
                          />
                          <CardContent classes={{ root: 'travel-card-content' }}>
                            <CardHeader
                              title={cardData.Title}
                              classes={{ root: 'travel-card-header-root' }}
                            />
                            <CardHeader
                              subheader={cardData.Description}
                              classes={{ root: 'travel-card-header-root' }}
                              subheaderTypographyProps={{
                                sx: { color: 'common.black' }
                              }}
                            />
                          </CardContent>
                          <CardActions
                            classes={{ root: 'travel-card-actions' }}
                            color="secondary"
                          >
                            <Button
                              variant="outlined"
                              // TODO: this needs to be made dynamic and placed in CMS
                              className="gtm-customerCare-chatbot"
                              {...(cardData.ButtonUrl === 'openDigitalExpert'
                                ? {
                                  onClick() {
                                    setFeedbackButtonVisible?.(false);
                                    setFeedbackOpen?.(false);
                                    setDigitalExpertActive(true);
                                    dataLayer({
                                      'event': 'customerCareDialogOpen'
                                    });
                                  }
                                }
                                : {
                                  component: 'a',
                                  href: cardData.ButtonUrl,
                                  target: '_blank'
                                })}
                            // className={!!buttonTag ? buttonTag : ''}
                            >
                              {cardData.ButtonLabel}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    )
                  )}
              </Grid>
            </Container>
          </Box>

          <Grid
            className="customer-bottom"
            container
            direction={desktop ? 'row' : 'column'}
            sx={{ textAlign: desktop ? 'left' : 'center' }}
            wrap="nowrap"
          >
            <Grid className="bottom-left" item xs={6}>
              <Box className="left-text">
                <Typography
                  variant="h4"
                  mb={4}
                  color={theme.palette.background.default}
                >
                  {customerCare.DoYouNeedAssistance}
                </Typography>
                <Typography
                  component="div"
                  variant="h3"
                  className="phone-number"
                  dangerouslySetInnerHTML={{ __html: customerCare.Numbers }}
                />

                <Typography
                  component="div"
                  variant="body1"
                  mb={2}
                  mt={2}
                  className="phone-number"
                  color={theme.palette.background.default}
                  dangerouslySetInnerHTML={{ __html: customerCare.NumbersNote }}
                />
              </Box>
            </Grid>
            <Grid className="bottom-right" item xs={6}>
              <Box className="right-text">
                <Typography variant="h4" mb={4}>
                  {customerCare.HowCanYouBenefit}
                </Typography>
                <Typography
									className='customer-care-text'
                  variant="body1"
                  component="div"
                  dangerouslySetInnerHTML={{
                    __html: customerCare.RegisterIQOSServices
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Styles>
			<LoadingBackdrop open={loading} />
    </Layout>
  );
};

const Styles = styled.div`
	.hero {
		padding: 4vw;

		${theme.breakpoints.down('md')} {
			text-align: center;
		}

		${theme.breakpoints.up('md')} {
			height: 40vw;
		}

		h1 {
			font-size: 33px;

			span {
				font-weight: 300;
			}
		}

		h2 {
			margin: 40px 0;
			font-size: 3.5vw;

			${theme.breakpoints.down('md')} {
				font-size: 8vw;
			}
		}

		b {
			color: ${theme.palette.primary.main};
		}

		h3 {
			font-weight: 300;
			font-size: 2rem;
		}
	}

	p {
		font-size: 1.5rem;
		font-weight: 300;
	}

	.text-area {
		padding: 8vw;
	}

	.breadcrumbs {
		font-size: 1rem;
		font-weight: 400;
		opacity: 0.4;

		.breadcrumb-link {
			color: #fff;
			text-decoration: none;
		}
	}

	.image-shop {
		position: absolute;
		right: 0;
		top: 5vw;
		max-width: 40vw;
		width: 100%;
		transition: 0.2s ease;

		${theme.breakpoints.down('md')} {
			position: relative;
			max-width: 100%;
		}

		${theme.breakpoints.up(1800)} {
			max-width: 40vw;
		}
	}

	.button {
		border: 3px solid ${theme.palette.background.default};
		border-radius: 50px;
		min-width: 150px;
		height: 51px;
		color: ${theme.palette.background.default};
		font-size: 1rem;
		text-transform: capitalize;
		padding: 0.5rem 0;
	}

	.button:hover {
		border: 3px solid #fff;
	}

	.customer-middle {
		background: #fff;
		padding: 4vw;

		${theme.breakpoints.down('md')} {
			padding: 8vw;
		}

		.middle-left {
			position: relative;
			min-height: 600px;

			${theme.breakpoints.down('md')} {
				min-height: 54.2vw;
			}

			.left-image {
				background: #00d1d2;
				object-fit: cover;
				height: 100%;
				width: 100%;
				margin: auto;
				position: absolute;
			}

			.gradient {
				background: linear-gradient(to top, #0000004e, transparent);
				object-fit: cover;
				height: 100%;
				width: 100%;
				margin: auto;
				position: absolute;
			}
		}

		.middle-right {
			position: relative;
			padding-top: 0;
			margin-left: 1vw;
			transition: 0.3s ease;

			${theme.breakpoints.down('md')} {
				margin: 0;
				margin-top: 8vw;
			}

			img {
				width: 100%;
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(to top, #000, transparent);
				opacity: 0.2;
			}
		}

		.middle-text {
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 3vw 4vw;
			max-width: 50%;

			.MuiTypography-body1 {
				font-size: 1.2vw;
				font-weight: 600;
				line-height: 1.2;

				${theme.breakpoints.down('md')} {
					font-size: 2.5vw;
				}
			}

			button {
				padding-left: 0;
				font-size: 0.9vw;
				font-weight: 400;
				text-transform: none;

				${theme.breakpoints.down('md')} {
					font-size: 2.8vw;
				}
			}
		}
	}

	.customer-bottom {
		background: ${theme.palette.primary.main};
		padding: 5vw 20vw;

		${theme.breakpoints.down('md')} {
			text-align: center;
			padding: 5vw 15vw;
		}

		.MuiTypography-h4 {
			font-size: 2vw;
			margin-bottom: ${theme.spacing(4)};

			${theme.breakpoints.down('md')} {
				font-size: 7vw;
			}
		}

		.MuiTypography-h3 {
			font-size: 2.5vw;

			${theme.breakpoints.down('md')} {
				font-size: 8vw;
			}
		}

		.MuiTypography-body1 {
			font-size: 1vw;

			${theme.breakpoints.down('md')} {
				font-size: 3vw;
			}
		}

		.bottom-left {
			border-right: 4px solid #fff;

			.left-text {
				max-width: 75%;
				width: 100%;
				padding: ${theme.spacing(2, 0)};

				${theme.breakpoints.down('md')} {
					max-width: 100%;
				}
			}

			${theme.breakpoints.down('md')} {
				border: none;
			}
		}

		.right-text {
			padding-left: ${theme.spacing(4)};
			max-width: 90%;
			width: 100%;

			${theme.breakpoints.down('md')} {
				max-width: 100%;
				padding: 0;
			}
		}
	}

	.travel-card {
		background-color: transparent;
		border-radius: 0;
		border: none;
		color: ${theme.palette.common.black};
		height: 100%;
		display: flex;
		flex-direction: column;

		&-content {
			padding: ${theme.spacing(2, 0)};
			flex-grow: 1;

			.travel-card-header-root {
				padding: ${theme.spacing(2, 0)};
			}
		}

		&-actions {
			padding: 0;
		}
	}
	
	.phone-number a {
		color: white !important;
		transition: opacity ease-in-out 0.2s;
		
		&:hover {
			opacity: 0.6;
		}
	}

	.customer-care-text {
		text-shadow: 0.5px 0.5px #000;
	}
`;

export default CustomerCare;
