import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'

import theme from '../../theme'
import Layout from '../Layout'
import { IqosTravelContext } from '../../App'

import { formatLocaleUrl } from '../../utils/common'
import config from '../../config.json'
import axios from 'axios'
import LoadingBackdrop from '../LoadingBackdrop'

const Home = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true)
	const [localTranslations, setLocalTranslations] = useState<any>({})
	const { translations } = useContext(IqosTravelContext);
	const { lang: langParam } = useParams();

	useEffect(() => {
		document.title = 'Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel';
		const url = new URL(window.location.href);
		if (!langParam) {
			url.pathname = 'en';
			window.history.replaceState({}, '', url.toString());
		}

		(async () => {
			try {
				const res = await axios.post(config.cmsUrl + '/home', {
					locale: langParam,
				})
				const data = res.data
				setLocalTranslations(data);
			} catch (e) {
				console.error(e);
			}
			setLoading(false);
		})()
	}, [langParam]);

	const page = localTranslations?.page

	const hero = page?.data?.attributes?.hero
	const heroLogoUrl = hero?.logo?.data?.attributes?.url
	const heroLogoName = hero?.logo?.data?.attributes?.name
	const heroImageUrl = hero?.image?.data?.attributes?.url
	const heroImageName = hero?.image?.data?.attributes?.name
	const heroTitle = hero?.title
	const heroSubtitle = hero?.subtitle
	const heroDescription = hero?.description
	const services = page?.data?.attributes?.services

	return (
		<Layout translations={translations} footerData={translations.footer?.data?.attributes}>
			<Styles>
				<Box sx={{ py: 10 }} className="home-hero">
					<Container maxWidth="lg">
						<Grid
							container
							direction={{ xs: 'column', md: 'row' }}
							justifyContent="center"
							alignItems="center"
						>
							<Grid
								item
								xs={12}
								md={8}
								justifyContent={{ xs: 'center', md: 'flex-start' }}
							>
								<Stack
									direction="column"
									spacing={6}
									alignItems={{ xs: 'center', md: 'start' }}
								>
									<img
										src={heroLogoUrl}
										alt={heroLogoName}
										className="home-hero-logo"
									/>
									<Stack direction="column" spacing={1}>
										<Typography
											variant="h1"
											sx={{ textAlign: { xs: 'center', md: 'left' } }}
										>
											{heroTitle}
										</Typography>
										<Typography
											variant="h1"
											sx={{ textAlign: { xs: 'center', md: 'left' } }}
											color="primary"
										>
											{heroSubtitle}
										</Typography>
									</Stack>
									<Typography
										variant="h5"
										fontWeight="100"
										sx={{ textAlign: { xs: 'center', md: 'left' } }}
									>
										{heroDescription}
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'end' }}>
								<img
									src={heroImageUrl}
									alt={heroImageName}
									className="home-hero-image"
								/>
							</Grid>
						</Grid>
					</Container>
				</Box>

				<Box>
					{services?.map(
						(data: any) => {
							return (
								<Stack
									key={data.title.serviceTitle}
									direction={{
										md: data.reversed ? 'row-reverse' : 'row',
										xs: 'column-reverse'
									}}
									className={`home-section ${data.backgroundColour === 'primary'
										? 'primary'
										: data.backgroundColour === 'secondary'
											? 'secondary'
											: 'tertiary'
										} ${data.imagePosition === 'absolute' ? 'py' : ''}`}
								>
                {data.img && (
                  <Box className={`home-section-container ${data.imagePosition === 'cover' ? 'display' : ''}`}>
										<img
											src={data.image.data.attributes.url}
											alt={data.image.data.attributes.name}
											className={`home-section-image ${data.imagePosition === 'cover'
												? 'cover'
												: data.imagePosition === 'absolute'
													? 'absolute'
													: ''
												} ${langParam === 'ar' ? 'ar' : 'non-ar'}`}
										/>
									</Box>
                )}
									<Box sx={{ flex: 1 }} className={`home-section-container`}>
										<Stack spacing={6}>
											<Stack spacing={2} sx={{ color: 'common.white' }}>
												<Typography
													variant="h1"
													component="h2"
													className={`home-section-title
                                                ${data.backgroundColour === 'primary'
															? 'primary'
															: data.backgroundColour === 'secondary'
																? 'secondary'
																: 'tertiary'
														}`}
												>
													{data.title}
												</Typography>
												{data.subtitle && (
													<Typography variant="h2" component="h3">
														{data.subtitle}
													</Typography>
												)}
											</Stack>
											<div
												dangerouslySetInnerHTML={{ __html: data.description }}
												className="home-section-description"
											/>
											<Button
												component={RouterLink}
												to={formatLocaleUrl(data.buttonLink)}
												variant="outlined"
												color="secondary"
												sx={{ width: 'max-content' }}
												size="large"
												className={!!data.buttonTag ? data.buttonTag : ''}
											>
												{data.buttonText}
											</Button>
										</Stack>
									</Box>
								</Stack>
							);
						}
					)}
				</Box>
			</Styles>
			<LoadingBackdrop open={loading} />
		</Layout>
	);
};

const Styles = styled.div`
	.home-hero {
		padding: ${theme.spacing(10, 0)};

		.home-hero-logo {
			object-fit: cover;
			max-width: 200px;

			.home-hero-image {
				object-fit: cover;
				max-width: 100%;
				max-height: 100%;
			}
		}
	}

	.home-section {
		min-height: 700px;
		position: relative;

		.home-section-container {
			flex: 1;
			display: flex;
			align-items: center;

			&.display {
				display: none;
			}

			${theme.breakpoints.up('md')} {
				&.display {
					display: block;
				}
			}

			> div {
				flex-grow: 1;
				padding: ${theme.spacing(2, 5)};

				${theme.breakpoints.up('md')} {
					padding: ${theme.spacing(0, 12)};
				}
			}

			.home-section-title {
				&.primary {
					color: ${theme.palette.common.black};
				}

				&.secondary {
					color: ${theme.palette.primary.main};
				}

				&.tertiary {
					color: ${theme.palette.common.black};
				}
			}

			.home-section-description {
				text-shadow: 0.5px 0.5px #000;
			}

			.home-section-image {
				&.cover {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}

				&.absolute {
					object-fit: cover;

					height: 100%;

					${theme.breakpoints.up('md')} {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}

					${({ theme }) => theme.breakpoints.down('md')} {
						max-width: 100%;
					}
				}

				&.ar {
					left: 10px;
				}

				&.non-ar {
					right: 10px;
				}
			}
		}

		&.py {
			margin: ${theme.spacing(0)};

			${theme.breakpoints.up('md')} {
				margin: ${theme.spacing(4, 0)};
			}
		}

		&.primary {
			background: ${theme.palette.primary.main};
		}

		&.secondary {
			background: ${theme.palette.background.default};
		}

		&.tertiary {
			background: ${theme.palette.alternate.main};
		}
	}
`;

export default Home;
