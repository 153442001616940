import QRCode from 'qrcode';

export function pxToRem(value: number) {
	return `${value / 16}rem`;
}

export const capitalise = (str: string) =>
	`${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;

export const getKeyByValue = (object: any, value: string) => {
	return Object.keys(object).find(key => object[key] === value);
};

export const capitaliseFirstLetter = (s: string) => {
	return s?.charAt(0)?.toUpperCase() + s?.slice(1);
};


export const accurateTimer = (timer: number, max: number, callbackArgument: Function) => {
	let counter = 1;

	const init = (time: number) => {
		const timeStart = new Date().getTime();
		return setTimeout(() => {
			if (counter < max) {
				const fix = new Date().getTime() - timeStart - timer;
				init(time - fix);
				counter++;
			} else {
				// event to be executed at animation end
				callbackArgument();
			}
		}, time);
	};
	return init(timer);
};

export async function generateQr(url: string) {
	try {
		return await QRCode.toDataURL(url, {
			errorCorrectionLevel: 'H',
			type: 'image/jpeg',
			margin: 3,
			color: {
				dark: '#000',
				light: '#fff',
			},
		});
	} catch (error) {
		console.error('generateQr error: ', error);
	}
}
export const findMiddle = (arr: unknown[]) => {
	return arr?.indexOf(arr[Math.floor((arr?.length - 1) / 2)]);
};
