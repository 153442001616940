import React from 'react';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';

const OtherResources: React.FC<{ code: any }> = ({ code }) => {
	const isArabic = code === 'ar';
	return (
		<Styled>
			<Box>
				<Typography
					component="div"
					variant="h1"
					dangerouslySetInnerHTML={{
						__html: isArabic ? 'موارد أخرى' : 'Other Resources'
					}}
					style={{ textAlign: 'center' }}
				/>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-evenly',
						alignItems: 'center',
						width: '100%',
						height: 200
					}}
				>
					<a
						href="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/VEEV-TC.pdf"
						download="VEEV-TC"
					>
						<Button
							variant="contained"
							className="cookie-button"
							color="primary"
							onClick={() => {
							}}
							size={'large'}
						>
							{isArabic ? 'تنزيل دليل المستخدم' : 'Download User Guide'}
						</Button>
					</a>
				</Box>
			</Box>
		</Styled>
	);
};

const Styled = styled.div``;

export default OtherResources;
