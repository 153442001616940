import { createContext, useContext } from "react";
import { Message } from "../sessions";

export const ClientStyleContext = createContext<{
  reset: () => void;
}>({
  reset: () => {},
});

export interface ServerStyleContextData {
  key: string;
  ids: Array<string>;
  css: string;
}

export const ServerStyleContext = createContext<ServerStyleContextData[]>([]);

export const MessageContext = createContext<{
  message: Message | null;
  setMessage: (message: Message) => void;
}>({
  message: null,
  setMessage: () => null,
});

export const CountryContext = createContext<any>({
  country: null,
  onOpen: () => null,
  onChange: () => null,
  countrySelectOpen: null,
  countries: [],
});

export const StardriftPopupContext = createContext<any>({
  onOpen: () => null,
  onChange: () => null,
});

export const AgeVerificationContext = createContext<{
  verified: boolean | null;
}>({
  verified: null,
});

export const useAgeVerification = () => useContext(AgeVerificationContext);

export const LanguageContext = createContext<
  Partial<{
    languages: any;
    language: any;
    navigation: any;
    footer: any;
    onChange: (language: any) => void;
  }>
>({});

export const FeedbackContext = createContext<
  Partial<{
    feedbackOpen: boolean;
    setFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
    feedbackButtonVisible: boolean;
    setFeedbackButtonVisible: React.Dispatch<React.SetStateAction<boolean>>;
  }>
>({});

export const useLanguages = () => useContext(LanguageContext);

export const useCountry = () => useContext(CountryContext);

export const useStardriftPopup = () => useContext(StardriftPopupContext);

export const useFeedback = () => useContext(FeedbackContext);
