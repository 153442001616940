/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { useEffect, useRef, useState } from 'react'
import { accurateTimer } from '../../../helpers/common'
import { Stack } from '@mui/material';
import styled from '@emotion/styled';


type Props = {
  choices: any;
  handleLAUAnswer: (choices: any) => void;
  initialLoad: boolean;
  setInitalLoad: (arg0: boolean) => void;
  kiosk?: boolean;
}

const ChoiceGrid: React.FC<(Props)> = ({
  choices,
  handleLAUAnswer,
  initialLoad,
  setInitalLoad,
  kiosk
}) => {

  const [hide, setHide] = useState<boolean>();
  const $lastChoices = useRef<any[]>();

  useEffect(() => {
    setHide(true);
    accurateTimer(
      initialLoad ? 9100 : 3200, 1, () => {
        $lastChoices.current = choices;
        setHide(false);
      })
    setInitalLoad(false)

    return () => {
      setHide(false);
    }
  }, [])

  const handleClick = async (choices: any) => {
    handleLAUAnswer(choices)
  }

  return (
    <Styles>
      <Stack direction="column" spacing={2} className="stack">
        <div className={`choices-grid ${!!$lastChoices.current?.length && !hide ? 'choices-grid-active' : 'choices-grid-inactive'} ${$lastChoices.current && $lastChoices.current.length > 4 && 'choices-grid-small'}`} >
          {$lastChoices.current && (
            $lastChoices.current?.map((choice, index) =>
              <div
                key={index}
                className={`choice-button`}
                onClick={() => handleClick(choice?.choice)}
                dangerouslySetInnerHTML={{ __html: choice?.choice?.LAUAnswer?.html }}
              >
              </div>
            )
          )}
        </div>
      </Stack>
    </Styles>
  )
}


const Styles = styled.div(({ theme }) => `
  height: 100%;
    .stack {
      height:100%;
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      margin-bottom: 16px;

      overflow: auto;
    }

    .choice-title { color: black; }
  .choices-grid{
    /* This and all children */
		&, * {
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */

      &::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
    }
    
    &-small {
      grid-template-columns: auto auto auto;
      grid-auto-rows: 30%;
    }
    transition: opacity linear 0.5s;
    &-inactive {
      transition: all linear 0.5s;
      opacity: 0;
    }
    &-active {
      opacity: 1;
    }
    position:relative;
    /* right:100vw; */
    width:85%;
    height:85%;
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows:40%;
    grid-gap: 20px;
    row-gap: 10px;
    overflow-y:scroll;
    .choice-button{
      padding:5px;
        border-radius:1.6rem;
      background:#34303D;
      color: white;
      display:flex;
      align-items:center;
      justify-content:center;
      text-transform:none;
      p{
        font-size:small;
      }
      &:hover {
        background:#00D1D2;
        cursor: pointer;
      }
    }
  }
`);

export default ChoiceGrid
