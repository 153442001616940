import {
	Box,
	Breadcrumbs,
	Button,
	Container,
	Grid,
	Link,
	Stack,
	Typography
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, useContext, useMemo } from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'

import theme from '../../theme'
import Layout from '../Layout'

import ProductShowcase from '../ProductShowcase'
import DestinationSelectorBar from '../DestinationSelectorBar'
import { IqosTravelContext } from '../../App'

import { formatCountryCode, formatLocaleUrl } from '../../utils/common'
import config from '../../config.json'
import axios from 'axios'
import lookup from 'country-code-lookup'

import { useCountry } from '../../helpers/contexts'
import LoadingBackdrop from '../LoadingBackdrop'

const BuyingAbroadCountry = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);

	const searchParams = new URL(window.location.href).searchParams;
	const airportIata = searchParams.get('airport');

	const [localTranslations, setLocalTranslations] = useState<any>({})
	const { translations } = useContext(IqosTravelContext);
	const { lang: langParam } = useParams();

	const { country, countries } = useCountry();

	useEffect(() => {
		document.title = 'Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel';

		(async () => {
			try {
				const res = await axios.post(config.cmsUrl + '/buying-abroad', {
					locale: langParam,
				})
				const data = res.data
				setLocalTranslations(data);
			} catch (e) {
				console.error(e);
			}
			setLoading(false);
		})()
	}, [langParam]);

	const airportsList = localTranslations?.airports || [];

	const airports = useMemo(
		() =>
			airportsList?.map((airport: any) => ({
				...airport,
				name: airport?.name === "Cote D\\\\'Azur" ? "Cote D'Azur" : airport?.name
			})),
		[airportsList]
	);

	const [chosenAirport, setChosenAirport] = useState<any>(airportIata);

	const ISOCode = country?.ISO ?? lookup.byCountry(country?.Name ?? '')?.iso2;

	let pageName = country?.Name?.toLowerCase();
	if (pageName === 'bosnia and herzegovina') pageName = 'bosnia herzegovina';

	const airportName = chosenAirport ?? '';
	const chosenAirportName = airports?.find(
		(airport: any) => airport.iata === chosenAirport
	)?.name

	const airportAddress = chosenAirportName ? (`${chosenAirportName} Airport ${country?.CountryCode}`).replaceAll(' ', '+') : country?.CountryCode

	const airportCountries = [
		...new Set(
			airports
				?.map((airport: any) => airport.market)
				?.map((airportMarket: any) =>
					countries?.find(
						(c: any) => formatCountryCode(c?.CountryCode) === formatCountryCode(airportMarket)
					)
				)
				?.filter(Boolean)
		)
	] as any;

	const page = localTranslations?.buyingAbroad?.data?.attributes || {}
	const navigation = translations?.navigation?.data?.attributes || {}
	return (
		<Layout translations={translations} footerData={translations.footer?.data?.attributes}>
			<Styles>
				<Stack>
					<Grid container justifyContent="center" className="hero">
						<Box className="hero">
							<Container sx={{ flexGrow: 1 }}>
								{country && (
									<Breadcrumbs separator=">">
										<Link
											component={RouterLink}
											to={formatLocaleUrl(`/shop`)}
										>
											{navigation?.shop}
										</Link>
										<Link component={RouterLink} to="#" aria-current="page">
											{navigation?.buyingAbroad}
										</Link>
									</Breadcrumbs>
								)}
								<Grid container sx={{ height: '100%', mt: 5 }}>
									<Grid item xs={12} md={6}>
										<Stack
											direction="column"
											sx={{ height: '100%' }}
											justifyContent="flex-start"
										>
											<Stack
												className={'how-to-travel-hero-text'}
												direction="column"
												spacing={4}
											>
												<Typography
													gutterBottom
													variant="h1"
													dangerouslySetInnerHTML={{
														__html: page?.hero?.title
													}}
												/>
												<Typography
													gutterBottom
													variant="body1"
													dangerouslySetInnerHTML={{
														__html: page?.hero?.description
													}}
												/>
											</Stack>
										</Stack>
									</Grid>
									<Grid item xs={12} md={6} pb={3}>
										{country && (
											<img
												width={'100%'}
												alt={''}
												src={
													`/images/handwritingImages/yellow/location_drawing_${country?.Name?.toLowerCase()?.replace(
														/ /g,
														'_'
													)}.svg` ?? ''
												}
											/>
										)}
									</Grid>
								</Grid>
							</Container>
						</Box>
					</Grid>
					<DestinationSelectorBar
						countries={airportCountries}
						airports={airports?.filter(
							(airport: any) =>
								formatCountryCode(airport.market) ===
								formatCountryCode(country?.CountryCode)
						)}
						translations={{
							title: page?.autocomplete?.title,
							button: page?.autocomplete?.buttonText,
							country: page?.autocomplete?.countryPlaceholder,
							airport: page?.autocomplete?.airportPlaceholder
						}}
						selectedAirport={chosenAirport}
						onAirportChange={newAirport => {
							setChosenAirport(newAirport ?? null);
							// update url with new airport but without reloading page
							const url = new URL(window.location.href);
							if (newAirport) {
								url.searchParams.set('airport', newAirport ?? '')
							}
							
							window.history.pushState({}, '', url.toString())
						}}
						link={formatLocaleUrl(
							`/buying-abroad/${formatCountryCode(
								country?.CountryCode
							)}?airport=${chosenAirport}`
						)}
						hideCountrySelectorIfValid
						hideButton
					/>

					<ProductShowcase
						cmsData={page}
						airports={airports}
						chosenAirport={chosenAirport ?? null}
					/>

					<Grid container>
						<Grid item xs={12} md={6} className="left">
							<Box>
								<Typography variant="h3">
									{page?.IsThereLimit?.replace(/\{airport\}/g, airportName)?.replace(
										/\{country\}/g,
										// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
										country?.Name!
									)}
								</Typography>
								<Typography variant="body1" className="expand-bottom">
									{page?.AllowancesMayVary}
								</Typography>
								<Button
									target="_blank"
									href={
										ISOCode
											? `https://www.iatatravelcentre.com/${ISOCode.toLowerCase()}-${country?.Name?.toLowerCase().replace(/ /g, '-')}-customs-currency-airport-tax-regulations-details.htm#Import%20regulations`
											: 'https://www.iatatravelcentre.com/'
									}
									className="rules-button button gtm-buyingAbroad-iataRules"
									variant="outlined"
									startIcon={<FontAwesomeIcon icon={faExternalLink} />}
								>
									{page?.IATARulesRegulations}
								</Button>
							</Box>
						</Grid>
						<Grid item xs={12} md={6} className="right">
							<Typography variant="h3">
								{page?.FindClosestIQOS?.replace(/\{airport\}/g, airportName)?.replace(
									/\{country\}/g,
									// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
									country?.Name!
								)}
							</Typography>
							<Typography variant="body1" className="expand-bottom">
								{page?.IQOSProductsAvailable?.replace(
									/\{airport\}/g,
									airportName
									// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
								)?.replace(/\{country\}/g, country?.Name!)}
							</Typography>
							<Button
								component={RouterLink}
								to={formatLocaleUrl(
									`/pre-order?country=${formatCountryCode(
										country?.CountryCode
									)}${chosenAirport ? `&airport=${chosenAirport}` : ''}`
								)}
								className="rules-button button gtm-buyingAbroad-preOrder"
								variant="outlined"
							>
								{page?.PreOrder}
							</Button>
							<Button
								component={RouterLink}
								to={formatLocaleUrl(
									`/store-locator?location=${airportAddress}`
								)}
								className="rules-button button gtm-buyingAbroad-findStore"
								variant="outlined"
							>
								{page?.FindClosestShop}
							</Button>
						</Grid>
					</Grid>
				</Stack>
			</Styles>
			<LoadingBackdrop open={loading} />
		</Layout>
	);
};

const Styles = styled.div`
	.buying-abroad-hero-title {
		p {
			margin: 0;
		}
	}

	.buying-abroad-hero-description {
		p:not(:last-of-type) {
			margin-bottom: ${theme.spacing(2)};
		}
	}

	.hero {
		//background: #ebaf34;
		//color: #35303e;
		padding: 2vw;
		height: 100%;
		display: flex;

		${theme.breakpoints.up('xl')} {
			min-height: 50vh;
		}

		${theme.breakpoints.down('md')} {
			padding: 0;
		}
	}

	.text-area {
		padding: 8vw;
	}

	.image-shop {
		position: absolute;
		right: 0;
		/* 5/12 is the ratio of the text, so 7/12 is ratio of image. hence 58% */
		max-width: 50vw;
		width: 100%;
		transition: 0.2s ease;

		${theme.breakpoints.down('md')} {
			position: relative;
			max-width: 100%;
			padding-top: 2rem;
		}

		${theme.breakpoints.up(1800)} {
			max-width: 28vw;
		}
	}

	.MuiAutocomplete-input {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		min-width: 300px;
		width: 100%;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.MuiAutocomplete-popper {
		background: #fff;
		color: #35303e;
		font-size: 1rem;
		font-weight: 800;
		border-radius: 50px;
	}

	.destination {
		background: ${theme.palette.primary.main};
		padding: 2vw;
	}

	.discover-range {
		background: #ebaf34;
		padding: 2vw;
	}

	.destination-wrapper {
		position: relative;
	}

	.close-icon {
		position: absolute;
		top: -25px;
		right: 0;
		cursor: pointer;
	}

	.left {
		h3, p, a {
			max-width: 80%;
			padding-bottom: ${theme.spacing(3)};
		}

		.rules-button {
			margin-right: auto;
			margin-left: 0;
			padding: 10px 24px;
		}

		background-color: #ebaf34;
		color: black;
		padding-left: ${theme.spacing(9)};
		padding-right: ${theme.spacing(9)};
		padding-top: ${theme.spacing(9)};
		padding-bottom: ${theme.spacing(9)};
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.right {
		* {
			max-width: 80%;
			padding-bottom: ${theme.spacing(3)};

		}

		a {
			margin-right: auto;
			margin-bottom: 1rem;
			padding-bottom: 10px;
		}


		color: ${theme.palette.common.black};
		background-color: white;
		padding-left: ${theme.spacing(9)};
		padding-top: ${theme.spacing(9)};
		padding-bottom: ${theme.spacing(9)};
		display: flex;
		flex-direction: column;
	}

	.inputs {
		flex-grow: 1;
	}

	.location-input {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem 2rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
		background: transparent;
	}

	.left {
		h3, p, a {
			max-width: 550px;
			padding-bottom: ${theme.spacing(3)};
		}

		.rules-button {
			margin-right: auto;
		}

		.ddf-selection-text {
			max-width: 50%;
		}

		.right {
			* {
				max-width: 550px;
				padding-bottom: ${theme.spacing(3)};
			}

			.iata-button {
				align-self: start;
				margin-left: 20%;
				margin-top: auto;
			}


			color: ${theme.palette.common.black};
			background-color: white;
			padding-left: ${theme.spacing(9)};
			padding-top: ${theme.spacing(9)};
			padding-bottom: ${theme.spacing(9)};
			display: flex;
			flex-direction: column;
		}

		.shop-items {
			background: #fff;
			padding: 4vw 0;
		}

		.variant-section {
			padding: 230px 0;
			background: #27242e;
		}

		.variant {
			text-align: center;
			align-items: center;
			margin: ${theme.spacing(2)};

			.location-option {
				outline: none;
				border: none;
				border-radius: 50px;
				padding: 1rem;
				min-width: 300px;
				font-size: 1rem;
				font-weight: 600;
				color: #35303e;
			}

			.ddf-selection {
				align-items: center;
			}

			.ddf-selection * {
				max-width: 600px;
			}

			.variant-name {
				font-size: 24px;
			}
		}

		.product-tab-selector {
			font-size: 0.93rem;
			color: #707070;

			&.Mui-selected {
				color: #fff;
				outline-color: #fff;
			}

			padding: ${theme.spacing(0, 3, 3, 3)};
		}
`;


export default BuyingAbroadCountry;
