import React from 'react';
import styled from '@emotion/styled';
import { Grid, Stack, Typography } from '@mui/material';
import QAWrapper from '../QAWrapper';
import theme from '../../../theme';

const TabContent: React.FC<{
	children: React.ReactNode;
	qaData: { question: string; answer: string; [key: string]: any }[];
	title: string;
}> = ({ children, title, qaData }) => {
	return (
		<Styles>
			<Grid container spacing={{ xs: 8, md: 0 }}>
				<Grid item xs={12} md={6}>
					<Stack direction="column" spacing={4} sx={{ pr: { md: theme.spacing(10) } }}>
						<Typography variant="h2">{title}</Typography>
						<div style={{ fontWeight: 'bold' }}>{children}</div>
					</Stack>
				</Grid>
				<Grid className={'qa-grid-wrapper'} item xs={12} md={6}>
					<QAWrapper qaData={qaData} />
				</Grid>
			</Grid>
		</Styles>
	);
};

const Styles = styled.div`
	.qa-grid-wrapper {
		${({ theme }) => theme.breakpoints.up('md')} {
			padding-left: 12px !important;
		}
	}
`;

export default TabContent;
