import {
  CssBaseline,
  Typography
} from '@mui/material';
import styled from '@emotion/styled'

const PageNotFound = (): JSX.Element => {
  return (
    <Styles>
      <CssBaseline />
      <div className="error">
        <Typography variant="h1">
          404: Page Not Found
        </Typography>
      </div>
    </Styles>
  )
}

const Styles = styled.div`
  .error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
`


export default PageNotFound