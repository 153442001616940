import React from "react";
import {Link, LinkProps} from "react-router-dom";
import {Box} from "@mui/material";

const DynamicLink = (props: LinkProps) => {
    const { to, ...rest } = props;
    const url = typeof to === 'string' ? to : to.pathname!;
    let isValid = (() => {
        try {
            new URL(url);
            return true
        } catch (e) {
            return false;
        }
    })()

    return (
        <>
            {isValid ? <a href={url} {...rest}/> : <Link to={url} {...rest}/>}
        </>
    );
}

export const DynamicLinkWithoutRef = DynamicLink

export default React.forwardRef<unknown, LinkProps>((props, ref) => (
    <Box ref={ref}>
        <DynamicLink {...props}/>
    </Box>
));