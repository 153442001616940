import React from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import theme from '../../../theme';

const QAWrapper: React.FC<{
	qaData: { question: string; answer: string; [key: string]: any }[];
}> = ({ qaData }) => {
	return (
		<Paper
			sx={{
				background: theme.palette.common.white,
				padding: { xs: theme.spacing(5, 4), md: theme.spacing(9, 7) },
				borderRadius: '16px'
			}}
			elevation={0}
		>
			<Stack direction="column" spacing={4}>
				{qaData?.map(({ question, answer }, i) => (
					<Stack
						direction="column"
						spacing={4}
						sx={{ color: theme.palette.common.black }}
						key={i}
					>
						<Typography variant="h3">{`${question}`}</Typography>
						<div dangerouslySetInnerHTML={{ __html: answer }} />
					</Stack>
				))}
			</Stack>
		</Paper>
	);
};

export default QAWrapper;
