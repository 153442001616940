import styled from '@emotion/styled';
import { Button } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useAgeVerification, useFeedback } from '../../helpers/contexts';
import Feedback from './Feedback';
import { useDataLayer } from "../../utils/dataLayer";

interface Props {
  translations: any;
}

const FeedbackWithIcon: React.FC<Props> = ({ translations }) => {
  const {
    feedbackOpen,
    setFeedbackOpen,
    feedbackButtonVisible
  } = useFeedback();

  const { verified } = useAgeVerification();

  // timer
  const timer = useMemo(() => setTimeout(() => {
    const finished = typeof window !== 'undefined' && window.localStorage.getItem('feedbackFinished') === "true";

    if (!finished) {
      setFeedbackOpen?.(verified ?? true)
    }
  }, 30 * 1000), []);

  useEffect(() => {
    if (feedbackOpen) clearTimeout(timer);
  }, [feedbackOpen]);

  const dataLayer = useDataLayer();

  return (
    <Styles>
      {feedbackButtonVisible && verified && (
        <Button className="feedback-button gtm-feedbackOpenButton" color="secondary" onClick={() => {
          setFeedbackOpen?.(!feedbackOpen);
          dataLayer({
            'event': 'giveFeedbackOpen'
          });
        }} variant="contained" size="small">
          {translations?.leadButton}
        </Button>
      )}
      <Feedback open={feedbackOpen} onClose={() => {
        setFeedbackOpen?.(false);
        typeof window !== 'undefined' && window.localStorage.setItem('feedbackFinished', "true");
      }} translations={translations} />
    </Styles>
  );
}

const Styles = styled.div`
    .feedback-button {
        position: fixed !important;
        padding-left: ${({ theme }) => theme.spacing(4)};
        padding-right: ${({ theme }) => theme.spacing(4)};
        top: 50vh;
        left: 0;
        z-index: 1200;
        transform: rotate(-90deg);
        transform-origin: left top;
    }
`

export default FeedbackWithIcon;
