import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Styles from "./styles";
import theme from "../../theme";
import {
  useCountry,
  useLanguages,
  useStardriftPopup,
} from "../../helpers/contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import LanguageSelect from "../LanguageSelect";
import { formatLocaleUrl } from "../../utils/common";
import DynamicLink from "../DynamicLink";
import { useNavigate } from "react-router";

const Navigation: React.FC<{
  translations: any;
  reducedNav?: boolean;
  onSidebarOpen: () => void;
  prefixLinks?: boolean;
  useVeev?: boolean;
}> = ({ translations, onSidebarOpen, reducedNav, prefixLinks, useVeev }) => {
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [shopMenuOpen, setShopMenuOpen] = useState<boolean>(false);
  const [travelMenuOpen, setTravelMenuOpen] = useState<boolean>(false);
  const $travelButton = useRef<any>(null);
  const $shopButton = useRef<any>(null);
  // use this country to navigate to country code URLs
  const { onOpen: openCountrySelect, country } = useCountry();
  const { language } = useLanguages();

  const countryPath = !!country?.CountryCode ? `/${country?.CountryCode}` : "";
  const domain = prefixLinks ? "https://iqostravel.com" : undefined;
  const shopUrl = formatLocaleUrl(`/shop${countryPath}`, domain);
  const preOrderUrl = formatLocaleUrl(`/pre-order`, domain);
  const storeLocatorUrl = formatLocaleUrl(`/store-locator`, domain);
  const buyingAbroadUrl = formatLocaleUrl(
    `/buying-abroad${countryPath}`,
    domain
  );
  const travelUrl = formatLocaleUrl(`/travel`, domain);
  const customerCareUrl = formatLocaleUrl("/customer-care", domain);
  const howToTravelUrl = formatLocaleUrl("/how-to-travel", domain);
  const navigate = useNavigate();

  return (
    <Styles>
      <Stack justifyContent="center" alignItems="center" className="navigation">
        {!reducedNav && mobile && !useVeev && (
          <>
            <Typography
              sx={{ width: "100vw", textAlign: "center" }}
              bgcolor={"black"}
              onClick={() => openCountrySelect()}
              py={1}
              variant={"body1"}
            >
              <strong>
                {!!country
                  ? translations?.changeLocation
                  : translations?.selectLocation}
                :
              </strong>{" "}
              {country?.Name}
            </Typography>
          </>
        )}

        <Container maxWidth="lg">
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={{ marginRight: "auto", flex: 1 }}>
              {!useVeev &&
                !reducedNav &&
                (!mobile ? (
                  <Stack direction={"row"} alignItems={"center"}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => openCountrySelect()}
                      className="gtm-nav-selectLocation"
                    >
                      {!!country
                        ? translations?.changeLocation
                        : translations?.selectLocation}
                    </Button>
                    {country?.Name && (
                      <Typography pl={2} variant="body1">
                        {country?.Name}
                      </Typography>
                    )}
                  </Stack>
                ) : (
                  <IconButton size="medium" onClick={onSidebarOpen}>
                    <Box className="hamburger-container">
                      <Box className="hamburger" />
                      <Box className="hamburger" />
                    </Box>
                  </IconButton>
                ))}
            </Box>
            <Box component="span" sx={{ flex: 1 }}>
              <Link
                component={DynamicLink}
                className="logo"
                to={
                  useVeev
                    ? formatLocaleUrl("veev", domain)
                    : formatLocaleUrl("", domain)
                }
                sx={{
                  color: "#fff",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {useVeev ? (
                  <img
                    src="/images/veev.svg"
                    width="235"
                    height="24.233"
                    alt="VEEV"
                    className="gtm-nav-logo"
                  />
                ) : (
                  <img
                    src="/images/iqostravel-logo.svg"
                    width="235"
                    height="24.233"
                    alt="IQOS Travel"
                    className="gtm-nav-logo"
                  />
                )}
              </Link>
            </Box>
            {!useVeev ? (
              <Box component="nav" sx={{ marginLeft: "auto", flex: 1 }}>
                <Stack
                  spacing={4}
                  direction="row"
                  component="ul"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ marginLeft: "auto", p: 0 }}
                >
                  {!mobile && (
                    <>
                      <li>
                        <Button
                          ref={$shopButton}
                          variant="text"
                          endIcon={
                            <FontAwesomeIcon
                              icon={shopMenuOpen ? faAngleUp : faAngleDown}
                            />
                          }
                          onClick={() => setShopMenuOpen(true)}
                          className="gtm-nav-shop-dropdown"
                        >
                          {translations?.shop}
                        </Button>
                        <Menu
                          anchorEl={$shopButton?.current}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          keepMounted
                          open={shopMenuOpen}
                          className="gtm-nav-shop"
                          onClose={() => setShopMenuOpen(false)}
                          onClick={() => setShopMenuOpen(false)}
                        >
                          <MenuItem
                            component={DynamicLink}
                            to={shopUrl}
                            className="gtm-nav-shopMenu-shop"
                          >
                            {translations?.shop}
                          </MenuItem>
                          <MenuItem
                            component={DynamicLink}
                            to={preOrderUrl}
                            style={{ paddingLeft: "30px" }}
                            className="gtm-nav-shopMenu-preOrder"
                          >
                            {translations?.preOrder}
                          </MenuItem>
                          <MenuItem
                            component={DynamicLink}
                            to={storeLocatorUrl}
                            style={{ paddingLeft: "30px" }}
                            className="gtm-nav-shopMenu-storeLocation"
                          >
                            {translations?.storeLocator}
                          </MenuItem>
                          <MenuItem
                            component={DynamicLink}
                            to={buyingAbroadUrl}
                            style={{ paddingLeft: "30px" }}
                            className="gtm-nav-shopMenu-buyingAbroad"
                          >
                            {translations?.buyingAbroad}
                          </MenuItem>
                        </Menu>
                      </li>
                      <li>
                        <Button
                          ref={$travelButton}
                          variant="text"
                          // component={DynamicLink}
                          // to={travelUrl}
                          endIcon={
                            <FontAwesomeIcon
                              icon={travelMenuOpen ? faAngleUp : faAngleDown}
                            />
                          }
                          onClick={() => setTravelMenuOpen(true)}
                          className="gtm-nav-travel-dropdown"
                        >
                          {translations?.travel}
                        </Button>
                        <Menu
                          anchorEl={$travelButton?.current}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          keepMounted
                          open={travelMenuOpen}
                          onClose={() => setTravelMenuOpen(false)}
                          onClick={() => setTravelMenuOpen(false)}
                        >
                          <MenuItem
                            component={DynamicLink}
                            to={travelUrl}
                            className="gtm-nav-travelMenu-travel"
                          >
                            {translations?.travel}
                          </MenuItem>
                          <MenuItem
                            component={DynamicLink}
                            to={howToTravelUrl}
                            style={{ paddingLeft: "30px" }}
                            className="gtm-nav-travelMenu-howToTravel"
                          >
                            {translations?.howToTravel}
                          </MenuItem>
                          <MenuItem
                            component={DynamicLink}
                            to={customerCareUrl}
                            style={{ paddingLeft: "30px" }}
                            className="gtm-nav-travelMenu-customerCare"
                          >
                            {translations?.customerCare}
                          </MenuItem>
                        </Menu>
                      </li>
                    </>
                  )}

                  <li className="logo">{!reducedNav && <LanguageSelect />}</li>
                </Stack>
              </Box>
            ) : (
              <Box component="nav" sx={{ marginLeft: "auto", flex: 1 }}>
                <Stack
                  spacing={4}
                  direction="row"
                  component="ul"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ marginLeft: "auto", p: 0 }}
                >
                  {!mobile && (
                    <>
                      <li>
                        <Button
                          variant="text"
                          onClick={() => {
                            navigate(`/${language}/veev-one`);
                          }}
                          className="gtm-nav-shop-dropdown"
                        >
                          VEEV One
                        </Button>
                      </li>
                      <li>
                        <Button
                          variant="text"
                          onClick={() => {
                            navigate(`/${language}/veev-now`);
                          }}
                          className="gtm-nav-travel-dropdown"
                        >
                          VEEV Now
                        </Button>
                      </li>
                    </>
                  )}

                  <li className="logo">{!reducedNav && <LanguageSelect />}</li>
                </Stack>
              </Box>
            )}
          </Stack>
        </Container>
      </Stack>
    </Styles>
  );
};

export default Navigation;
