import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  Link,
  Stack,
  Typography
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'

import theme from '../../theme'
import Layout from '../Layout'

import { IqosTravelContext } from '../../App'
import TravelPopup from '../TravelPopup/';

import { formatLocaleUrl } from '../../utils/common'
import config from '../../config.json'
import axios from 'axios'
import { useCountry } from '../../helpers/contexts'
import LoadingBackdrop from '../LoadingBackdrop'

const Travel = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const {onOpen: countrySelectOpen, country} = useCountry()
  const [localTranslations, setLocalTranslations] = useState<any>({})
  const { translations } = useContext(IqosTravelContext);
  const { lang: langParam } = useParams();
  const [travelPopupOpen, setTravelPopupOpen] = useState<boolean>(!countrySelectOpen);

  useEffect(() => {
    document.title = 'Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel';

    (async () => {
      try {
        const res = await axios.post(config.cmsUrl + '/travel', {
          locale: langParam,
        })
        const data = res.data
        setLocalTranslations(data);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    })()
  }, [langParam])

  const page = localTranslations?.travel?.data?.attributes
  const navigation = translations?.navigation?.data?.attributes

  return (
    <Layout translations={translations} footerData={translations.footer?.data?.attributes}>
      <Styles>
        <Box className="hero">
          <Container sx={{ flexGrow: 1 }}>
            {country?.Name ? (
              <Breadcrumbs separator=">">
                <Link
                  component={RouterLink}
                  to={formatLocaleUrl(`/${navigation?.travel?.toLowerCase()}`)}
                >
                  {navigation?.travel}
                </Link>
                <Link component={RouterLink} to="#" aria-current="page">
                  {country?.Name}
                </Link>
              </Breadcrumbs>
            ) : (
              <Breadcrumbs separator=">">
                <Link
                  component={RouterLink}
                  to={formatLocaleUrl(`/${navigation?.travel?.toLowerCase()}`)}
                >
                  {navigation?.travel}
                </Link>
              </Breadcrumbs>
            )}
            <Grid container sx={{ height: '100%', mt: 5 }}>
              <Grid item xs={12} md={6}>
                <Stack
                  direction="column"
                  sx={{ height: '100%' }}
                  justifyContent="flex-start"
                >
                  <Stack className={'how-to-travel-hero-text'} direction="column" spacing={4}>
                    <Typography gutterBottom variant="h1">
                      {page?.title}
                    </Typography>
                    <Typography gutterBottom variant="body1">
                      {page?.description}
                    </Typography>
                  </Stack>

                  {!!country?.Name && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ width: 'max-content', my: 3 }}
                      size="large"
                      className="gtm-travel-quickTips"
                      onClick={() => setTravelPopupOpen(true)}
                    >
                      {page?.buttonText}
                    </Button>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} pb={3}>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ backgroundColor: 'common.white', padding: theme.spacing(8, 0) }}>
          <Container>
            <Grid container spacing={4}>
              {page?.card
                ?.slice(1)
                ?.map(
                  ({ title, description, buttonText, link, image, buttonTag }: any, i: number) => (
                    <Grid item xs={12} md={4} key={`${title}-${i}`}>
                      <Card classes={{ root: 'travel-card' }} elevation={0}>
                        <CardMedia
                          component="img"
                          image={image?.data?.attributes?.url}
                          alt={`${title}'s image`}
                        />
                        <CardContent classes={{ root: 'travel-card-content' }}>
                          <CardHeader
                            title={title}
                            classes={{ root: 'travel-card-header-root' }}
                          />
                          <CardHeader
                            subheader={description}
                            classes={{ root: 'travel-card-header-root' }}
                            subheaderTypographyProps={{
                              sx: { color: 'common.black' }
                            }}
                          />
                        </CardContent>
                        <CardActions
                          classes={{ root: 'travel-card-actions' }}
                          color="secondary"
                        >
                          <Button
                            variant="outlined"
                            component={RouterLink}
                            to={'/' + langParam + link}
                            className={!!buttonTag ? buttonTag : ''}
                          >
                            {buttonText}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                )}
            </Grid>
          </Container>
        </Box>
        { page?.popup && (
        <TravelPopup
          open={travelPopupOpen}
          onClose={() => setTravelPopupOpen(false)}
          data={page?.popup}
        />)}
      </Styles>
      <LoadingBackdrop open={loading} />
    </Layout>
  );
};

const Styles = styled.div`
    ${theme.breakpoints.down('md')} {
        .how-to-travel-hero-text {
            text-align: center;
            max-width: 450px;
            margin: auto;
        }
	    
	    .gtm-travel-quickTips{
		    margin-left: auto;
		    margin-right: auto;
	    }
    }
	
	

    .travel-card {
        background-color: transparent;
        border-radius: 0;
        border: none;
        color: ${theme.palette.common.black};
        height: 100%;
        display: flex;
        flex-direction: column;

        &-content {
            padding: ${theme.spacing(2, 0)};
            flex-grow: 1;

            .travel-card-header-root {
                padding: ${theme.spacing(2, 0)};
            }
        }

        &-actions {
            padding: 0;
        }
    }

    .hero {
        padding: 4vw;
        height: 100%;
        display: flex;

        ${theme.breakpoints.up('xl')} {
            min-height: 50vh;
        }

        ${theme.breakpoints.down('md')} {
            padding: 0;
        }
    }

    .container {
        ${theme.breakpoints.down('md')} {
            padding: 0;
        }
    }

    .text-area {
        padding: 8vw;
    }

    .hero-text {
        ${theme.breakpoints.down('md')} {
            text-align: center;
        }
    }

    .image-travel {
        position: absolute;
        right: 0;
        top: 300px;
        max-width: 60%;
        width: 100%;
        transition: 0.2s ease;

        ${theme.breakpoints.down('md')} {
            position: relative;
            top: 0;
            max-width: 100%;
            padding-top: 10rem;
        }
    }
`;

export default Travel;
