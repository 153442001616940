import {
  Container,
  Stack,
  Typography,
  Box,
} from '@mui/material'
import { useState } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router';
import { useLanguages } from '../../helpers/contexts';

import Layout from '../Layout'
import VeevImportantInformation from '../Veev/VeevImportantInformation'
import VeevSection from '../Veev/VeevSection'
import VeevFooter from '../Veev/VeevFooter'

const Veev: React.FC = () => {
	// do something

	const navigate = useNavigate();
	const [showImportant, setShowImportant] = useState(false);

	const { language } = useLanguages();
	const isArabic = language === 'ar';

	return (
    <Layout translations={{}} footerData={{}}>
		<Styles>
			{showImportant ? (
				<VeevImportantInformation
					setShowImportant={setShowImportant}
					code={language}
				/>
			) : (
				<>
					<Box sx={{ py: 10 }} className="hero"></Box>
					<Box sx={{ py: 10 }} className="one-section-title">
						<Container maxWidth="lg">
							<Stack direction="column" spacing={1}>
								<Typography
									variant="h1"
									sx={{
										textAlign: { xs: 'center', md: 'center', fontSize: '2rem' }
									}}
								>
									{isArabic
										? 'اكتشف منتجات الفيب الفاخرة المتوفرة لدينا'
										: 'Discover our vaping products'}
								</Typography>
								<Typography
									variant="h1"
									sx={{
										textAlign: { xs: 'center', md: 'center', fontSize: '2rem' },
										marginTop: '50px !important'
									}}
								>
									VEEV ONE
								</Typography>
							</Stack>
						</Container>
					</Box>
					<VeevSection
						code={language}
						imagePlacement="left"
						imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-landing-one-left.jpg"
						sectionStyles={{ paddingBottom: '0px !important' }}
						titleText={
							isArabic
								? 'يُعد VEEV ONE نظام فيب متقدم يعمل باستخدام الكبسولات'
								: 'VEEV ONE is our <br /> pod-vape system'
						}
						bodyText={
							isArabic
								? [
										'يوفر السائل الإلكتروني ما يصل إلى 2000* سحبة بكل عبوة تحتوي على كبسولتين',
										'بطارية متينة توفّر شحنًا كاملًا في أقل من 45 دقيقة',
										'هيكل ألومنيوم مصنوع من قطعة واحدة لضمان منتج متين ومستدام'
								  ]
								: [
										'E-liquid delivers up to 2000* puffs per pack of 2 pods.',
										'Powerful battery makes a full charge in less than 45 minutes**.',
										'Moulded from a single block of aluminium to make it durable and robust.'
								  ]
						}
						imageType="fill"
						handleClick={() => {
							navigate(`/${language}/veev-one`);
						}}
						buttonText={isArabic ? 'يتعلم أكثر' : 'Learn More'}
						disclaimer={
							isArabic
								? '* على اعتبار أن السحبة مدّتها ثانية واحدة. تختلف حسب طريقة الاستخدام الفردية'
								: '* Based on a puff duration of 1 second. May vary depending on individual usage patterns.'
						}
						disclaimerLower={
							isArabic
								? '**أرجع جهازك المستعمل إلينا لإعادة التدوير، ونحن نهتم بالباقي'
								: '**Return your used device to us for recycling, we will take care of it.'
						}
					/>
					<Box sx={{ py: 10 }} className="one-section-title">
						<Container maxWidth="lg">
							<Stack direction="column" spacing={1}>
								<Typography
									variant="h1"
									sx={{
										textAlign: { xs: 'center', md: 'center' },
										marginTop: '50px !important'
									}}
								>
									VEEV NOW
								</Typography>
							</Stack>
						</Container>
					</Box>
					<VeevSection
						code={language}
						imagePlacement="right"
						imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-landing-one-right.jpg"
						sectionStyles={{ paddingBottom: '0px !important' }}
						titleText={
							isArabic
								? 'يعدّ VEEV NOW جهاز فيب فاخر مخصص للاستعمال مرة واحدة'
								: 'VEEV NOW is our <br /> premium disposable vape'
						}
						bodyText={
							isArabic
								? [
										'تشكيلة من النكهات المفعمة بالحيوية مع ما يصل إلى 500 سحبة*',
										'جودة وتصميم بأكثر من 80% مكوّنات قابلة لإعادة التدوير',
										'لا داعي للتنظيف، و الشحن'
								  ]
								: [
										'Vibrant flavour range with up to 500 puffs*',
										'Quality and design with over 80% recyclable components**.',
										'No cleaning, no charging and no refilling.'
								  ]
						}
						imageType="fill"
						handleClick={() => {
							navigate(`/${language}/veev-now`);
						}}
						buttonText={isArabic ? 'يتعلم أكثر' : 'Learn More'}
						disclaimer={
							isArabic
								? '*يُقدر هذا بناءً على سعة بطارية المنتج الجديد ومدة السحبة بمعدل ثانية واحدة. تختلف حسب طريقة الاستخدام الفردية'
								: '*Estimated based on battery capacity of a newly manufactured product and a puff duration of 1 second. May vary depending on individual usage patterns.'
						}
						disclaimerLower={
							isArabic
								? '**أرجع جهازك المستعمل إلينا لإعادة التدوير، ونحن نهتم بالباقي'
								: '**Return your used device to us for recycling, we will take care of it.'
						}
					/>
					<VeevFooter setShowImportant={setShowImportant} code={language} />
				</>
			)}
		</Styles>
    </Layout>
	);
};

const Styles = styled.div`
	.hero {
		height: 600px;
		background-image: url('https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-landing-hero.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	@media only screen and (max-width: 800px) {
		height: 300px;
		background-image: url('https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-landing-hero-mobile.jpg');
	}
`;

export default Veev;
