import styled from '@emotion/styled';

const Styles = styled.div`
	.variant-avatar {
		align-self: center;
		height: 100%;
		width: 100%;
		text-align: center;
		border-radius: 50%;
		
		div {
			max-width: 50%;
		}

		.product-background {
			border-radius: 50%;
			transform: translateX(50%);
		}
		
		img {
			border-radius: 50%;
		}
		
		svg {
			max-width: 50%;
		}
	}
	.embla {
		position: relative;
		background-color: transparent;
		padding: 20px;
		max-width: 670px;
		margin-left: auto;
		margin-right: auto;

		.embla-viewport {
			overflow: hidden;
			width: 100%;

			.embla-container {
				display: flex;
				user-select: none;
				-webkit-touch-callout: none;
				-khtml-user-select: none;
				-webkit-tap-highlight-color: transparent;
				margin-left: -10px;

				.embla-slide {
					position: relative;
					min-width: 80%;
					padding-left: 10px;

					.embla-slide-inner {
						position: relative;
						overflow: hidden;
						height: 190px;

						.embla-slide-img {
							position: absolute;
							display: block;
							top: 50%;
							left: 50%;
							width: auto;
							min-height: 100%;
							min-width: 100%;
							max-width: none;
							transform: translate(-50%, -50%);
						}
					}
				}
			}
		}

		.embla-viewport.is-draggable {
			cursor: move;
			cursor: grab;
		}

		.embla-viewport.is-dragging {
			cursor: grabbing;
		}
	}
`;

export default Styles;
