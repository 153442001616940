import React from 'react';
import styled from '@emotion/styled';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import theme from '../../../theme';

const VeevSection: React.FC<{
	code: String;
	imagePlacement: String;
	imagePath: String;
	sectionStyles: any;
	bodyText: Array<String>;
	imageType: String;
	titleText: String;
	handleClick?: any;
	buttonText: String;
	disclaimer: String;
	disclaimerLower: String;
}> = ({
	code,
	imagePlacement,
	imagePath,
	sectionStyles,
	bodyText,
	imageType,
	titleText,
	handleClick = () => {},
	buttonText,
	disclaimer,
	disclaimerLower
}) => {
	return (
		<Styled>
			<Box sx={{ py: 10 }} className="one-section" style={sectionStyles}>
				<Grid
					container
					direction={{
						xs: 'column',
						md: imagePlacement === 'left' ? 'row' : 'row-reverse'
					}}
					className="one-section-grid"
				>
					<Grid
						item
						xs={12}
						md={6}
						justifyContent={{ xs: 'center', md: 'flex-start' }}
						className={
							imageType === 'fill'
								? 'one-section-left-fill'
								: 'one-section-left-fixed'
						}
						style={{ backgroundImage: `url(${imagePath})` }}
					></Grid>
					<Grid
						item
						xs={12}
						md={6}
						justifyContent={{ xs: 'center', md: 'flex-start' }}
						className="one-section-right"
					>
						<Grid
							item
							className="pre-order-hero-text"
							sx={{ textAlign: 'left' }}
							md={12}
							xs={12}
						>
							<Stack direction="column" spacing={6}>
								<Typography
									component="div"
									variant="h1"
									dangerouslySetInnerHTML={{
										__html: titleText as string
									}}
									textAlign={code === 'ar' ? 'right' : 'left'}
									className="section-title"
								/>
								<Grid
									container
									direction={{ xs: 'column' }}
									style={{
										alignItems: code === 'ar' ? 'flex-end' : 'flex-start'
									}}
								>
									{bodyText.map(text => {
										return (
											<Grid
												item
												className="section-grid-item"
												style={{
													flexDirection:
														code === 'ar' ? 'row-reverse' : 'row'
												}}
											>
												<div>
													<FontAwesomeIcon
														icon={faCircleCheck}
														color="#27242e"
														size="2x"
													/>
												</div>
												<div className="section-text">{text}</div>
											</Grid>
										);
									})}
									<Button
										variant="outlined"
										onClick={handleClick}
										sx={{ maxWidth: 'max-content' }}
									>
										{buttonText}
									</Button>
									<Typography
										gutterBottom
										variant="body2"
										className="section-disclaimer-text"
										textAlign={code === 'ar' ? 'right' : 'left'}
									>
										{disclaimer}
										<br />
										{disclaimerLower}
									</Typography>
								</Grid>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Styled>
	);
};

const Styled = styled.div`
	.one-section {
		.one-section-grid {
			/* height: 850px; */
			.one-section-left-fill {
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				flex: 2;
			}

			.one-section-left-fixed {
				background-repeat: no-repeat;
				background-size: auto;
				background-position: top;
				flex: 2;
			}

			.one-section-right {
				background-color: #f5f4f0;
				padding-top: 50px;
				padding-bottom: 50px;
				padding-left: 100px;
				padding-right: 100px;
				flex: 3;

				${theme.breakpoints.only('xs')} {
					padding-left: 20px;
					padding-right: 20px;
				}
			}
		}
	}

	.no-top-padding {
		padding-top: 0px !important;
	}

	.section-title {
		color: #27242e;
		font-size: 2rem;
	}

	.section-text {
		color: #27242e;
		font-size: 1rem;
		padding-right: 50px;
		padding-left: 50px;
	}

	.section-disclaimer-text {
		color: #27242e;
		font-size: 0.8rem;
		padding-top: 50px;
		padding-bottom: 20px;
	}

	.section-grid-item {
		height: auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-bottom: 50px;
	}
`;

export default VeevSection;
