import React, { useState } from "react";
import { FeedbackContext } from "../../helpers/contexts";

const FeedbackProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackButtonVisible, setFeedbackButtonVisible] = useState(true);

  return <FeedbackContext.Provider
    value={{
      feedbackOpen,
      setFeedbackOpen,
      feedbackButtonVisible,
      setFeedbackButtonVisible
    }}
  >
    {children}
  </FeedbackContext.Provider>
}

export default FeedbackProvider;