import {
	Box,
	Container,
	FormControl,
	Grid,
	IconButton,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	Typography,
	useMediaQuery
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router'
import styled from '@emotion/styled'

import theme from '../../theme'
import Layout from '../Layout'

import { IqosTravelContext } from '../../App'

import config from '../../config.json'
import axios from 'axios'

import airportMaps from '../../airportMaps.json'
import LoadingBackdrop from '../LoadingBackdrop'

const AirportMap = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);

	const desktop = useMediaQuery(theme.breakpoints.up('md'));
	const mobile = useMediaQuery(theme.breakpoints.only('xs'));
  const navigate = useNavigate()
  const { translations } = useContext(IqosTravelContext);
  const { lang: langParam, airport: airportParam } = useParams();
  const [localTranslations, setLocalTranslations] = useState<any>({})

	const [floor, setFloor] = useState(0);

  const [selectedAirport, setSelectedAirport] = useState<any>(null)
  useEffect(() => {
		document.title = 'Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel';
    if (!airportParam) navigate('/404');
    
		const airport = airportMaps.allAirports?.find((a: any) => a.name === airportParam);
    
    if (!airport) navigate('/404');
    setSelectedAirport(airport);
		(async () => {
			try {
				const res = await axios.post(config.cmsUrl + '/store-locator', {
					locale: langParam,
				})
				const data = res.data
				setLocalTranslations(data);
			} catch (e) {
				console.error(e);
			}
			setLoading(false);
		})()
		
	}, [langParam, airportParam])

	
	const handleChange = (event: SelectChangeEvent) => {
		setFloor(parseInt(event.target.value));
	};

	const storeLocator = localTranslations?.storeLocator?.data?.attributes || {}

	return (
		<Layout translations={translations} footerData={translations.footer?.data?.attributes}>
					<Styles>
			<Stack>
				<Box
					component="img"
					sx={{ display: { xs: 'inline-block', md: 'none' } }}
					className="image-shop"
					src="/images/whiteMagnifier.png"
					width="100%"
					alt="Buy IQOS Abroad"
				/>

				<Grid container justifyContent="center" className="hero">
					<Container>
						<Grid container direction="row" wrap="nowrap">
							<Grid
								item
								container
								direction="column"
								sx={{
									textAlign: desktop ? 'left' : 'center',
									color: 'white',
									minWidth: desktop ? 'unset' : '100%'
								}}
								sm={7}
							>
								<Typography
									gutterBottom
									variant="h2"
									fontSize={'75px'}
									sx={{ textTransform: 'capitalize' }}
								>
									{storeLocator?.Welcome} {selectedAirport?.city ?? ''}
								</Typography>
								<Typography gutterBottom variant="body1" fontSize={'24px'}>
									{storeLocator?.UseLocator}
								</Typography>
								<Typography gutterBottom variant="body1" fontSize={'24px'}>
									{storeLocator?.PreOrderProducts}
								</Typography>
								<Box mt={8} />
							</Grid>
							<Grid item sm={desktop ? 5 : 12}>
								{desktop && (
									<Box
										component="img"
										sx={{ display: { xs: 'none', md: 'inline-block' } }}
										className="image-shop"
										src="/images/whiteMagnifier.png"
										width="100%"
										alt="Shop IQOS in Switzerland"
									/>
								)}
							</Grid>
						</Grid>
					</Container>
				</Grid>

				<Box className={'map-section'}>
					<Container
						sx={{
							justifyContent: 'center',
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column'
						}}
					>
						<Stack
							py={2}
							justifyContent={'space-between'}
							direction={mobile ? 'column' : 'row'}
							width="100%"
							alignSelf={'flex-start'}
						>
							<Typography
								variant="h3"
								pb={mobile ? 3 : 0}
								sx={{ color: 'black', textTransform: 'capitalize' }}
							>
								{selectedAirport?.city ?? ''} airport
							</Typography>
							{!!selectedAirport?.maps?.length && (
								<FormControl sx={{ minWidth: '200px' }}>
									<Select
										value={`${floor}`}
										label="Floor Select"
										onChange={handleChange}
										variant={'standard'}
										placeholder={'select floor'}
										sx={[
											{
												bgcolor: '#EEE',
												borderRadius: '50px',
												border: 'none',
												outline: 'none'
											},
											mobile ? { py: 3 } : { py: 1 }
										]}
										className="select gtm-airportMap-dropdown"
									>
										{selectedAirport?.maps?.map((item: any, index: number) => (
											<MenuItem value={`${index}`}>{item?.title} - {item?.location}</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
						</Stack>

						{!!selectedAirport?.maps?.length ? (
							<>
								<Box className={'map-wrapper'}>
									<Box className={'map-1 map-box'} sx={{ border: 'solid' }}>
										<TransformWrapper
											initialScale={1}
											initialPositionX={200}
											initialPositionY={100}
											centerOnInit={true}
										>
											{({ zoomIn, zoomOut, resetTransform }) => (
												<React.Fragment>
													<TransformComponent>
														<img
															src={`/images/airport-maps/${
																(selectedAirport?.city)?.toLowerCase()?.replaceAll(' ', '_') ?? ''
															}/${
																selectedAirport?.maps?.[floor]?.image ?? ''
															}`}
															alt=""
															className="airport-map-image"
														/>
													</TransformComponent>

													<div className="tools">
														<Stack
															justifyContent={'center'}
															direction={'row'}
															pt={1}
														>
															<Box sx={{ px: 3 }}>
																<IconButton
																	className="icon gtm-airportMap-controls-plus"
																	sx={{
																		color: 'black',
																		borderRadius: '20px'
																	}}
																	onClick={() => zoomIn()}
																>
																	<FontAwesomeIcon
																		icon={faPlus}
																		size={'xs'}
																	/>
																</IconButton>
																<IconButton
																	className="icon gtm-airportMap-controls-minus"
																	sx={{
																		color: 'black',
																		borderRadius: '20px'
																	}}
																	onClick={() => zoomOut()}
																>
																	<FontAwesomeIcon
																		icon={faMinus}
																		size={'xs'}
																	/>
																</IconButton>

																<IconButton
																	className="icon gtm-airportMap-controls-refresh"
																	sx={{
																		color: 'black',
																		borderRadius: '20px'
																	}}
																	onClick={() => resetTransform()}
																>
																	<FontAwesomeIcon
																		icon={faExpand}
																		size={'xs'}
																	/>
																</IconButton>
															</Box>
														</Stack>
													</div>
												</React.Fragment>
											)}
										</TransformWrapper>
									</Box>
								</Box>

								<Stack pt={2} sx={{ alignSelf: 'flex-start', color: 'black' }}>
									<Typography py={0.5} fontWeight={'bold'} variant={'h6'}>
										{selectedAirport?.maps?.[floor].title}
									</Typography>
									<Typography py={0.5} variant={'body1'}>
										{selectedAirport?.maps?.[floor].location}
									</Typography>
									<Typography py={0.5} variant={'body1'}>
										{selectedAirport?.maps?.[floor].hours}
									</Typography>
								</Stack>
							</>
						) : (
							<Typography
								align="left"
								component="p"
								variant="h3"
								sx={{ mt: 6, color: 'common.black' }}
							>
								{storeLocator?.MapUnavailable}
							</Typography>
						)}
					</Container>
				</Box>
			</Stack>
		</Styles>
		<LoadingBackdrop open={loading} />
		</Layout>
	);
};

const Styles = styled.div`
	.MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
		border: none !important;
	}

	.icon {
		width: 35px;
		height: 35px;
		margin: 4px;
		background-color: #dddddd;

		&:hover {
			background-color: #cacaca;
		}
	}

	.map-wrapper {
		position: relative;

		.map-box {
			color: whitesmoke;
		}

		& svg > rect {
			fill: whitesmoke;
		}

		& > * {
			display: block;
			position: relative;
			top: 0;
			left: 0;
		}

		& .active {
			display: block;
		}
	}

	.map-section {
		background-color: whitesmoke;
		padding: 4rem 0;
	}

	.select {
		background-color: #dadada;
		color: #1f1f1f;
		padding: 0.7em 1em;

		&:hover::before {
			border-bottom: 2px solid transparent !important;
		}

		& svg {
			color: #1f1f1f;
		}

		&::before {
			border: none;
			transition: none;
		}

		&::after {
			border: none;
		}

		&:hover {
			background-color: #c7c7c7;
			color: black;
			border: none;
		}

		&:active,
		&:focus {
			border: none;
		}
	}

	h1 {
		font-size: 33px;

		span {
			font-weight: 300;
		}
	}

	h2 {
		margin: 40px 0;
		font-size: 3vw;

		${theme.breakpoints.down('md')} {
			font-size: 8vw;
		}
	}

	.hero {
		padding: 4vw;
		background-color: #35303e;

		b {
			color: ${theme.palette.primary.main};
		}

		h3 {
			font-weight: 300;
			font-size: 2rem;
		}

		p {
			max-width: 80%;
			font-size: 1vw;

			${theme.breakpoints.down('md')} {
				font-size: 3vw;
				margin: auto;
			}
		}
	}

	p {
		font-size: 1rem;
	}

	.text-area {
		padding: 8vw;
	}

	.image-shop {
		right: 0;
		transition: 0.2s ease;

		${theme.breakpoints.down('md')} {
			position: relative;
			max-width: 100%;
			padding-top: 2rem;
		}

		${theme.breakpoints.up(1800)} {
			max-width: 40vw;
		}
	}

	.MuiOutlinedInput-notchedOutline {
		display: none !important;
	}

	.MuiAutocomplete-input {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		min-width: 300px;
		width: 100%;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.MuiAutocomplete-popper {
		background: #fff;
		color: #35303e;
		font-size: 1rem;
		font-weight: 800;
		border-radius: 50px;
	}

	.destination {
		background: ${theme.palette.primary.main};
		padding: 2vw;

		&-button {
			color: black;
		}
	}

	.discover-range {
		background: ${theme.palette.background.paper};
		padding: 2vw;
	}

	.destination-wrapper {
		position: relative;
	}

	.close-icon {
		position: absolute;
		top: -25px;
		right: 0;
		cursor: pointer;
	}

	.left {
		* {
			max-width: 80%;
		}

		background-color: #ebaf34;
		padding-right: ${theme.spacing(3)};
		padding-top: ${theme.spacing(3)};
		padding-bottom: ${theme.spacing(3)};
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.inputs {
		padding-top: ${theme.spacing(3)};
	}

	.right {
		* {
			max-width: 80%;
		}

		color: ${theme.palette.common.black};
		background-color: white;
		padding-left: ${theme.spacing(3)};
		padding-top: ${theme.spacing(3)};
		padding-bottom: ${theme.spacing(3)};
		display: flex;
		flex-direction: column;
	}

	.inputs {
		flex-grow: 1;
	}

	.location-input {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem 2rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
		background: transparent;
	}

	.location-select {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		margin: 1rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.location-option {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.location-cards {
		width: 100%;
		overflow: auto;
	}

	.location-card {
		min-width: min(397px, 100%);
		background-color: #ebaf34;
		padding: ${theme.spacing(4)} 0;
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: capitalize;

		.location-card-text {
			color: black;
			text-align: center;
		}

		.location-card-button-container {
			width: 100%;
			display: flex;
			justify-content: center;

			.location-card-button {
				margin-top: ${theme.spacing(2)};
			}
		}
	}

	.ddf-selection-text {
		max-width: 50%;
	}

	.button {
		border: 3px solid ${theme.palette.background.default};
		border-radius: 50px;
		min-width: 150px;
		height: 51px;
		color: ${theme.palette.background.default};
		font-size: 1rem;
		text-transform: capitalize;
		padding: 0.5rem 0.5rem;
	}

	.iata-button {
		align-self: start;
		margin-left: 20%;
		margin-top: auto;
	}

	.right-button {
		align-self: start;
	}

	.dark-button {
		border: 3px solid ${theme.palette.common.white};
		border-radius: 50px;
		min-width: 150px;
		height: 51px;
		color: ${theme.palette.common.white};
		font-size: 1rem;
		text-transform: capitalize;
		padding: 0.5rem 0.5rem;
	}

	.button:hover {
		border: 3px solid #fff;
	}

	.shop-items {
		background: #fff;
		padding: 4vw 0;
	}

	.mapboxgl-canvas {
		height: 50vh;
	}

	.mapboxgl-control-container {
		display: none;
	}

	.search-container {
		padding: ${theme.spacing(6)} 1rem;
	}

	.airport-map-image {
		width: 700px;
		${theme.breakpoints.down('sm')} {
			width: 100%;
		}

		${theme.breakpoints.down('xs')} {
			width: 100%;
		}
	}
`;


export default AirportMap;
