import { Box, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material'
import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'

import theme from '../../theme'
import Layout from '../Layout'
import { IqosTravelContext } from '../../App'
import ImageTile from '../ImageTile';

import { formatLocaleUrl } from '../../utils/common'
import config from '../../config.json'
import axios from 'axios'

import { useCountry } from '../../helpers/contexts';
import LoadingBackdrop from '../LoadingBackdrop'

const Shop = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);

	const { country } = useCountry()
	const desktop = useMediaQuery(theme.breakpoints.up('md'))

	const [localTranslations, setLocalTranslations] = useState<any>({})
	const { translations } = useContext(IqosTravelContext);
	const { lang: langParam } = useParams();

	const title = localTranslations?.shop?.data?.attributes?.title
	const description = localTranslations?.shop?.data?.attributes?.description
	const url = localTranslations?.shop?.data?.attributes?.image?.data?.attributes?.url
	const card = localTranslations?.shop?.data?.attributes?.card

	useEffect(() => {
		document.title = 'Get IQOS at Duty-Free While Travelling - IQOS Travel • IQOS Travel';

		(async () => {
			try {
				const res = await axios.post(config.cmsUrl + '/shop', {
					locale: langParam,
				})
				const data = res.data
				setLocalTranslations(data);
			} catch (e) {
				console.error(e);
			}
			setLoading(false);
		})()
	}, [langParam, country]);
	return (
		<Layout translations={translations} footerData={translations.footer?.data?.attributes}>
			<Styles>
				<Box className="hero">
					{desktop && url && (
						<img
							src={url}
							className="image-shop"
							alt="Shop IQOS hero image"
							style={{ visibility: desktop ? 'visible' : 'hidden' }}
						/>
					)}
					<Container>
						<Grid container>
							<Grid item xs={12} md={8}>
								<Stack direction="column" spacing={6}>
									<Typography variant="h1">{title}</Typography>

									<Typography
										variant="h6"
										fontWeight={theme.typography.fontWeightRegular}
									>
										{description}
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} md={6}>
								{ url &&
								<img
									src={url}
									className="image-shop"
									alt="Shop IQOS hero image"
									style={{
										visibility: !desktop ? 'visible' : 'hidden',
										float: 'right'
									}}
								/>
								}
							</Grid>
						</Grid>
					</Container>
				</Box>

				<div className="shop-items">
					<Container>
						<Grid container justifyContent="center" spacing={2}>
							{card?.length ? card
								.map(
								(cardData: any) =>
									(
										<Grid item md={4} xs={12} key={title}>
											<ImageTile
												title={cardData?.title}
												subTitle={cardData?.subtitle}
												linkText={cardData?.buttonText}
												link={
													cardData.buttonLink?.startsWith('/')
														? formatLocaleUrl(
															`${cardData?.buttonLink}${country?.CountryCode}`
														)
														: `${cardData?.buttonLink}${country?.CountryCode}`
												}
												image={cardData?.image?.data?.attributes?.url}
												className={cardData?.buttonTag}
											/>
										</Grid>
									)
							) : null}
						</Grid>
					</Container>
				</div>
			</Styles>
			<LoadingBackdrop open={loading} />
		</Layout>
	);
};

const Styles = styled.div`
	.hero {
		padding: ${theme.spacing(3)};
		min-height: 500px;
		display: flex;
		align-items: center;

		${theme.breakpoints.up('lg')} {
			height: auto;
			//min-height: 800px;
		}
	}

	.image-shop {
		position: absolute;
		right: 0;
		width: 23vw;
		min-width: 200px;
		transition: 0.2s ease;

		${theme.breakpoints.down('md')} {
			position: relative;
			max-width: 70%;
			right: -30vw;
			padding-top: 2rem;
			padding-bottom: 2rem;
		}

		${theme.breakpoints.up(1800)} {
			max-width: 23vw;
		}
	}

	.text-area {
		padding: 8vw;
	}

	.image-shop {
		position: absolute;
		right: 0;
		max-width: 33vw;
		width: 100%;
		transition: 0.2s ease;

		${theme.breakpoints.down('md')} {
			position: relative;
			max-width: 70%;
			//right: -30vw;
			padding-top: 2rem;
		}

		${theme.breakpoints.up(1800)} {
			max-width: 23vw;
		}
	}

	.MuiAutocomplete-input {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		min-width: 300px;
		width: 100%;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.MuiAutocomplete-popper {
		background: #fff;
		color: #35303e;
		font-size: 1rem;
		font-weight: 800;
		border-radius: 50px;
	}

	.destination {
		background: ${theme.palette.primary.main};
		padding: 2vw;
	}

	.destination-wrapper {
		position: relative;
	}

	.close-icon {
		position: absolute;
		top: -25px;
		right: 0;
		cursor: pointer;
	}

	.inputs {
		flex-grow: 1;
	}

	.location-input {
		outline: none;
		border: none;
		border-radius: 50px;
		//padding: 1rem 2rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
		background: transparent;
	}

	.location-select {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		margin: 1rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.location-option {
		outline: none;
		border: none;
		border-radius: 50px;
		padding: 1rem;
		min-width: 300px;
		font-size: 1rem;
		font-weight: 600;
		color: #35303e;
	}

	.button {
		border: 3px solid ${theme.palette.background.default};
		border-radius: 50px;
		min-width: 150px;
		height: 51px;
		color: ${theme.palette.background.default};
		font-size: 1rem;
		text-transform: capitalize;
		padding: 0.5rem 0;
	}

	.button:hover {
		border: 3px solid #fff;
	}

	.shop-items {
		background: #fff;
		padding: 4vw 0;

		${theme.breakpoints.down('md')} {
			padding-bottom: 8vh;
		}

		.tile {
			min-height: unset !important;
		}
	}

	.image-cta-square {
		position: relative;

		img {
			position: absolute;
		}
	}
`;

export default Shop;
