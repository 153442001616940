/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { useEffect, useState } from 'react';
import { Avatar } from '@mui/material';
import Typing from '../Typing';
import { accurateTimer, generateQr } from '../../../helpers/common';
import styled from '@emotion/styled';
import { alpha } from '@mui/system';
import { useNavigate, Link } from 'react-router-dom'
import { formatLocaleUrl } from '../../../utils/common'

type Message = {
	html: string;
	name: string;
	video?: string;
	link?: string;
	linkLabel?: string;
	action: string | null;
};

type Props = {
	isLastMessage?: boolean;
	message: Message;
	updateScroll: () => void;
	handleOpenClose: () => void;
};

const TextBubble: React.FC<Props> = ({ isLastMessage, message, updateScroll, handleOpenClose }) => {
	const [isTyping, setTyping] = useState<boolean>(isLastMessage ?? false);
	const [qrCode, setQrCode] = useState<any>();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isLastMessage) return;
		const timeout = accurateTimer(2500, 1, () => {
			setTyping(false);
		});
		return () => clearTimeout(timeout)
	}, []);

	useEffect(() => {
		if (!isLastMessage || !isTyping) return;
		updateScroll();
	}, [isTyping]);

	useEffect(() => {
		(async () => {
			const url = message?.link;
			let generatedQrCode = null;

			if (url) generatedQrCode = await generateQr(url);

			setQrCode(generatedQrCode);
		})();
	}, []);

	const handleOpenHeets = () => {
		navigate(formatLocaleUrl('/buying-abroad'))
	};

	return (
		<Styles className={message?.name === 'DE' ? 'assistant-container' : 'user-container'} key={message?.html}>
			{message?.name === 'DE' ? (
				<div className="messageDE">
					<Avatar
						className="smallAvatar"
						alt="Assistant"
						src="/images/jamieSquare.png"
					/>
					<div className={message?.name}>
						{isTyping ? (
							<Typing />
						) : (
							<>
								<div dangerouslySetInnerHTML={{ __html: message?.html }}></div>
								{!isTyping && message?.action === 'open-iqos-availability' && (
									<div className="action-button-container">
										<div className="action-button" onClick={handleOpenHeets}>
											Open IQOS Availability
										</div>
									</div>
								)}
								{!isTyping && message?.action === 'link' && (
									<div className="action-button-container">
										{message.link?.startsWith('/') ? (
											<Link className="action-button" to={message.link}>
												{message?.linkLabel ?? `Open ${message?.link}`}
											</Link>
										) : (
											<a className="action-button" href={message.link}>
												{message?.linkLabel ?? `Open ${message?.link}`}
											</a>
										)}
										<img src={qrCode} key={qrCode} />
									</div>
								)}
							</>
						)}
					</div>
				</div>
			) : (
				<div className="messageLAU">
					<div
						className={message?.name}
						dangerouslySetInnerHTML={{ __html: message?.html }}
					></div>
				</div>
			)}
		</Styles>
	);
};

const Styles = styled.div(({ theme }) => `
    .messageDE{
      width:80%;
      float: left;
      display:flex;
      flex-direction:row;
      align-items:center;
      margin:${theme.spacing(1)};
      .DE{
        padding:10px;
        background:#34303D;
        border-radius:1.6rem;
        color:white;
        text-align:left;
        text-transform:none;
        p{
          font-size:smaller;
        }
      }
      .smallAvatar{
        border: 1px solid #34303D;
        background-color: white;
        width:30px;
        height:30px;
        margin-right:10px;
      }
    }

    &.assistant-container:first-child {
      .messageDE {
        position: relative;

        .smallAvatar {
          align-self: flex-start;
        }
      }
      .messageDE::before {
          top: 0;
          left: 14px;
          height: 10px;
          width: 3px;
          background-color: white;
          content: '';
          position: absolute;
      }
    }

    &.assistant-container:only-child {
      .messageDE {
        position: relative;

        .smallAvatar {
          align-self: flex-start !important;
        }
      }
      .messageDE::before {
          top: 0% !important;
          left: 14px !important;
          height: 100% !important;
          width: 3px;
          background-color: white;
          content: '';
          position: absolute;
      }
    }

    &.assistant-container:last-child {
      .messageDE {
        position: relative;

        .smallAvatar {
          align-self: end;
        }
      }
      .messageDE::before {
          bottom: 0px;
          left: 14px;
          height: 10px;
          width: 3px;
          background-color: white;
          content: '';
          position: absolute;
      }
    }

    &.assistant-container:not(:last-child):not(:first-child) {
      .messageDE {
        .smallAvatar {
          visibility: hidden;
        }
      }
    }

    .messageLAU{
      float: right;
      display:flex;
      flex-direction:row;
      align-items:center;
      margin:${theme.spacing(1)}; 
      width:80%;
      justify-content:flex-end;
      .LAU{
        padding:10px;
        background:#00D1D2;
        border-radius:1.6rem;
        color:white;
        text-align:left;
        text-transform:none;
        p{
          font-size:smaller;
        }
      }
    }
    .video-container{
      margin:10px;
    }
    .action-button-container{
      width:100%;
      height:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      margin-top:10px;
      .action-button{
        text-align:center;
        width:100%;
        background:#00D1D2;
        color:white;
        border-radius:1.6rem;
        padding:10px;
        text-decoration:none;
      }
    }
    ul {
      list-style-type: none;
      padding:0;
      margin-top: 10px;
      li{
        margin: 10px 0px 10px 0px;
      }
    }
    ol {
      padding:0px 0px 0px 10px;
      margin-top: 10px;
      li{
        margin: 10px 0px 10px 0px;
      }
    }
    hr{
      border:0;
      height: 1px;
      background: #333;
      background-image: linear-gradient(to right, #333, #ccc);
      margin: 3px 0px 3px 0px;
    }
    p{
      margin:0;
    }

    .content {
				width: 100%;
				position: relative;
				z-index: 1;
				border-radius: 0;
				box-shadow: none;
				margin: 4px;
        height: 200px;

        align-items: center;
        /* justify-content: center; */
        display: flex;
				/* transition: ${theme.transitions.create(['box-shadow'], {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.shorter,
				})}; */

				.content-image {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					z-index: 1;
					background-color: ${alpha(theme.palette.common.black, 0.25)};
				}

				.content-overlay {
					height: 0;
					overflow: hidden;
					padding-top: 56.25%;
					width: 100%;
					position: absolute;
					left: -${theme.spacing(2)};
					bottom: -${theme.spacing(2)};
					background-color: ${alpha(theme.palette.common.black, 0.25)};
				}
			}
`)

export default TextBubble;
