import styled from '@emotion/styled';

const Styles = styled.div`
	.cookie-text {
		border: unset !important;
		position: relative;
		outline: none !important;
		color: ${({ theme }) => theme.palette.common.white} !important;
		font-weight: 100 !important;
		visibility: hidden;
		display: inline-block !important;
		width: 0 !important;
		max-width: 0 !important;
		height: 24px !important;
		padding: 0 8rem;
		overflow-y: hidden;
		min-width: 100%;

		&,
		&:hover,
		&:active,
		&:focus {
			background-color: transparent !important;
			text-decoration: none !important;
		}
		&:hover {
			opacity: 0.8;
		}
		&::after {
			top: 0;
			left: 0;
			position: absolute;
			visibility: visible;
			content: 'cookie preferences';
			height: 24px;
			padding: 0;
			overflow-y: hidden;
		}
		font-size: 16px !important;
	}

	background-color: ${({ theme }) => theme.palette.common.black};
	.footer {
		.footer-white {
			background: white;
			width: 100%;
			color: black;
			font-weight: 700;
			font-family: iqos-sans, Helvetica, sans-serif;
			padding: 20px 0;
			text-align: center;
			border-top: 2px solid;
			border-bottom: 2px solid;
			border-color: ${({ theme }) => theme.palette.common.black};

			p {
				font-size: 0.8rem;
				font-weight: bold;
			}
		}

		.footer-bottom {
			nav {
				display: flex;
				justify-content: space-between;
				padding: 20px 0;

				${({ theme }) => theme.breakpoints.down('md')} {
					flex-direction: column;
					padding-bottom: 100px;
				}

				.footer-bottom-copyright {
					padding: 0;

					li {
						margin-left: 0;
					}
				}

				.footer-bottom-links {
					padding: 0;

					${({ theme }) => theme.breakpoints.down('md')} {
						flex-direction: column;
					}

					li {
						text-transform: uppercase;
					}
				}
				ul {
					list-style: none;

					li {
						font-size: 1rem;
						margin-left: 40px;

						${({ theme }) => theme.breakpoints.down('md')} {
							margin: 10px 0;
						}

						a {
							text-decoration: none;
							color: ${({ theme }) => theme.palette.text.primary};
							transition: ${({ theme }) => theme.transitions.create(['color'])};

							font-family: iqos-sans, Helvetica, sans-serif;
							font-weight: 300;
							&:hover {
								opacity: 0.8;
							}
						}
					}
				}
			}
		}
	}
`;

export default Styles;
