import { createTheme, darken, lighten } from '@mui/material';
import { css } from '@emotion/react';
import { grey } from '@mui/material/colors';
import { pxToRem } from './helpers/common';

const theme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: '#ebaf34'
		},
		secondary: {
			main: '#1e1f1f' // no secondary usage
		},
		alternate: {
			main: '#00D1D2'
		},
		background: {
			default: '#35303E',
			paper: '#222526'
		},
		common: {
			black: '#27242E',
			white: '#fff'
		}
	},
	typography: {
		fontFamily: ['iqos-sans', 'Helvetica', 'sans-serif'].join(', '),
		h1: {
			fontSize: pxToRem(46),
			fontWeight: 700
		},
		h2: {
			fontSize: pxToRem(40),
			fontWeight: 700
		},
		h3: {
			fontSize: pxToRem(36),
			fontWeight: 700
		},
		h4: {
			fontSize: pxToRem(30),
			fontWeight: 700
		},
		h5: {
			fontSize: pxToRem(28),
			fontWeight: 400
		},
		h6: {
			fontSize: pxToRem(27),
			fontWeight: 700
		},
		body1: {
			fontSize: '1rem',
			fontWeight: 400
		}
	},
	shape: {
		borderRadius: 4,
		borderRadiusSm: 8,
		borderRadiusMd: 16,
		borderRadiusLg: 24,
		borderRadiusXl: 40
	}
}as any) as any;

theme.components = {
	MuiCardHeader: {
		styleOverrides: {
			root: css``
		},
		variants: [
			{
				props: { color: 'secondary' },
				style: css`
					color: ${theme.palette.common.black};
				`
			}
		]
	},
	MuiTypography: {
		variants: [
			{
				props: { color: 'alternate' },
				style: css`
					color: ${theme.palette.alternate.main};
				`
			},
			{
				props: { color: 'secondary' },
				style: css`
					color: ${theme.palette.common.black};
				`
			}
		]
	},
	MuiTextField: {
		styleOverrides: {
			root: css`
				border: none;
			`
		}
	},
	MuiBreadcrumbs: {
		styleOverrides: {
			root: css`
				display: none;
				${theme.breakpoints.up('md')} {
					display: block;
				}
			`,
			li: css`
				opacity: 0.7;
				.MuiLink-root {
					text-decoration: none;
					color: ${theme.palette.common.white};
					font-weight: ${theme.typography.fontWeightBold};
				}
				&:last-child {
					opacity: 1;
				}
			`,
			separator: css`
				color: ${theme.palette.common.white};
				margin-right: ${theme.spacing(2)};
				margin-left: ${theme.spacing(2)};
				font-size: 1.5rem;
			`
		},
		variants: [
			{
				props: { color: 'secondary' },
				style: css`
					.MuiBreadcrumbs-separator {
						color: ${theme.palette.common.black};
					}
					
					.MuiBreadcrumbs-li {
						.MuiLink-root {
							color: ${theme.palette.common.black};
						}
					}
				`
			}
		]
	},
	MuiLink: {
		styleOverrides: {
			root: css`
				cursor: pointer;
			`
		}
	},
	MuiOutlinedInput: {
		styleOverrides: {
			root: css`
				color: ${theme.palette.common.black};

				.MuiSvgIcon-root {
					color: ${theme.palette.common.black};
				}

				&,
				&.Mui-focused,
				&:hover {
					.MuiOutlinedInput-notchedOutline {
						border: 2px solid ${theme.palette.common.black};
					}
				}
			`
		},
		variants: [
			{
				props: { color: 'secondary' },
				style: css`
					.MuiOutlinedInput-notchedOutline {
						border: 2px solid ${theme.palette.common.white};

						&,
						&:focus {
							border: 2px solid ${theme.palette.common.white};
						}
						&:hover {
							border: 2px solid ${theme.palette.common.white};
						}
						.MuiPaper-root {
							background-color: ${theme.palette.common.white};
							color: ${theme.palette.common.black};
						}
					}
				`
			}
		]
	},
	MuiPaper: {
		styleOverrides: {
			root: css`
				border-radius: ${theme.shape.borderRadiusMd}px;
			`
		}
	},
	MuiAutocomplete: {
		styleOverrides: {
			root: css``,
			option: {
				'&:hover': {
					'&[data-focus="true"]': {
						backgroundColor: 'red'
					},
					'&[aria-selected="true"]': {
						backgroundColor: 'blue'
					}
				}
			}
		}
	},
	MuiButton: {
		styleOverrides: {
			root: css`
				position: relative;
				overflow: hidden;
				z-index: 0;
				color: ${theme.palette.text.primary};
				font-weight: 700;
				padding: ${theme.spacing(0.75, 5)};
				border-radius: 50px;
				border: none;
				text-transform: none;
			`
		},
		variants: [
			{
				props: { variant: 'text' },
				style: css`
					text-transform: none;
					padding: ${theme.spacing(0.75, 1.5)};

					&,
					&:focus,
					&:hover {
						background: transparent;
					}
				`
			},
			{
				props: { variant: 'outlined' },
				style: css`
					background: transparent;
					border: 2px solid ${theme.palette.background.default};
					color: ${theme.palette.background.default};
					transition: ${theme.transitions.create(['color', 'border'], {
						duration: theme.transitions.duration.standard
					})};
					&:focus {
						color: ${theme.palette.background.default};
					}
					
					&:hover {
						color: ${theme.palette.common.white};
						border: 2px solid ${theme.palette.background.default};
						background: transparent;
						svg {
							path {
								fill: ${theme.palette.common.white};
							}
						}
					}

					&::after {
						background: ${theme.palette.background.default};
						content: '';
						height: 200%;
						left: -121%;
						position: absolute;
						top: -50%;
						transform: skewX(0deg);
						transition: ${theme.transitions.create(['left', 'transform', 'width'], {
							duration: theme.transitions.duration.standard
						})};
						width: 0;
						z-index: -1;
					}

					&:hover::after {
						content: '';
						left: -10%;
						transform: skewX(-20deg);
						width: 120%;
					}
					svg {
						height: ${theme.spacing(2)};
						width: ${theme.spacing(2)};
						path {
							transition: ${theme.transitions.create('fill', {
								duration: theme.transitions.duration.standard
							})};
							fill: ${theme.palette.background.default};
						}
					}

					&.Mui-disabled {
						cursor: not-allowed;
						color: rgb(173 173 173 / 40%);
						box-shadow: none;
						background-color: rgb(173 173 173 / 20%);
						border-color: rgb(173 173 173 / 40%);

						&:focus,
						&:hover {
							background: ${theme.palette.common.white};
							border: 2px solid ${lighten(theme.palette.background.default, 0.2)};
						}
					}
				`
			},
			{
				props: { variant: 'outlined', color: 'secondary' },
				style: css`
					background: transparent;
					border: 2px solid ${theme.palette.common.white};
					color: ${theme.palette.common.white};
					transition: ${theme.transitions.create(['color', 'border'], {
						duration: theme.transitions.duration.standard
					})};

					&:focus {
						color: ${theme.palette.common.white};
						border-color: ${theme.palette.common.white};
					}
					&:hover {
						color: ${theme.palette.common.black};
						border-color: ${theme.palette.common.white};
						svg {
							path {
								fill: ${theme.palette.common.black};
							}
						}
					}

					&::after {
						background-color: ${theme.palette.common.white};
						content: '';
						height: 200%;
						left: -121%;
						position: absolute;
						top: -50%;
						transform: skewX(0deg);
						transition: ${theme.transitions.create(['left', 'transform', 'width'], {
							duration: theme.transitions.duration.standard
						})};
						width: 0;
						z-index: -1;
					}
					&:hover::after {
						content: '';
						left: -10%;
						transform: skewX(-20deg);
						width: 120%;
					}
					svg {
						height: ${theme.spacing(2)};
						width: ${theme.spacing(2)};
						path {
							transition: ${theme.transitions.create('fill', {
								duration: theme.transitions.duration.standard
							})};
							fill: ${theme.palette.common.white};
						}
					}

					&.Mui-disabled {
						color: ${grey[700]};
						border: 2px solid ${darken(theme.palette.common.white, 0.5)};

						&:focus,
						&:hover {
							border: 2px solid ${darken(theme.palette.common.white, 0.5)};
						}
					}
				`
			},
			{
				props: { variant: 'outlined', color: 'success' },
				style: css`
					background: transparent;
					border: 2px solid ${theme.palette.success.main};
					color: ${theme.palette.success.main};
					transition: ${theme.transitions.create(['color', 'border'], {
					duration: theme.transitions.duration.standard
				})};

					&:focus {
						color: ${theme.palette.success.main};
						border-color: ${theme.palette.success.main};
					}
					&:hover {
						color: ${theme.palette.success.contrastText};
						border-color: ${theme.palette.success.main};
						svg {
							path {
								fill: ${theme.palette.success.contrastText};
							}
						}
					}

					&::after {
						background-color: ${theme.palette.success.main};
						content: '';
						height: 200%;
						left: -121%;
						position: absolute;
						top: -50%;
						transform: skewX(0deg);
						transition: ${theme.transitions.create(['left', 'transform', 'width'], {
					duration: theme.transitions.duration.standard
				})};
						width: 0;
						z-index: -1;
					}
					&:hover::after {
						content: '';
						left: -10%;
						transform: skewX(-20deg);
						width: 120%;
					}
					svg {
						height: ${theme.spacing(2)};
						width: ${theme.spacing(2)};
						path {
							transition: ${theme.transitions.create('fill', {
					duration: theme.transitions.duration.standard
				})};
							fill: ${theme.palette.success.main};
						}
					}

					&.Mui-disabled {
						color: ${grey[700]};
						border: 2px solid ${theme.palette.success.dark};

						&:focus,
						&:hover {
							border: 2px solid ${theme.palette.success.dark};
						}
					}
				`
			},
			{
				props: { variant: 'contained', color: 'secondary' },
				style: css`
					background: ${theme.palette.common.white};
					border: 2px solid ${theme.palette.common.white};
					color: ${theme.palette.background.default};
					transition: ${theme.transitions.create(['color', 'border'], {
						duration: theme.transitions.duration.standard
					})};

					&:focus {
						color: ${theme.palette.background.default};
					}
					
					&:hover {
						border: 2px solid ${theme.palette.common.black};
						background: ${theme.palette.common.white};
						color: ${theme.palette.common.white};
					}

					&.Mui-disabled {
						cursor: not-allowed;
						color: ${darken(theme.palette.common.white, 0.2)};
						border: 2px solid ${darken(theme.palette.common.white, 0.02)};
						background: ${darken(theme.palette.common.white, 0.02)};

						&:focus,
						&:hover {
							background: #fff;
							border: 2px solid ${lighten('#35303e', 0.2)};
						}
					}

					&::after {
						background-color: ${theme.palette.common.black};
						content: '';
						height: 200%;
						left: -121%;
						position: absolute;
						top: -50%;
						transform: skewX(0deg);
						transition: ${theme.transitions.create(['left', 'transform', 'width'], {
							duration: theme.transitions.duration.standard
						})};
						width: 0;
						z-index: -1;
					}
					&:hover::after {
						content: '';
						left: -10%;
						transform: skewX(-20deg);
						width: 120%;
					}
				`
			},
			{
				props: { variant: 'contained', color: 'primary' },
				style: css`
					background: ${theme.palette.primary.main};
					border: 2px solid ${theme.palette.primary.main};
					color: ${theme.palette.background.default};
					transition: ${theme.transitions.create(['color', 'border'], {
						duration: theme.transitions.duration.standard
					})};

					&:focus {
						color: ${theme.palette.background.default};
					}
					
					&:hover {
						color: ${theme.palette.background.default};
						background: ${theme.palette.primary.main};
						border: 2px solid ${theme.palette.common.white};
					}

					&.Mui-disabled {
						cursor: not-allowed;
						border: none;
						color: ${darken(theme.palette.background.default, 0.05)};
						background: ${darken(theme.palette.primary.main, 0.05)};

						&:focus,
						&:hover {
							background: #fff;
							border: 2px solid ${lighten('#35303e', 0.2)};
						}
					}

					&::after {
						background-color: ${theme.palette.common.white};
						content: '';
						height: 200%;
						left: -121%;
						position: absolute;
						top: -50%;
						transform: skewX(0deg);
						transition: ${theme.transitions.create(['left', 'transform', 'width'], {
							duration: theme.transitions.duration.standard
						})};
						width: 0;
						z-index: -1;
					}
					&:hover::after {
						content: '';
						left: -10%;
						transform: skewX(-20deg);
						width: 120%;
					}
				`
			},
			{
				props: { variant: 'solid', color: 'tertiary' },
				style: css`
					background: #00d1d2;
					color: #35303e;
					svg {
						height: ${theme.spacing(2)};
						width: ${theme.spacing(2)};
						path {
							fill: ${theme.palette.background.default};
						}
					}

					&:focus,
					&:hover {
						background: #00d1d2;
					}
					&::after {
						background-color: ${theme.palette.common.white};
						content: '';
						height: 200%;
						left: -121%;
						position: absolute;
						top: -50%;
						transform: skewX(0deg);
						transition: ${theme.transitions.create(['left', 'transform', 'width'], {
							duration: theme.transitions.duration.standard
						})};
						width: 0;
						z-index: -1;
					}
					&:hover::after {
						content: '';
						left: -10%;
						transform: skewX(-20deg);
						width: 120%;
					}

					&.Mui-disabled {
						cursor: not-allowed;
						color: ${grey[400]};
						background: ${lighten('#00d1d2', 0.2)};

						&:focus,
						&:hover {
						}
					}
				`
			}
		]
	}
};

export default theme;
