import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useLanguages } from "../../helpers/contexts";
import styled from "@emotion/styled";

import Layout from "../Layout";
import theme from "../../theme";
import VeevImportantInformation from "../Veev/VeevImportantInformation";
import VeevSection from "../Veev/VeevSection";
import VeevFooter from "../Veev/VeevFooter";
import VeevCarousel from "../Veev/VeevCarousel";
import VeevSectionWithChildren from "../Veev/VeevSectionWithChildren";

import HeaderBodyText from "../HeaderBodyText";
import OtherResources from "../OtherResources";

const VeevNow = (): JSX.Element => {
  const [showImportant, setShowImportant] = useState(false);
  const { language } = useLanguages();
  const isArabic = language === "ar";

  return (
    <Layout translations={{}} footerData={{}}>
      <Styles>
        {showImportant ? (
          <VeevImportantInformation
            setShowImportant={setShowImportant}
            code={language}
          />
        ) : (
          <>
            <VeevSection
              code={language}
              imagePlacement="right"
              imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-landing-one-right.jpg"
              sectionStyles={{ paddingTop: 0 }}
              titleText={
                isArabic
                  ? "يعدّ VEEV NOW جهاز فيب فاخر مخصص للاستعمال مرة واحدة"
                  : "VEEV NOW is our <br /> premium disposable vape"
              }
              bodyText={
                isArabic
                  ? [
                      "تشكيلة من النكهات المفعمة بالحيوية مع ما يصل إلى 500 سحبة*",
                      "لا داعي للتنظيف، و الشحن",
                    ]
                  : [
                      "Vibrant flavour range with up to 500 puffs*",
                      "No cleaning, no charging and no refilling.",
                    ]
              }
              imageType="fill"
              buttonText={isArabic ? "VEEV NOW اشترِ" : "Buy VEEV NOW"}
              disclaimer={
                isArabic
                  ? "*يُقدر هذا بناءً على سعة بطارية المنتج الجديد ومدة السحبة بمعدل ثانية واحدة. تختلف حسب طريقة الاستخدام الفردية"
                  : "*Estimated based on battery capacity of a newly manufactured product and a puff duration of 1 second. May vary depending on individual usage patterns."
              }
              disclaimerLower={""}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: "5vw",
                backgroundColor: "#F6F4F0",
              }}
            >
              <VeevCarousel
                code={language}
                headerText={
                  isArabic
                    ? "مجموعة النكهات في سوق دبي الحرة"
                    : "Flavor Range in Dubai Duty Free"
                }
                device="now"
                slides={[
                  "Blue Mint",
                  "Classic Tobacco",
                  "Coral Pink",
                  "Marigold",
                  "Red",
                ]}
              />
            </Box>
            <VeevSectionWithChildren
              imagePlacement="right"
              imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-now-middle.jpg"
              imageType="fill"
              bodyContent={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    py: "5vw",
                    backgroundColor: "#F6F4F0",
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    className="body-section-title"
                  >
                    {isArabic
                      ? "كيفية استخدام جهاز VEEV NOW الخاص بك"
                      : "How to use your VEEV NOW"}
                  </Typography>

                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText={isArabic ? "استعد:" : "Get ready:"}
                      bodyText=""
                      code={language}
                    />
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										1. حان الوقت لفتح عبوة جهاز VEEV NOW الجديد!
										`
                          : "1. Time to unbox your new VEEV NOW device!"
                      }
                      code={language}
                    />
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										2. قبل أن تبدأ، ارفع غطاء السيليكون العلوي
										`
                          : "2. Before you start, please remove the top silicone-cap."
                      }
                      code={language}
                    />
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										3.تأكد من عدم سدّ فتحات تدفق الهواء وعن تمكن الهواء من الوصول إلى جهازك
										`
                          : "3. Make sure that the inlet holes are not covered, and the air is able to flow through your device."
                      }
                      code={language}
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText={
                        isArabic
                          ? `
										الفيب:
										`
                          : "Vape:"
                      }
                      bodyText=""
                      code={language}
                    />
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										4.سهل الاستخدام: ما عليك سوى البدء بالسحب! فيعمل الجهاز تلقائيًا. لا حاجة للضغط على أي أزرار للتشغيل/الإيقاف
										`
                          : "4. It's simple: just start puffing! Your device will activate automatically. No need to press any buttons for on/off."
                      }
                      code={language}
                    />
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										5.يضيء مصباح LED باللون الأبيض أثناء السحب للدلالة على على أن الجهاز يعمل
										`
                          : "5. LED light will grow white while you puff to indicate the device is working."
                      }
                      code={language}
                    />
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										6.عندما تنتهي من السحب ، توقف وسينطفئ الجهاز تلقائيًا بمنتهى البساطة
										`
                          : "6. Once finished, simply stop puffing and the device will turn off automatically."
                      }
                      code={language}
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText={
                        isArabic
                          ? `
										لاحقَا: 
										`
                          : "Afterwards:"
                      }
                      bodyText=""
                      code={language}
                    />
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										7.يومض مصباح LED في 10 مرات ثم ينطفئ عندما ينفد شحن البطارية بالكامل
										`
                          : "7. LED will pulse 10 times and then switch off once the battery is fully depleted."
                      }
                      code={language}
                    />
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										8.لا تتخلّص من جهاز VEEV NOW الخاص بك مع النفايات المنزلية.  أرجعه إلينا ونحن سنتولى إعادة تدوير مكوناته القابلة لإعادة التدوير.  تعرّف على كيفية إرجاع الجهاز هنا
										`
                          : "8. Please don't dispose your used VEEV NOW with household waste."
                      }
                      code={language}
                    />
                  </div>
                </Box>
              }
            />
            {/* Care you can count on HERO */}
            <Box sx={{ py: 10 }} className="hero">
              <Typography
                component="div"
                variant="h1"
                dangerouslySetInnerHTML={{
                  __html: isArabic
                    ? "رعاية يمكنك الاعتماد عليها"
                    : "Care you can count on",
                }}
                className="hero-title"
              />
            </Box>
            {/* Tips and hints*/}
            <Box
              sx={{
                paddingtop: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                component="div"
                variant="h1"
                sx={{
                  py: 10,
                }}
                dangerouslySetInnerHTML={{
                  __html: isArabic
                    ? "VEEV NOW: النصائح والتلميحات"
                    : "Tips and Hints",
                }}
                className="hero-title-no-wrap"
              />
            </Box>
            <Box
              sx={{
                py: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexWrap: "wrap",
                backgroundColor: "#FFFDFB",
              }}
            >
              <Box className="tips-wrapper">
                <img
                  src="/images/veev-now-cap.gif"
                  className="tips-image"
                  alt=""
                />
                <div className="body-text-container">
                  <HeaderBodyText
                    headerText={
                      isArabic ? "ارفع غطاء السيليكون" : "Remove silicone cap."
                    }
                    bodyText={
                      isArabic
                        ? "لا تنسَ رفع غطاء السيليكون الواقي عن جهازك قبل استخدامه  "
                        : "Don't forget to remove protective silicone cap from the top of your device before using it."
                    }
                    code={language}
                  />
                </div>
              </Box>
              <Box className="tips-wrapper">
                <img
                  src="/images/veev-now-airflow.gif"
                  className="tips-image"
                  alt=""
                />
                <div className="body-text-container">
                  <HeaderBodyText
                    headerText={
                      isArabic
                        ? "لا تسدّ فتحات تدفق الهواء "
                        : "Don't cover airflow holes."
                    }
                    bodyText={
                      isArabic
                        ? "تأكد دائمًا من عدم سدّ فتحتي تدفق الهواء الموجودتين على جانب الجهاز بأصابعك.  يشترط تدفق الهواء عبر الجهاز"
                        : "Always make sure that the two airflow holes, which are placed on the side of your device, are not blocked by your fingers. The air should be able to flow through your device."
                    }
                    code={language}
                  />
                </div>
              </Box>
            </Box>
            {/* FAQS*/}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ paddingTop: 10 }}
                component="div"
                variant="h1"
                dangerouslySetInnerHTML={{
                  __html: isArabic
                    ? "الأسئلة الشائعة"
                    : "Frequently Asked Questions",
                }}
                className="hero-title-no-wrap"
              />
            </Box>
            {/* Leaking */}
            <VeevSectionWithChildren
              imagePlacement="right"
              imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-now-leak-banner.jpg"
              imageType="fill"
              /* sectionStyles={{ paddingTop: 0 }} */
              bodyContent={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    py: "5vw",
                    backgroundColor: "#F6F4F0",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    className="body-section-title"
                  >
                    {isArabic
                      ? "ماذا أفعل إذا حدث تسريب للسائل الإلكتروني من جهاز VEEV NOW الخاص بي؟"
                      : "What to do if my VEEV NOW is leaking e-liquid?"}
                  </Typography>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										1. قد تظهر قطرات من السائل على مبسم الجهاز أثناء الاستخدام العادي.  في هذه الحالة، امسح هذه القطرات بقطعة قماش جافة ونظيفة قبل استخدام الجهاز
										`
                          : "1. Droplets of liquid that are visible on the mouthpiece may occur during unpacking. In case, they must be wiped off with a clean dry cloth before you use the device."
                      }
                      code={language}
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										2.  لا تستخدم هذا المنتج إذا اتضح وجود عطل في الجهاز، إذا تم التلاعب به، أو إذا كُسر، أو إذا حدث تسريب
										`
                          : "2. Do not use this product if the device appears damaged, tampered with, broken, or leaking."
                      }
                      code={language}
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										3. في حالة ملامسة السائل الإلكتروني المتسرب من جهاز VEEV NOW، اغسل الموضع المتأثر على الفور بالماء والصابون
										`
                          : "3. In case of contact with leaking e-liquid from VEEV NOW, wash the affected area immediately with soap and water."
                      }
                      code={language}
                    />
                  </div>
                </Box>
              }
            />
            <VeevSectionWithChildren
              imagePlacement="left"
              imagePath="https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/veev-now-tip.jpg"
              imageType="fill"
              /* sectionStyles={{ paddingTop: 0 }} */
              bodyContent={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    py: "5vw",
                    backgroundColor: "#F6F4F0",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h3"
                    component="h3"
                    className="body-section-title"
                  >
                    {isArabic
                      ? `
									ماذا أفعل إذا تعذّر السحب من الجهاز أو لم يعمل الضوء؟
									`
                      : "What to do when you can't puff on the device, or the light does not turn on?"}
                  </Typography>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										1. لم تتم إزالة الغطاء البلاستيكي عن أعلى وأسفل المنتج. يُرجى إزالة أغطية السيليكون العلوية والسفلية والمحاولة مرة أخرى
										`
                          : "1. The plastic covers at the top and bottom of your product are not removed. Please remove the top and bottom silicone-caps and try again."
                      }
                      code={language}
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? `
										2. من المحتمل أن تكون فتحات مدخل الهواء على جانب الجهاز مسدودة بأصابعك.  لذا، تأكد من عدم تغطية فتحات مدخل الهواء الموجودة بجهازك أثناء السحب
										`
                          : "2. Your fingers may block the air inlet holes on the sides of the device. Therefore, please ensure that the air inlet holes are not covered when puffing on the device. "
                      }
                      code={language}
                    />
                  </div>
                  <div className="body-text-container">
                    <HeaderBodyText
                      headerText=""
                      bodyText={
                        isArabic
                          ? "   جديد VEEV NOW إذا استمرت المشكلة ولم يعمل الجهاز بعد ذلك، تخلّص منه وافتح جهاز "
                          : "If it’s still not working afterwards, please dispose of it and unpack a new VEEV NOW."
                      }
                      code={language}
                    />
                  </div>
                </Box>
              }
            />
            <OtherResources code={language} />
            <VeevFooter setShowImportant={setShowImportant} code={language} />
          </>
        )}
      </Styles>
    </Layout>
  );
};

const Styles = styled.div`
  .body-section-title {
    color: #27242e;
    margin-bottom: 20px;
  }

  .body-section-subtitle {
    color: #27242e;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .body-section-body {
    color: #27242e;
  }

  .body-text-container {
    width: 100%;
  }

  .hero {
    height: 600px;
    background-image: url("https://iqos-travel-landing.s3.eu-west-2.amazonaws.com/images/care-you-can-count-on.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .hero {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hero-title {
    max-width: 400px;
    text-align: center;
    font-size: 2rem;
  }

  .hero-title-no-wrap {
    text-align: center;
    font-size: 2rem;
  }

  .spacer {
    ${theme.breakpoints.up("xs")} {
      margin: 150px;
    }
  }

  .tips-wrapper {
    margin: 20px;
    width: 500px;
  }

  .tips-image {
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 500px;
  }
`;

export default VeevNow;
